import { getRemovedDuplicates } from "../../utils/formatter.utils";

import SUBSCRIPTION_ACTION_TYPES from "./subscription.type";

export const SUBSCRIPTION_INITIAL_STATE = {
  isModalAutoClose: true,

  subscriptions: [],
  subscription: null,

  extendSubscription: null,
  extendSubscriptionKey: null,
  extendSubscriptionOptionKey: null,

  isSubscriptionsHasMore: true,

  fetchSubscriptionsSearch: null,
  fetchSubscriptionsPage: 1,
  fetchSubscriptionsPerPage: null,
  fetchSubscriptionsLoading: false,
  fetchSubscriptionsSuccess: null,
  fetchSubscriptionsFailed: null,

  fetchSubscriptionLoading: false,
  fetchSubscriptionSuccess: null,
  fetchSubscriptionFailed: null,

  createSubscriptionLoading: false,
  createSubscriptionSuccess: null,
  createSubscriptionFailed: null,

  updateSubscriptionLoading: false,
  updateSubscriptionSuccess: null,
  updateSubscriptionFailed: null,

  deleteSubscriptionLoading: false,
  deleteSubscriptionSuccess: null,
  deleteSubscriptionFailed: null,

  isFetchSubscriptionsHitted: false,
  isFetchSubscriptionHitted: false,
  isCreateSubscriptionHitted: false,
  isUpdateSubscriptionHitted: false,
  isDeleteSubscriptionHitted: false,
};

export const subscriptionReducer = (state = SUBSCRIPTION_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUBSCRIPTION_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE:
      return { ...state, isModalAutoClose: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTIONS:
      return { ...state, subscriptions: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION:
      return { ...state, subscription: payload };

    case SUBSCRIPTION_ACTION_TYPES.SET_EXTEND_SUBSCRIPTION:
      return { ...state, extendSubscription: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_EXTEND_SUBSCRIPTION_KEY:
      return { ...state, extendSubscriptionKey: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_EXTEND_SUBSCRIPTION_OPTION_KEY:
      return { ...state, extendSubscriptionOptionKey: payload };

    case SUBSCRIPTION_ACTION_TYPES.SET_IS_SUBSCRIPTIONS_HAS_MORE:
      return { ...state, isSubscriptionsHasMore: payload };

    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_SEARCH:
      return { ...state, fetchSubscriptionsSearch: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_PAGE:
      return { ...state, fetchSubscriptionsPage: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_PER_PAGE:
      return { ...state, fetchSubscriptionsPerPage: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_LOADING:
      return { ...state, fetchSubscriptionsLoading: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_SUCCESS:
      return { ...state, fetchSubscriptionsSuccess: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_FAILED:
      return { ...state, fetchSubscriptionsFailed: payload };

    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_LOADING:
      return { ...state, fetchSubscriptionLoading: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_SUCCESS:
      return { ...state, fetchSubscriptionSuccess: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_FAILED:
      return { ...state, fetchSubscriptionFailed: payload };

    case SUBSCRIPTION_ACTION_TYPES.SET_CREATE_SUBSCRIPTION_LOADING:
      return { ...state, createSubscriptionLoading: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_CREATE_SUBSCRIPTION_SUCCESS:
      return { ...state, createSubscriptionSuccess: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_CREATE_SUBSCRIPTION_FAILED:
      return { ...state, createSubscriptionFailed: payload };

    case SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_LOADING:
      return { ...state, updateSubscriptionLoading: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_SUCCESS:
      return { ...state, updateSubscriptionSuccess: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_FAILED:
      return { ...state, updateSubscriptionFailed: payload };

    case SUBSCRIPTION_ACTION_TYPES.SET_DELETE_SUBSCRIPTION_LOADING:
      return { ...state, deleteSubscriptionLoading: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_DELETE_SUBSCRIPTION_SUCCESS:
      return { ...state, deleteSubscriptionSuccess: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_DELETE_SUBSCRIPTION_FAILED:
      return { ...state, deleteSubscriptionFailed: payload };

    case SUBSCRIPTION_ACTION_TYPES.APPEND_SUBSCRIPTIONS:
      return { ...state, subscriptions: getRemovedDuplicates([...state.subscriptions, ...payload], "key") };

    case SUBSCRIPTION_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTIONS_HITTED:
      return { ...state, isFetchSubscriptionsHitted: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTION_HITTED:
      return { ...state, isFetchSubscriptionHitted: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_IS_CREATE_SUBSCRIPTION_HITTED:
      return { ...state, isCreateSubscriptionHitted: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_IS_UPDATE_SUBSCRIPTION_HITTED:
      return { ...state, isUpdateSubscriptionHitted: payload };
    case SUBSCRIPTION_ACTION_TYPES.SET_IS_DELETE_SUBSCRIPTION_HITTED:
      return { ...state, isDeleteSubscriptionHitted: payload };

    case SUBSCRIPTION_ACTION_TYPES.RESET_SUBSCRIPTION_REDUCER:
      return SUBSCRIPTION_INITIAL_STATE;
    default:
      return state;
  }
};
