import { takeLatest, put, all, call, select } from "redux-saga/effects";

import COUNTRY_ACTION_TYPES from "./country.type";

import {
  appendCountries,
  appendSelectCountries,
  setCountries,
  setCountry,
  setCreateCountryFailed,
  setCreateCountryLoading,
  setCreateCountrySuccess,
  setDeleteCountryFailed,
  setDeleteCountryLoading,
  setDeleteCountrySuccess,
  setFetchCountriesFailed,
  setFetchCountriesLoading,
  setFetchCountriesPage,
  setFetchCountriesSuccess,
  setFetchCountryFailed,
  setFetchCountryLoading,
  setFetchCountrySuccess,
  setFetchSelectCountriesFailed,
  setFetchSelectCountriesLoading,
  setFetchSelectCountriesPage,
  setFetchSelectCountriesSuccess,
  setIsCountriesHasMore,
  setIsCreateCountryHitted,
  setIsDeleteCountryHitted,
  setIsFetchCountriesHitted,
  setIsFetchCountryHitted,
  setIsFetchSelectCountriesHitted,
  setIsSelectCountriesHasMore,
  setIsSyncRateCountryHitted,
  setIsUpdateCountryHitted,
  setSelectCountries,
  setSyncRateCountryFailed,
  setSyncRateCountryLoading,
  setSyncRateCountrySuccess,
  setUpdateCountryFailed,
  setUpdateCountryLoading,
  setUpdateCountrySuccess,
} from "./country.action";
import {
  getFetchCountriesIncludes,
  getFetchCountriesKeyBy,
  getFetchCountriesPage,
  getFetchCountriesPerPage,
  getFetchCountriesSearch,
  getFetchSelectCountriesIncludes,
  getFetchSelectCountriesKeyBy,
  getFetchSelectCountriesPage,
  getFetchSelectCountriesPerPage,
  getFetchSelectCountriesSearch,
  getIsFetchCountriesHitted,
  getIsFetchSelectCountriesHitted,
} from "./country.selector";

import {
  getCountries,
  getCountry,
  createCountry,
  updateCountry,
  deleteCountry,
  syncRateCountry,
} from "../../api/country.api";

export function* _getCountries() {
  try {
    yield put(setFetchCountriesLoading(true));

    const search = yield select(getFetchCountriesSearch);
    const key_by = yield select(getFetchCountriesKeyBy);
    const page = yield select(getFetchCountriesPage);
    const per_page = yield select(getFetchCountriesPerPage);
    const includes = yield select(getFetchCountriesIncludes);

    const parameters = { search, key_by, page, per_page, includes };

    const {
      meta: { message },
      data: countries,
    } = yield call(getCountries, parameters);

    yield put(setIsFetchCountriesHitted(true));
    yield put(setIsCountriesHasMore(countries.length > 0));

    if (page > 1) {
      yield put(appendCountries(countries));
    } else {
      yield put(setCountries(countries));
    }

    yield put(setFetchCountriesSuccess(message));
    yield put(setFetchCountriesLoading(false));
  } catch (error) {
    yield put(setFetchCountriesFailed(error));
    yield put(setFetchCountriesLoading(false));
  }
}
export function* _getSelectCountries() {
  try {
    yield put(setFetchSelectCountriesLoading(true));

    const search = yield select(getFetchSelectCountriesSearch);
    const key_by = yield select(getFetchSelectCountriesKeyBy);
    const page = yield select(getFetchSelectCountriesPage);
    const per_page = yield select(getFetchSelectCountriesPerPage);
    const includes = yield select(getFetchSelectCountriesIncludes);

    const parameters = { search, key_by, page, per_page, includes };

    const {
      meta: { message },
      data: countries,
    } = yield call(getCountries, parameters);

    yield put(setIsFetchSelectCountriesHitted(true));
    yield put(setSelectCountries(countries));

    // yield put(setIsSelectCountriesHasMore(countries.length > 0));

    // if (page > 1) {
    //   yield put(appendSelectCountries(countries));
    // } else {
    //   yield put(setSelectCountries(countries));
    // }

    yield put(setFetchSelectCountriesSuccess(message));
    yield put(setFetchSelectCountriesLoading(false));
  } catch (error) {
    yield put(setFetchSelectCountriesFailed(error));
    yield put(setFetchSelectCountriesLoading(false));
  }
}
export function* _getCountry({ payload: id }) {
  try {
    yield put(setFetchCountryLoading(true));

    const {
      meta: { message },
      data: country,
    } = yield call(getCountry, id);

    yield put(setIsFetchCountryHitted(true));
    yield put(setCountry(country));

    yield put(setFetchCountrySuccess(message));
    yield put(setFetchCountryLoading(false));
  } catch (error) {
    yield put(setFetchCountryFailed(error));
    yield put(setFetchCountryLoading(false));
  }
}
export function* _createCountry({ payload: request }) {
  try {
    yield put(setCreateCountryLoading(true));

    const {
      meta: { message },
    } = yield call(createCountry, request);

    yield put(setIsCreateCountryHitted(true));

    const isFetchCountriesHitted = yield select(getIsFetchCountriesHitted);
    const isFetchSelectCountriesHitted = yield select(getIsFetchSelectCountriesHitted);

    if (isFetchCountriesHitted) {
      yield put(setFetchCountriesPage(1));
      yield call(_getCountries);
    }
    if (isFetchSelectCountriesHitted) {
      yield put(setFetchSelectCountriesPage(1));
      yield call(_getSelectCountries);
    }

    yield put(setCreateCountrySuccess(message));
    yield put(setCreateCountryLoading(false));
  } catch (error) {
    yield put(setCreateCountryFailed(error));
    yield put(setCreateCountryLoading(false));
  }
}
export function* _updateCountry({ payload: { id, request } }) {
  try {
    yield put(setUpdateCountryLoading(true));

    const {
      meta: { message },
    } = yield call(updateCountry, id, request);

    yield put(setIsUpdateCountryHitted(true));

    const isFetchCountriesHitted = yield select(getIsFetchCountriesHitted);
    const isFetchSelectCountriesHitted = yield select(getIsFetchSelectCountriesHitted);

    if (isFetchCountriesHitted) {
      yield put(setFetchCountriesPage(1));
      yield call(_getCountries);
    }
    if (isFetchSelectCountriesHitted) {
      yield put(setFetchSelectCountriesPage(1));
      yield call(_getSelectCountries);
    }

    yield put(setUpdateCountrySuccess(message));
    yield put(setUpdateCountryLoading(false));
  } catch (error) {
    yield put(setUpdateCountryFailed(error));
    yield put(setUpdateCountryLoading(false));
  }
}
export function* _deleteCountry({ payload: id }) {
  try {
    yield put(setDeleteCountryLoading(true));

    const {
      meta: { message },
    } = yield call(deleteCountry, id);

    yield put(setIsDeleteCountryHitted(true));

    const isFetchCountriesHitted = yield select(getIsFetchCountriesHitted);
    const isFetchSelectCountriesHitted = yield select(getIsFetchSelectCountriesHitted);

    if (isFetchCountriesHitted) {
      yield put(setFetchCountriesPage(1));
      yield call(_getCountries);
    }
    if (isFetchSelectCountriesHitted) {
      yield put(setFetchSelectCountriesPage(1));
      yield call(_getSelectCountries);
    }

    yield put(setDeleteCountrySuccess(message));
    yield put(setDeleteCountryLoading(false));
  } catch (error) {
    yield put(setDeleteCountryFailed(error));
    yield put(setDeleteCountryLoading(false));
  }
}
export function* _syncRateCountry({ payload: { id, request } }) {
  try {
    yield put(setSyncRateCountryLoading(true));

    const {
      meta: { message },
      data: country,
    } = yield call(syncRateCountry, id, request);

    yield put(setIsSyncRateCountryHitted(true));
    yield put(setCountry(country));

    yield put(setSyncRateCountrySuccess(message));
    yield put(setSyncRateCountryLoading(false));
  } catch (error) {
    yield put(setSyncRateCountryFailed(error));
    yield put(setSyncRateCountryLoading(false));
  }
}

export function* onFetchCountriesStart() {
  yield takeLatest(COUNTRY_ACTION_TYPES.FETCH_COUNTRIES_START, _getCountries);
}
export function* onFetchSelectCountriesStart() {
  yield takeLatest(COUNTRY_ACTION_TYPES.FETCH_SELECT_COUNTRIES_START, _getSelectCountries);
}
export function* onFetchCountryStart() {
  yield takeLatest(COUNTRY_ACTION_TYPES.FETCH_COUNTRY_START, _getCountry);
}
export function* onCreateCountryStart() {
  yield takeLatest(COUNTRY_ACTION_TYPES.CREATE_COUNTRY_START, _createCountry);
}
export function* onUpdateCountryStart() {
  yield takeLatest(COUNTRY_ACTION_TYPES.UPDATE_COUNTRY_START, _updateCountry);
}
export function* onDeleteCountryStart() {
  yield takeLatest(COUNTRY_ACTION_TYPES.DELETE_COUNTRY_START, _deleteCountry);
}
export function* onSyncRateCountryStart() {
  yield takeLatest(COUNTRY_ACTION_TYPES.SYNC_RATE_COUNTRY_START, _syncRateCountry);
}

export function* countrySaga() {
  yield all([
    call(onFetchCountriesStart),
    call(onFetchSelectCountriesStart),
    call(onFetchCountryStart),
    call(onCreateCountryStart),
    call(onUpdateCountryStart),
    call(onDeleteCountryStart),
    call(onSyncRateCountryStart),
  ]);
}
