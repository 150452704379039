import EXPORT_PDF_ACTION_TYPES from "./export-pdf.type";

export const EXPORT_PDF_INITIAL_STATE = {
  exportPdfOrderInvoice: null,
  exportPdfOrderKitchen: null,

  exportPdfOrderInvoiceLoading: false,
  exportPdfOrderInvoiceSuccess: null,
  exportPdfOrderInvoiceFailed: null,

  exportPdfOrderKitchenLoading: false,
  exportPdfOrderKitchenSuccess: null,
  exportPdfOrderKitchenFailed: null,

  isExportPdfOrderInvoiceHitted: false,
  isExportPdfOrderKitchenHitted: false,
};

export const exportPdfReducer = (state = EXPORT_PDF_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE:
      return { ...state, exportPdfOrderInvoice: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN:
      return { ...state, exportPdfOrderKitchen: payload };

    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_LOADING:
      return { ...state, exportPdfOrderInvoiceLoading: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_SUCCESS:
      return { ...state, exportPdfOrderInvoiceSuccess: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_FAILED:
      return { ...state, exportPdfOrderInvoiceFailed: payload };

    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_LOADING:
      return { ...state, exportPdfOrderKitchenLoading: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_SUCCESS:
      return { ...state, exportPdfOrderKitchenSuccess: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_FAILED:
      return { ...state, exportPdfOrderKitchenFailed: payload };

    case EXPORT_PDF_ACTION_TYPES.SET_IS_EXPORT_PDF_ORDER_INVOICE_HITTED:
      return { ...state, isExportPdfOrderInvoiceHitted: payload };
    case EXPORT_PDF_ACTION_TYPES.SET_IS_EXPORT_PDF_ORDER_KITCHEN_HITTED:
      return { ...state, isExportPdfOrderKitchenHitted: payload };

    case EXPORT_PDF_ACTION_TYPES.RESET_EXPORT_PDF_REDUCER:
      return EXPORT_PDF_INITIAL_STATE;
    default:
      return state;
  }
};
