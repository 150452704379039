import PRODUCT_CATEGORY_ACTION_TYPES from "./product-category.type";
import { createAction } from "../../utils/store.utils";

export const setProductCategories = (productCategories) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORIES,
    productCategories
  );
export const setSelectProductCategories = (selectProductCategories) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_SELECT_PRODUCT_CATEGORIES,
    selectProductCategories
  );
export const setRelatedProductCategories = (relatedProductCategories) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_RELATED_PRODUCT_CATEGORIES,
    relatedProductCategories
  );
export const setProductCategory = (productCategory) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORY,
    productCategory
  );

export const setIsProductCategoriesHasMore = (isProductCategoriesHasMore) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_PRODUCT_CATEGORIES_HAS_MORE,
    isProductCategoriesHasMore
  );
export const setIsSelectProductCategoriesHasMore = (
  isSelectProductCategoriesHasMore
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_SELECT_PRODUCT_CATEGORIES_HAS_MORE,
    isSelectProductCategoriesHasMore
  );
export const setIsRelatedProductCategoriesHasMore = (
  isRelatedProductCategoriesHasMore
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_RELATED_PRODUCT_CATEGORIES_HAS_MORE,
    isRelatedProductCategoriesHasMore
  );

export const setFetchProductCategoriesSearch = (fetchProductCategoriesSearch) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SEARCH,
    fetchProductCategoriesSearch
  );
export const setFetchProductCategoriesSort = (fetchProductCategoriesSort) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SORT,
    fetchProductCategoriesSort
  );
export const setFetchProductCategoriesKeyBy = (fetchProductCategoriesKeyBy) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_KEY_BY,
    fetchProductCategoriesKeyBy
  );
export const setFetchProductCategoriesPage = (fetchProductCategoriesPage) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PAGE,
    fetchProductCategoriesPage
  );
export const setFetchProductCategoriesPerPage = (
  fetchProductCategoriesPerPage
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PER_PAGE,
    fetchProductCategoriesPerPage
  );
export const setFetchProductCategoriesIncludes = (
  fetchProductCategoriesIncludes
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_INCLUDES,
    fetchProductCategoriesIncludes
  );
export const setFetchProductCategoriesFilterSectorId = (
  fetchProductCategoriesFilterSectorId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_SECTOR_ID,
    fetchProductCategoriesFilterSectorId
  );
export const setFetchProductCategoriesFilterMarketId = (
  fetchProductCategoriesFilterMarketId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_MARKET_ID,
    fetchProductCategoriesFilterMarketId
  );
export const setFetchProductCategoriesFilterBranchId = (
  fetchProductCategoriesFilterBranchId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_BRANCH_ID,
    fetchProductCategoriesFilterBranchId
  );
export const setFetchProductCategoriesLoading = (
  fetchProductCategoriesLoading
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_LOADING,
    fetchProductCategoriesLoading
  );
export const setFetchProductCategoriesSuccess = (
  fetchProductCategoriesSuccess
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SUCCESS,
    fetchProductCategoriesSuccess
  );
export const setFetchProductCategoriesFailed = (fetchProductCategoriesFailed) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FAILED,
    fetchProductCategoriesFailed
  );

export const setFetchSelectProductCategoriesSearch = (
  fetchSelectProductCategoriesSearch
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_SEARCH,
    fetchSelectProductCategoriesSearch
  );
export const setFetchSelectProductCategoriesSort = (
  fetchSelectProductCategoriesSort
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_SORT,
    fetchSelectProductCategoriesSort
  );
export const setFetchSelectProductCategoriesKeyBy = (
  fetchSelectProductCategoriesKeyBy
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_KEY_BY,
    fetchSelectProductCategoriesKeyBy
  );
export const setFetchSelectProductCategoriesPage = (
  fetchSelectProductCategoriesPage
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_PAGE,
    fetchSelectProductCategoriesPage
  );
export const setFetchSelectProductCategoriesPerPage = (
  fetchSelectProductCategoriesPerPage
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_PER_PAGE,
    fetchSelectProductCategoriesPerPage
  );
export const setFetchSelectProductCategoriesIncludes = (
  fetchSelectProductCategoriesIncludes
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_INCLUDES,
    fetchSelectProductCategoriesIncludes
  );
export const setFetchSelectProductCategoriesFilterSectorId = (
  fetchSelectProductCategoriesFilterSectorId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_SECTOR_ID,
    fetchSelectProductCategoriesFilterSectorId
  );
export const setFetchSelectProductCategoriesFilterMarketId = (
  fetchSelectProductCategoriesFilterMarketId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_MARKET_ID,
    fetchSelectProductCategoriesFilterMarketId
  );
export const setFetchSelectProductCategoriesFilterBranchId = (
  fetchSelectProductCategoriesFilterBranchId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_BRANCH_ID,
    fetchSelectProductCategoriesFilterBranchId
  );
export const setFetchSelectProductCategoriesFilterProductCategoriesIds = (
  fetchSelectProductCategoriesFilterProductCategoriesIds
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_PRODUCT_CATEGORIES_IDS,
    fetchSelectProductCategoriesFilterProductCategoriesIds
  );
export const setFetchSelectProductCategoriesLoading = (
  fetchSelectProductCategoriesLoading
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_LOADING,
    fetchSelectProductCategoriesLoading
  );
export const setFetchSelectProductCategoriesSuccess = (
  fetchSelectProductCategoriesSuccess
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_SUCCESS,
    fetchSelectProductCategoriesSuccess
  );
export const setFetchSelectProductCategoriesFailed = (
  fetchSelectProductCategoriesFailed
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FAILED,
    fetchSelectProductCategoriesFailed
  );

export const setFetchRelatedProductCategoriesSearch = (
  fetchRelatedProductCategoriesSearch
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_SEARCH,
    fetchRelatedProductCategoriesSearch
  );
export const setFetchRelatedProductCategoriesSort = (
  fetchRelatedProductCategoriesSort
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_SORT,
    fetchRelatedProductCategoriesSort
  );
export const setFetchRelatedProductCategoriesKeyBy = (
  fetchRelatedProductCategoriesKeyBy
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_KEY_BY,
    fetchRelatedProductCategoriesKeyBy
  );
export const setFetchRelatedProductCategoriesPage = (
  fetchRelatedProductCategoriesPage
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_PAGE,
    fetchRelatedProductCategoriesPage
  );
export const setFetchRelatedProductCategoriesPerPage = (
  fetchRelatedProductCategoriesPerPage
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_PER_PAGE,
    fetchRelatedProductCategoriesPerPage
  );
export const setFetchRelatedProductCategoriesIncludes = (
  fetchRelatedProductCategoriesIncludes
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_INCLUDES,
    fetchRelatedProductCategoriesIncludes
  );
export const setFetchRelatedProductCategoriesFilterSectorId = (
  fetchRelatedProductCategoriesFilterSectorId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_SECTOR_ID,
    fetchRelatedProductCategoriesFilterSectorId
  );
export const setFetchRelatedProductCategoriesFilterMarketId = (
  fetchRelatedProductCategoriesFilterMarketId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_MARKET_ID,
    fetchRelatedProductCategoriesFilterMarketId
  );
export const setFetchRelatedProductCategoriesFilterBranchId = (
  fetchRelatedProductCategoriesFilterBranchId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_BRANCH_ID,
    fetchRelatedProductCategoriesFilterBranchId
  );
export const setFetchRelatedProductCategoriesLoading = (
  fetchRelatedProductCategoriesLoading
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_LOADING,
    fetchRelatedProductCategoriesLoading
  );
export const setFetchRelatedProductCategoriesSuccess = (
  fetchRelatedProductCategoriesSuccess
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_SUCCESS,
    fetchRelatedProductCategoriesSuccess
  );
export const setFetchRelatedProductCategoriesFailed = (
  fetchRelatedProductCategoriesFailed
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_FAILED,
    fetchRelatedProductCategoriesFailed
  );

export const setAppendSelectProductCategoriesSearch = (
  appendSelectProductCategoriesSearch
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_SEARCH,
    appendSelectProductCategoriesSearch
  );
export const setAppendSelectProductCategoriesSort = (
  appendSelectProductCategoriesSort
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_SORT,
    appendSelectProductCategoriesSort
  );
export const setAppendSelectProductCategoriesKeyBy = (
  appendSelectProductCategoriesKeyBy
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_KEY_BY,
    appendSelectProductCategoriesKeyBy
  );
export const setAppendSelectProductCategoriesPage = (
  appendSelectProductCategoriesPage
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_PAGE,
    appendSelectProductCategoriesPage
  );
export const setAppendSelectProductCategoriesPerPage = (
  appendSelectProductCategoriesPerPage
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_PER_PAGE,
    appendSelectProductCategoriesPerPage
  );
export const setAppendSelectProductCategoriesIncludes = (
  appendSelectProductCategoriesIncludes
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_INCLUDES,
    appendSelectProductCategoriesIncludes
  );
export const setAppendSelectProductCategoriesFilterSectorId = (
  appendSelectProductCategoriesFilterSectorId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_SECTOR_ID,
    appendSelectProductCategoriesFilterSectorId
  );
export const setAppendSelectProductCategoriesFilterMarketId = (
  appendSelectProductCategoriesFilterMarketId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_MARKET_ID,
    appendSelectProductCategoriesFilterMarketId
  );
export const setAppendSelectProductCategoriesFilterBranchId = (
  appendSelectProductCategoriesFilterBranchId
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_BRANCH_ID,
    appendSelectProductCategoriesFilterBranchId
  );
export const setAppendSelectProductCategoriesFilterProductCategoriesIds = (
  appendSelectProductCategoriesFilterProductCategoriesIds
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_PRODUCT_CATEGORIES_IDS,
    appendSelectProductCategoriesFilterProductCategoriesIds
  );
export const setAppendSelectProductCategoriesLoading = (
  appendSelectProductCategoriesLoading
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_LOADING,
    appendSelectProductCategoriesLoading
  );
export const setAppendSelectProductCategoriesSuccess = (
  appendSelectProductCategoriesSuccess
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_SUCCESS,
    appendSelectProductCategoriesSuccess
  );
export const setAppendSelectProductCategoriesFailed = (
  appendSelectProductCategoriesFailed
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FAILED,
    appendSelectProductCategoriesFailed
  );

export const setFetchProductCategoryLoading = (fetchProductCategoryLoading) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_LOADING,
    fetchProductCategoryLoading
  );
export const setFetchProductCategorySuccess = (fetchProductCategorySuccess) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_SUCCESS,
    fetchProductCategorySuccess
  );
export const setFetchProductCategoryFailed = (fetchProductCategoryFailed) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_FAILED,
    fetchProductCategoryFailed
  );

export const setSortProductCategoriesLoading = (sortProductCategoriesLoading) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_SORT_PRODUCT_CATEGORIES_LOADING,
    sortProductCategoriesLoading
  );
export const setSortProductCategoriesSuccess = (sortProductCategoriesSuccess) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_SORT_PRODUCT_CATEGORIES_SUCCESS,
    sortProductCategoriesSuccess
  );
export const setSortProductCategoriesFailed = (sortProductCategoriesFailed) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_SORT_PRODUCT_CATEGORIES_FAILED,
    sortProductCategoriesFailed
  );

export const setCreateProductCategoryLoading = (createProductCategoryLoading) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_CREATE_PRODUCT_CATEGORY_LOADING,
    createProductCategoryLoading
  );
export const setCreateProductCategorySuccess = (createProductCategorySuccess) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_CREATE_PRODUCT_CATEGORY_SUCCESS,
    createProductCategorySuccess
  );
export const setCreateProductCategoryFailed = (createProductCategoryFailed) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_CREATE_PRODUCT_CATEGORY_FAILED,
    createProductCategoryFailed
  );

export const setUpdateProductCategoryLoading = (updateProductCategoryLoading) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_UPDATE_PRODUCT_CATEGORY_LOADING,
    updateProductCategoryLoading
  );
export const setUpdateProductCategorySuccess = (updateProductCategorySuccess) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_UPDATE_PRODUCT_CATEGORY_SUCCESS,
    updateProductCategorySuccess
  );
export const setUpdateProductCategoryFailed = (updateProductCategoryFailed) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_UPDATE_PRODUCT_CATEGORY_FAILED,
    updateProductCategoryFailed
  );

export const setDeleteProductCategoryLoading = (deleteProductCategoryLoading) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_DELETE_PRODUCT_CATEGORY_LOADING,
    deleteProductCategoryLoading
  );
export const setDeleteProductCategorySuccess = (deleteProductCategorySuccess) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_DELETE_PRODUCT_CATEGORY_SUCCESS,
    deleteProductCategorySuccess
  );
export const setDeleteProductCategoryFailed = (deleteProductCategoryFailed) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SET_DELETE_PRODUCT_CATEGORY_FAILED,
    deleteProductCategoryFailed
  );

export const appendProductCategories = (productCategories) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.APPEND_PRODUCT_CATEGORIES,
    productCategories
  );
export const appendSelectProductCategories = (selectProductCategories) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.APPEND_SELECT_PRODUCT_CATEGORIES,
    selectProductCategories
  );
export const appendRelatedProductCategories = (relatedProductCategories) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.APPEND_RELATED_PRODUCT_CATEGORIES,
    relatedProductCategories
  );

export const fetchProductCategoriesStart = () =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_START);
export const fetchSelectProductCategoriesStart = () =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_SELECT_PRODUCT_CATEGORIES_START
  );
export const fetchRelatedProductCategoriesStart = () =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_RELATED_PRODUCT_CATEGORIES_START
  );
export const appendSelectProductCategoriesStart = () =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.APPEND_SELECT_PRODUCT_CATEGORIES_START
  );
export const fetchProductCategoryStart = (categoryId) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORY_START,
    categoryId
  );
export const sortProductCategoriesStart = (request) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.SORT_PRODUCT_CATEGORIES_START,
    request
  );
export const createProductCategoryStart = (request) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.CREATE_PRODUCT_CATEGORY_START,
    request
  );
export const updateProductCategoryStart = (categoryId, request) =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.UPDATE_PRODUCT_CATEGORY_START, {
    categoryId,
    request,
  });
export const deleteProductCategoryStart = (categoryId) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.DELETE_PRODUCT_CATEGORY_START,
    categoryId
  );

export const setIsFetchProductCategoriesHitted = (
  isFetchProductCategoriesHitted
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_PRODUCT_CATEGORIES_HITTED,
    isFetchProductCategoriesHitted
  );
export const setIsFetchSelectProductCategoriesHitted = (
  isFetchSelectProductCategoriesHitted
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_SELECT_PRODUCT_CATEGORIES_HITTED,
    isFetchSelectProductCategoriesHitted
  );
export const setIsFetchRelatedProductCategoriesHitted = (
  isFetchRelatedProductCategoriesHitted
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_RELATED_PRODUCT_CATEGORIES_HITTED,
    isFetchRelatedProductCategoriesHitted
  );
export const setIsAppendSelectProductCategoriesHitted = (
  isAppendSelectProductCategoriesHitted
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.IS_APPEND_SELECT_PRODUCT_CATEGORIES_HITTED,
    isAppendSelectProductCategoriesHitted
  );
export const setIsFetchProductCategoryHitted = (isFetchProductCategoryHitted) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_PRODUCT_CATEGORY_HITTED,
    isFetchProductCategoryHitted
  );
export const setIsSortProductCategoriesHitted = (
  isSortProductCategoriesHitted
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.IS_SORT_PRODUCT_CATEGORIES_HITTED,
    isSortProductCategoriesHitted
  );
export const setIsCreateProductCategoryHitted = (
  isCreateProductCategoryHitted
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.IS_CREATE_PRODUCT_CATEGORY_HITTED,
    isCreateProductCategoryHitted
  );
export const setIsUpdateProductCategoryHitted = (
  isUpdateProductCategoryHitted
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.IS_UPDATE_PRODUCT_CATEGORY_HITTED,
    isUpdateProductCategoryHitted
  );
export const setIsDeleteProductCategoryHitted = (
  isDeleteProductCategoryHitted
) =>
  createAction(
    PRODUCT_CATEGORY_ACTION_TYPES.IS_DELETE_PRODUCT_CATEGORY_HITTED,
    isDeleteProductCategoryHitted
  );

export const resetProductCategoryReducer = () =>
  createAction(PRODUCT_CATEGORY_ACTION_TYPES.RESET_PRODUCT_CATEGORY_REDUCER);
