import USER_CUSTOMER_ACTION_TYPES from "./user-customer.type";
import { createAction } from "../../utils/store.utils";

export const setUserCustomers = (userCustomers) =>
  createAction(USER_CUSTOMER_ACTION_TYPES.SET_USER_CUSTOMERS, userCustomers);
export const setUserCustomer = (userCustomer) =>
  createAction(USER_CUSTOMER_ACTION_TYPES.SET_USER_CUSTOMER, userCustomer);

export const setIsUserCustomersHasMore = (isUserCustomersHasMore) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_IS_USER_CUSTOMERS_HAS_MORE,
    isUserCustomersHasMore
  );

export const setFetchUserCustomersSearch = (fetchUserCustomersSearch) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_SEARCH,
    fetchUserCustomersSearch
  );
export const setFetchUserCustomersSort = (fetchUserCustomersSort) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_SORT,
    fetchUserCustomersSort
  );
export const setFetchUserCustomersKeyBy = (fetchUserCustomersKeyBy) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_KEY_BY,
    fetchUserCustomersKeyBy
  );
export const setFetchUserCustomersPage = (fetchUserCustomersPage) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_PAGE,
    fetchUserCustomersPage
  );
export const setFetchUserCustomersPerPage = (fetchUserCustomersPerPage) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_PER_PAGE,
    fetchUserCustomersPerPage
  );
export const setFetchUserCustomersIncludes = (fetchUserCustomersIncludes) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_INCLUDES,
    fetchUserCustomersIncludes
  );
export const setFetchUserCustomersFilterMarketId = (
  fetchUserCustomersFilterMarketId
) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_FILTER_MARKET_ID,
    fetchUserCustomersFilterMarketId
  );
export const setFetchUserCustomersLoading = (fetchUserCustomersLoading) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_LOADING,
    fetchUserCustomersLoading
  );
export const setFetchUserCustomersSuccess = (fetchUserCustomersSuccess) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_SUCCESS,
    fetchUserCustomersSuccess
  );
export const setFetchUserCustomersFailed = (fetchUserCustomersFailed) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMERS_FAILED,
    fetchUserCustomersFailed
  );

export const setFetchUserCustomerLoading = (fetchUserCustomerLoading) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMER_LOADING,
    fetchUserCustomerLoading
  );
export const setFetchUserCustomerSuccess = (fetchUserCustomerSuccess) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMER_SUCCESS,
    fetchUserCustomerSuccess
  );
export const setFetchUserCustomerFailed = (fetchUserCustomerFailed) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_FETCH_USER_CUSTOMER_FAILED,
    fetchUserCustomerFailed
  );

export const setCreateUserCustomerLoading = (createUserCustomerLoading) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_CREATE_USER_CUSTOMER_LOADING,
    createUserCustomerLoading
  );
export const setCreateUserCustomerSuccess = (createUserCustomerSuccess) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_CREATE_USER_CUSTOMER_SUCCESS,
    createUserCustomerSuccess
  );
export const setCreateUserCustomerFailed = (createUserCustomerFailed) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_CREATE_USER_CUSTOMER_FAILED,
    createUserCustomerFailed
  );

export const setUpdateUserCustomerLoading = (updateUserCustomerLoading) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_UPDATE_USER_CUSTOMER_LOADING,
    updateUserCustomerLoading
  );
export const setUpdateUserCustomerSuccess = (updateUserCustomerSuccess) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_UPDATE_USER_CUSTOMER_SUCCESS,
    updateUserCustomerSuccess
  );
export const setUpdateUserCustomerFailed = (updateUserCustomerFailed) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_UPDATE_USER_CUSTOMER_FAILED,
    updateUserCustomerFailed
  );

export const setAddMarketUserCustomerLoading = (addMarketUserCustomerLoading) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_ADD_MARKET_USER_CUSTOMER_LOADING,
    addMarketUserCustomerLoading
  );
export const setAddMarketUserCustomerSuccess = (addMarketUserCustomerSuccess) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_ADD_MARKET_USER_CUSTOMER_SUCCESS,
    addMarketUserCustomerSuccess
  );
export const setAddMarketUserCustomerFailed = (addMarketUserCustomerFailed) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_ADD_MARKET_USER_CUSTOMER_FAILED,
    addMarketUserCustomerFailed
  );

export const setRemoveMarketUserCustomerLoading = (
  removeMarketUserCustomerLoading
) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_REMOVE_MARKET_USER_CUSTOMER_LOADING,
    removeMarketUserCustomerLoading
  );
export const setRemoveMarketUserCustomerSuccess = (
  removeMarketUserCustomerSuccess
) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_REMOVE_MARKET_USER_CUSTOMER_SUCCESS,
    removeMarketUserCustomerSuccess
  );
export const setRemoveMarketUserCustomerFailed = (
  removeMarketUserCustomerFailed
) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_REMOVE_MARKET_USER_CUSTOMER_FAILED,
    removeMarketUserCustomerFailed
  );

export const appendUserCustomers = (userCustomers) =>
  createAction(USER_CUSTOMER_ACTION_TYPES.APPEND_USER_CUSTOMERS, userCustomers);

export const fetchUserCustomersStart = () =>
  createAction(USER_CUSTOMER_ACTION_TYPES.FETCH_USER_CUSTOMERS_START);
export const fetchUserCustomerStart = (customerId) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.FETCH_USER_CUSTOMER_START,
    customerId
  );
export const createUserCustomerStart = (request) =>
  createAction(USER_CUSTOMER_ACTION_TYPES.CREATE_USER_CUSTOMER_START, request);
export const updateUserCustomerStart = (customerId, request) =>
  createAction(USER_CUSTOMER_ACTION_TYPES.UPDATE_USER_CUSTOMER_START, {
    customerId,
    request,
  });
export const addMarketUserCustomerStart = (customerId, marketId) =>
  createAction(USER_CUSTOMER_ACTION_TYPES.ADD_MARKET_USER_CUSTOMER_START, {
    customerId,
    marketId,
  });
export const removeMarketUserCustomerStart = (customerId, marketId) =>
  createAction(USER_CUSTOMER_ACTION_TYPES.REMOVE_MARKET_USER_CUSTOMER_START, {
    customerId,
    marketId,
  });

export const setIsFetchUserCustomersHitted = (isFetchUserCustomersHitted) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_IS_FETCH_USER_CUSTOMERS_HITTED,
    isFetchUserCustomersHitted
  );
export const setIsFetchUserCustomerHitted = (isFetchUserCustomerHitted) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_IS_FETCH_USER_CUSTOMER_HITTED,
    isFetchUserCustomerHitted
  );
export const setIsCreateUserCustomerHitted = (isCreateUserCustomerHitted) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_IS_CREATE_USER_CUSTOMER_HITTED,
    isCreateUserCustomerHitted
  );
export const setIsUpdateUserCustomerHitted = (isUpdateUserCustomerHitted) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_IS_UPDATE_USER_CUSTOMER_HITTED,
    isUpdateUserCustomerHitted
  );
export const setIsAddMarketUserCustomerHitted = (
  isAddMarketUserCustomerHitted
) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_IS_ADD_MARKET_USER_CUSTOMER_HITTED,
    isAddMarketUserCustomerHitted
  );
export const setIsRemoveMarketUserCustomerHitted = (
  isRemoveMarketUserCustomerHitted
) =>
  createAction(
    USER_CUSTOMER_ACTION_TYPES.SET_IS_REMOVE_MARKET_USER_CUSTOMER_HITTED,
    isRemoveMarketUserCustomerHitted
  );

export const resetUserCustomerReducer = () =>
  createAction(USER_CUSTOMER_ACTION_TYPES.RESET_USER_CUSTOMER_REDUCER);
