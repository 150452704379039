import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PRODUCT_CATEGORY_ACTION_TYPES from "./product-category.type";

import {
  appendProductCategories,
  appendRelatedProductCategories,
  appendSelectProductCategories,
  setAppendSelectProductCategoriesFailed,
  setAppendSelectProductCategoriesLoading,
  setAppendSelectProductCategoriesSuccess,
  setCreateProductCategoryFailed,
  setCreateProductCategoryLoading,
  setCreateProductCategorySuccess,
  setDeleteProductCategoryFailed,
  setDeleteProductCategoryLoading,
  setDeleteProductCategorySuccess,
  setFetchProductCategoriesFailed,
  setFetchProductCategoriesLoading,
  setFetchProductCategoriesPage,
  setFetchProductCategoriesSuccess,
  setFetchProductCategoryFailed,
  setFetchProductCategoryLoading,
  setFetchProductCategorySuccess,
  setFetchRelatedProductCategoriesFailed,
  setFetchRelatedProductCategoriesLoading,
  setFetchRelatedProductCategoriesPage,
  setFetchRelatedProductCategoriesSuccess,
  setFetchSelectProductCategoriesFailed,
  setFetchSelectProductCategoriesLoading,
  setFetchSelectProductCategoriesPage,
  setFetchSelectProductCategoriesSuccess,
  setIsAppendSelectProductCategoriesHitted,
  setIsCreateProductCategoryHitted,
  setIsDeleteProductCategoryHitted,
  setIsFetchProductCategoriesHitted,
  setIsFetchProductCategoryHitted,
  setIsFetchRelatedProductCategoriesHitted,
  setIsFetchSelectProductCategoriesHitted,
  setIsProductCategoriesHasMore,
  setIsRelatedProductCategoriesHasMore,
  setIsSelectProductCategoriesHasMore,
  setIsSortProductCategoriesHitted,
  setIsUpdateProductCategoryHitted,
  setProductCategories,
  setProductCategory,
  setRelatedProductCategories,
  setSelectProductCategories,
  setSortProductCategoriesFailed,
  setSortProductCategoriesLoading,
  setSortProductCategoriesSuccess,
  setUpdateProductCategoryFailed,
  setUpdateProductCategoryLoading,
  setUpdateProductCategorySuccess,
} from "./product-category.action";
import {
  getAppendSelectProductCategoriesFilterBranchId,
  getAppendSelectProductCategoriesFilterMarketId,
  getAppendSelectProductCategoriesFilterProductCategoriesIds,
  getAppendSelectProductCategoriesFilterSectorId,
  getAppendSelectProductCategoriesIncludes,
  getAppendSelectProductCategoriesKeyBy,
  getAppendSelectProductCategoriesPage,
  getAppendSelectProductCategoriesPerPage,
  getAppendSelectProductCategoriesSearch,
  getAppendSelectProductCategoriesSort,
  getFetchProductCategoriesFilterBranchId,
  getFetchProductCategoriesFilterMarketId,
  getFetchProductCategoriesFilterSectorId,
  getFetchProductCategoriesIncludes,
  getFetchProductCategoriesKeyBy,
  getFetchProductCategoriesPage,
  getFetchProductCategoriesPerPage,
  getFetchProductCategoriesSearch,
  getFetchProductCategoriesSort,
  getFetchRelatedProductCategoriesFilterBranchId,
  getFetchRelatedProductCategoriesFilterMarketId,
  getFetchRelatedProductCategoriesFilterSectorId,
  getFetchRelatedProductCategoriesIncludes,
  getFetchRelatedProductCategoriesKeyBy,
  getFetchRelatedProductCategoriesPage,
  getFetchRelatedProductCategoriesPerPage,
  getFetchRelatedProductCategoriesSearch,
  getFetchRelatedProductCategoriesSort,
  getFetchSelectProductCategoriesFilterBranchId,
  getFetchSelectProductCategoriesFilterMarketId,
  getFetchSelectProductCategoriesFilterProductCategoriesIds,
  getFetchSelectProductCategoriesFilterSectorId,
  getFetchSelectProductCategoriesIncludes,
  getFetchSelectProductCategoriesKeyBy,
  getFetchSelectProductCategoriesPage,
  getFetchSelectProductCategoriesPerPage,
  getFetchSelectProductCategoriesSearch,
  getFetchSelectProductCategoriesSort,
  getIsFetchProductCategoriesHitted,
  getIsFetchRelatedProductCategoriesHitted,
  getIsFetchSelectProductCategoriesHitted,
} from "./product-category.selector";

import {
  getProductCategories,
  getProductCategory,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory,
  sortProductCategories,
} from "../../api/product-category.api";

export function* _getProductCategories() {
  try {
    yield put(setFetchProductCategoriesLoading(true));

    const search = yield select(getFetchProductCategoriesSearch);
    const sort = yield select(getFetchProductCategoriesSort);
    const key_by = yield select(getFetchProductCategoriesKeyBy);
    const page = yield select(getFetchProductCategoriesPage);
    const per_page = yield select(getFetchProductCategoriesPerPage);
    const includes = yield select(getFetchProductCategoriesIncludes);
    const sector_id = yield select(getFetchProductCategoriesFilterSectorId);
    const market_id = yield select(getFetchProductCategoriesFilterMarketId);
    const branch_id = yield select(getFetchProductCategoriesFilterBranchId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        sector_id,
        market_id,
        branch_id,
      },
    };

    const {
      meta: { message },
      data: { data: categories },
    } = yield call(getProductCategories, parameters);

    yield put(setIsFetchProductCategoriesHitted(true));
    yield put(setIsProductCategoriesHasMore(categories.length > 0));

    if (page > 1) {
      yield put(appendProductCategories(categories));
    } else {
      yield put(setProductCategories(categories));
    }

    yield put(setFetchProductCategoriesSuccess(message));
    yield put(setFetchProductCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchProductCategoriesFailed(error));
    yield put(setFetchProductCategoriesLoading(false));
  }
}
export function* _getSelectProductCategories() {
  try {
    yield put(setFetchSelectProductCategoriesLoading(true));

    const search = yield select(getFetchSelectProductCategoriesSearch);
    const sort = yield select(getFetchSelectProductCategoriesSort);
    const key_by = yield select(getFetchSelectProductCategoriesKeyBy);
    const page = yield select(getFetchSelectProductCategoriesPage);
    const per_page = yield select(getFetchSelectProductCategoriesPerPage);
    const includes = yield select(getFetchSelectProductCategoriesIncludes);
    const sector_id = yield select(
      getFetchSelectProductCategoriesFilterSectorId
    );
    const market_id = yield select(
      getFetchSelectProductCategoriesFilterMarketId
    );
    const branch_id = yield select(
      getFetchSelectProductCategoriesFilterBranchId
    );
    const product_categories_ids = yield select(
      getFetchSelectProductCategoriesFilterProductCategoriesIds
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        sector_id,
        market_id,
        branch_id,
        product_categories_ids,
      },
    };

    const {
      meta: { message },
      data: categories,
    } = yield call(getProductCategories, parameters);

    yield put(setIsFetchSelectProductCategoriesHitted(true));
    yield put(
      setIsSelectProductCategoriesHasMore(Object.keys(categories).length > 0)
    );

    if (page > 1) {
      yield put(appendSelectProductCategories(categories));
    } else {
      yield put(setSelectProductCategories(categories));
    }

    yield put(setFetchSelectProductCategoriesSuccess(message));
    yield put(setFetchSelectProductCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchSelectProductCategoriesFailed(error));
    yield put(setFetchSelectProductCategoriesLoading(false));
  }
}
export function* _getRelatedProductCategories() {
  try {
    yield put(setFetchRelatedProductCategoriesLoading(true));

    const search = yield select(getFetchRelatedProductCategoriesSearch);
    const sort = yield select(getFetchRelatedProductCategoriesSort);
    const key_by = yield select(getFetchRelatedProductCategoriesKeyBy);
    const page = yield select(getFetchRelatedProductCategoriesPage);
    const per_page = yield select(getFetchRelatedProductCategoriesPerPage);
    const includes = yield select(getFetchRelatedProductCategoriesIncludes);
    const sector_id = yield select(
      getFetchRelatedProductCategoriesFilterSectorId
    );
    const market_id = yield select(
      getFetchRelatedProductCategoriesFilterMarketId
    );
    const branch_id = yield select(
      getFetchRelatedProductCategoriesFilterBranchId
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        sector_id,
        market_id,
        branch_id,
      },
    };

    const {
      meta: { message },
      data: { data: categories },
    } = yield call(getProductCategories, parameters);

    yield put(setIsFetchRelatedProductCategoriesHitted(true));
    yield put(setIsRelatedProductCategoriesHasMore(categories.length > 0));

    if (page > 1) {
      yield put(appendRelatedProductCategories(categories));
    } else {
      yield put(setRelatedProductCategories(categories));
    }

    yield put(setFetchRelatedProductCategoriesSuccess(message));
    yield put(setFetchRelatedProductCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchRelatedProductCategoriesFailed(error));
    yield put(setFetchRelatedProductCategoriesLoading(false));
  }
}
export function* _getAppendSelectProductCategories() {
  try {
    yield put(setAppendSelectProductCategoriesLoading(true));

    const search = yield select(getAppendSelectProductCategoriesSearch);
    const sort = yield select(getAppendSelectProductCategoriesSort);
    const key_by = yield select(getAppendSelectProductCategoriesKeyBy);
    const page = yield select(getAppendSelectProductCategoriesPage);
    const per_page = yield select(getAppendSelectProductCategoriesPerPage);
    const includes = yield select(getAppendSelectProductCategoriesIncludes);
    const sector_id = yield select(
      getAppendSelectProductCategoriesFilterSectorId
    );
    const market_id = yield select(
      getAppendSelectProductCategoriesFilterMarketId
    );
    const branch_id = yield select(
      getAppendSelectProductCategoriesFilterBranchId
    );
    const product_categories_ids = yield select(
      getAppendSelectProductCategoriesFilterProductCategoriesIds
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        sector_id,
        market_id,
        branch_id,
        product_categories_ids,
      },
    };

    const {
      meta: { message },
      data: categories,
    } = yield call(getProductCategories, parameters);

    yield put(setIsAppendSelectProductCategoriesHitted(true));
    yield put(appendSelectProductCategories(categories));

    yield put(setAppendSelectProductCategoriesSuccess(message));
    yield put(setAppendSelectProductCategoriesLoading(false));
  } catch (error) {
    yield put(setAppendSelectProductCategoriesFailed(error));
    yield put(setAppendSelectProductCategoriesLoading(false));
  }
}
export function* _getProductCategory({ payload: categoryId }) {
  try {
    yield put(setFetchProductCategoryLoading(true));

    const {
      meta: { message },
      data: productCategory,
    } = yield call(getProductCategory, categoryId);

    yield put(setIsFetchProductCategoryHitted(true));
    yield put(setProductCategory(productCategory));

    yield put(setFetchProductCategorySuccess(message));
    yield put(setFetchProductCategoryLoading(false));
  } catch (error) {
    yield put(setFetchProductCategoryFailed(error));
    yield put(setFetchProductCategoryLoading(false));
  }
}
export function* _sortProductCategories({ payload: request }) {
  try {
    yield put(setSortProductCategoriesLoading(true));

    const {
      meta: { message },
    } = yield call(sortProductCategories, request);

    yield put(setIsSortProductCategoriesHitted(true));

    const isFetchProductCategoriesHitted = yield select(
      getIsFetchProductCategoriesHitted
    );
    const isFetchSelectProductCategoriesHitted = yield select(
      getIsFetchSelectProductCategoriesHitted
    );
    const isFetchRelatedProductCategoriesHitted = yield select(
      getIsFetchRelatedProductCategoriesHitted
    );

    if (isFetchProductCategoriesHitted) {
      yield put(setFetchProductCategoriesPage(1));
      yield call(_getProductCategories);
    }
    if (isFetchSelectProductCategoriesHitted) {
      yield put(setFetchSelectProductCategoriesPage(1));
      yield call(_getSelectProductCategories);
    }
    if (isFetchRelatedProductCategoriesHitted) {
      yield put(setFetchRelatedProductCategoriesPage(1));
      yield call(_getRelatedProductCategories);
    }

    yield put(setSortProductCategoriesSuccess(message));
    yield put(setSortProductCategoriesLoading(false));
  } catch (error) {
    yield put(setSortProductCategoriesFailed(error));
    yield put(setSortProductCategoriesLoading(false));
  }
}
export function* _createProductCategory({ payload: request }) {
  try {
    yield put(setCreateProductCategoryLoading(true));

    const {
      meta: { message },
    } = yield call(createProductCategory, request);

    yield put(setIsCreateProductCategoryHitted(true));

    const isFetchProductCategoriesHitted = yield select(
      getIsFetchProductCategoriesHitted
    );
    const isFetchSelectProductCategoriesHitted = yield select(
      getIsFetchSelectProductCategoriesHitted
    );
    const isFetchRelatedProductCategoriesHitted = yield select(
      getIsFetchRelatedProductCategoriesHitted
    );

    if (isFetchProductCategoriesHitted) {
      yield put(setFetchProductCategoriesPage(1));
      yield call(_getProductCategories);
    }
    if (isFetchSelectProductCategoriesHitted) {
      yield put(setFetchSelectProductCategoriesPage(1));
      yield call(_getSelectProductCategories);
    }
    if (isFetchRelatedProductCategoriesHitted) {
      yield put(setFetchRelatedProductCategoriesPage(1));
      yield call(_getRelatedProductCategories);
    }

    yield put(setCreateProductCategorySuccess(message));
    yield put(setCreateProductCategoryLoading(false));
  } catch (error) {
    yield put(setCreateProductCategoryFailed(error));
    yield put(setCreateProductCategoryLoading(false));
  }
}
export function* _updateProductCategory({ payload: { categoryId, request } }) {
  try {
    yield put(setUpdateProductCategoryLoading(true));

    const {
      meta: { message },
    } = yield call(updateProductCategory, categoryId, request);

    yield put(setIsUpdateProductCategoryHitted(true));

    const isFetchProductCategoriesHitted = yield select(
      getIsFetchProductCategoriesHitted
    );
    const isFetchSelectProductCategoriesHitted = yield select(
      getIsFetchSelectProductCategoriesHitted
    );
    const isFetchRelatedProductCategoriesHitted = yield select(
      getIsFetchRelatedProductCategoriesHitted
    );

    if (isFetchProductCategoriesHitted) {
      yield put(setFetchProductCategoriesPage(1));
      yield call(_getProductCategories);
    }
    if (isFetchSelectProductCategoriesHitted) {
      yield put(setFetchSelectProductCategoriesPage(1));
      yield call(_getSelectProductCategories);
    }
    if (isFetchRelatedProductCategoriesHitted) {
      yield put(setFetchRelatedProductCategoriesPage(1));
      yield call(_getRelatedProductCategories);
    }

    yield put(setUpdateProductCategorySuccess(message));
    yield put(setUpdateProductCategoryLoading(false));
  } catch (error) {
    yield put(setUpdateProductCategoryFailed(error));
    yield put(setUpdateProductCategoryLoading(false));
  }
}
export function* _deleteProductCategory({ payload: categoryId }) {
  try {
    yield put(setDeleteProductCategoryLoading(true));

    const {
      meta: { message },
    } = yield call(deleteProductCategory, categoryId);

    yield put(setIsDeleteProductCategoryHitted(true));

    const isFetchProductCategoriesHitted = yield select(
      getIsFetchProductCategoriesHitted
    );
    const isFetchSelectProductCategoriesHitted = yield select(
      getIsFetchSelectProductCategoriesHitted
    );
    const isFetchRelatedProductCategoriesHitted = yield select(
      getIsFetchRelatedProductCategoriesHitted
    );

    if (isFetchProductCategoriesHitted) {
      yield put(setFetchProductCategoriesPage(1));
      yield call(_getProductCategories);
    }
    if (isFetchSelectProductCategoriesHitted) {
      yield put(setFetchSelectProductCategoriesPage(1));
      yield call(_getSelectProductCategories);
    }
    if (isFetchRelatedProductCategoriesHitted) {
      yield put(setFetchRelatedProductCategoriesPage(1));
      yield call(_getRelatedProductCategories);
    }

    yield put(setDeleteProductCategorySuccess(message));
    yield put(setDeleteProductCategoryLoading(false));
  } catch (error) {
    yield put(setDeleteProductCategoryFailed(error));
    yield put(setDeleteProductCategoryLoading(false));
  }
}

export function* onFetchProductCategoriesStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_START,
    _getProductCategories
  );
}
export function* onFetchSelectProductCategoriesStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_SELECT_PRODUCT_CATEGORIES_START,
    _getSelectProductCategories
  );
}
export function* onFetchRelatedProductCategoriesStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_RELATED_PRODUCT_CATEGORIES_START,
    _getRelatedProductCategories
  );
}
export function* onAppendSelectProductCategoriesStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.APPEND_SELECT_PRODUCT_CATEGORIES_START,
    _getAppendSelectProductCategories
  );
}
export function* onFetchProductCategoryStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORY_START,
    _getProductCategory
  );
}
export function* onSortProductCategoriesStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.SORT_PRODUCT_CATEGORIES_START,
    _sortProductCategories
  );
}
export function* onCreateProductCategoryStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.CREATE_PRODUCT_CATEGORY_START,
    _createProductCategory
  );
}
export function* onUpdateProductCategoryStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.UPDATE_PRODUCT_CATEGORY_START,
    _updateProductCategory
  );
}
export function* onDeleteProductCategoryStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_ACTION_TYPES.DELETE_PRODUCT_CATEGORY_START,
    _deleteProductCategory
  );
}

export function* productCategorySaga() {
  yield all([
    call(onFetchProductCategoriesStart),
    call(onFetchSelectProductCategoriesStart),
    call(onFetchRelatedProductCategoriesStart),
    call(onAppendSelectProductCategoriesStart),
    call(onFetchProductCategoryStart),
    call(onSortProductCategoriesStart),
    call(onCreateProductCategoryStart),
    call(onUpdateProductCategoryStart),
    call(onDeleteProductCategoryStart),
  ]);
}
