import apiService from "./api";

export const downloadReportOrder = async (parameters) =>
  (await apiService.get("/v2/export/report/orders", parameters)).data;
export const downloadReportSummary = async (parameters) =>
  (await apiService.get("/v2/export/report/summaries", parameters)).data;

export const downloadTableQrCode = async (parameters) =>
  (await apiService.get("/export/table-qrcode", parameters)).data;
export const downloadProductBarcode = async (parameters) =>
  (await apiService.get("/export/product-barcode", parameters)).data;

export const downloadCustomerReport = async (parameters) =>
  await apiService.blobGet("/export/customer", parameters);

export const downloadIngredientReport = async (parameters) =>
  await apiService.blobGet("/export/ingredient", parameters);

export const downloadIngredientHistoryReport = async (parameters) =>
  await apiService.blobGet("/export/ingredient-history", parameters);

export const downloadSubscriptionReceipt = async (id, parameters) =>
  await apiService.blobGet(`/pdf/extend-period/receipt/${id}`, parameters);

export const exportPdfOrderInvoice = async (orderId, request) =>
  (
    await apiService.post(
      `/v2/export/pdf/orders/${orderId}/invoice/generate`,
      request
    )
  ).data;
export const exportPdfOrderKitchen = async (orderId, request) =>
  (
    await apiService.post(
      `/v2/export/pdf/orders/${orderId}/kitchen/generate`,
      request
    )
  ).data;

export const exportExcelReports = async (request) =>
  (await apiService.post(`/v2/export/excel/reports/generate`, request)).data;
