import apiService from "./api";

export const getUserSuppliers = async (parameters) =>
  (await apiService.get("/v2/suppliers/suppliers", parameters)).data;
export const getUserSupplier = async (supplierId) =>
  (await apiService.get(`/v2/suppliers/suppliers/${supplierId}`)).data;
export const createUserSupplier = async (request) =>
  (await apiService.post("/v2/suppliers/suppliers", request)).data;
export const updateUserSupplier = async (supplierId, request) =>
  (await apiService.post(`/v2/suppliers/suppliers/${supplierId}`, request))
    .data;
export const deleteUserSupplier = async (supplierId) =>
  (await apiService.delete(`/v2/suppliers/suppliers/${supplierId}`)).data;
