import { createSelector } from "reselect";

const globalSelector = ({ global }) => global;

export const getIsRightToLeft = createSelector(
  [globalSelector],
  ({ isRightToLeft }) => isRightToLeft
);
export const getIsGlobalLoading = createSelector(
  [globalSelector],
  ({ isGlobalLoading }) => isGlobalLoading
);

export const getAuthorizePages = createSelector(
  [globalSelector],
  ({ authorizePages }) => authorizePages
);
export const getIsMarketAdministrator = createSelector(
  [globalSelector],
  ({ isMarketAdministrator }) => isMarketAdministrator
);
export const getIsCenterAdministrator = createSelector(
  [globalSelector],
  ({ isCenterAdministrator }) => isCenterAdministrator
);

export const getCurrentMarket = createSelector(
  [globalSelector],
  ({ currentMarket }) => currentMarket
);
export const getCurrentBranch = createSelector(
  [globalSelector],
  ({ currentBranch }) => currentBranch
);
export const getCurrentMerchant = createSelector(
  [globalSelector],
  ({ currentMerchant }) => currentMerchant
);
export const getCurrentPermission = createSelector(
  [globalSelector],
  ({ currentPermission }) => currentPermission
);
export const getCurrentSchedules = createSelector(
  [globalSelector],
  ({ currentSchedules }) => currentSchedules
);
export const getCurrentExtendPeriods = createSelector(
  [globalSelector],
  ({ currentExtendPeriods }) => currentExtendPeriods
);

export const getCurrentMarketId = createSelector(
  [globalSelector],
  ({ currentMarketId }) => currentMarketId
);
export const getCurrentBranchId = createSelector(
  [globalSelector],
  ({ currentBranchId }) => currentBranchId
);
export const getCurrentMerchantId = createSelector(
  [globalSelector],
  ({ currentMerchantId }) => currentMerchantId
);
export const getCurrentPermissionId = createSelector(
  [globalSelector],
  ({ currentPermissionId }) => currentPermissionId
);
export const getIsCurrentKitchenActive = createSelector(
  [globalSelector],
  ({ isCurrentKitchenActive }) => isCurrentKitchenActive
);

export const getTodaySchedule = createSelector(
  [globalSelector],
  ({ todaySchedule }) => todaySchedule
);
export const getIsTodayInSchedule = createSelector(
  [globalSelector],
  ({ isTodayInSchedule }) => isTodayInSchedule
);

export const getTodayAttendance = createSelector(
  [globalSelector],
  ({ todayAttendance }) => todayAttendance
);
export const getIsTodayHasAttendance = createSelector(
  [globalSelector],
  ({ isTodayHasAttendance }) => isTodayHasAttendance
);

export const getActiveSubscription = createSelector(
  [globalSelector],
  ({ activeSubscription }) => activeSubscription
);
export const getIsSubscription = createSelector(
  [globalSelector],
  ({ isSubscription }) => isSubscription
);
export const getIsAdvancedSubscription = createSelector(
  [globalSelector],
  ({ isAdvancedSubscription }) => isAdvancedSubscription
);
export const getIsHasSubscription = createSelector(
  [globalSelector],
  ({ isHasSubscription }) => isHasSubscription
);
export const getIsHasAdvancedSubscription = createSelector(
  [globalSelector],
  ({ isHasAdvancedSubscription }) => isHasAdvancedSubscription
);

export const getFetchCurrentMarketLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentMarketLoading }) => fetchCurrentMarketLoading
);
export const getFetchCurrentMarketSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentMarketSuccess }) => fetchCurrentMarketSuccess
);
export const getFetchCurrentMarketFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentMarketFailed }) => fetchCurrentMarketFailed
);

export const getFetchCurrentBranchLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentBranchLoading }) => fetchCurrentBranchLoading
);
export const getFetchCurrentBranchSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentBranchSuccess }) => fetchCurrentBranchSuccess
);
export const getFetchCurrentBranchFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentBranchFailed }) => fetchCurrentBranchFailed
);

export const getFetchCurrentMerchantLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentMerchantLoading }) => fetchCurrentMerchantLoading
);
export const getFetchCurrentMerchantSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentMerchantSuccess }) => fetchCurrentMerchantSuccess
);
export const getFetchCurrentMerchantFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentMerchantFailed }) => fetchCurrentMerchantFailed
);

export const getFetchCurrentPermissionLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentPermissionLoading }) => fetchCurrentPermissionLoading
);
export const getFetchCurrentPermissionSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentPermissionSuccess }) => fetchCurrentPermissionSuccess
);
export const getFetchCurrentPermissionFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentPermissionFailed }) => fetchCurrentPermissionFailed
);

export const getFetchCurrentSchedulesIncludes = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesIncludes }) => fetchCurrentSchedulesIncludes
);
export const getFetchCurrentSchedulesFilterMarketId = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesFilterMarketId }) =>
    fetchCurrentSchedulesFilterMarketId
);
export const getFetchCurrentSchedulesFilterBranchId = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesFilterBranchId }) =>
    fetchCurrentSchedulesFilterBranchId
);
export const getFetchCurrentSchedulesLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesLoading }) => fetchCurrentSchedulesLoading
);
export const getFetchCurrentSchedulesSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesSuccess }) => fetchCurrentSchedulesSuccess
);
export const getFetchCurrentSchedulesFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesFailed }) => fetchCurrentSchedulesFailed
);

export const getFetchCurrentExtendPeriodsSearch = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsSearch }) => fetchCurrentExtendPeriodsSearch
);
export const getFetchCurrentExtendPeriodsPage = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsPage }) => fetchCurrentExtendPeriodsPage
);
export const getFetchCurrentExtendPeriodsPerPage = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsPerPage }) => fetchCurrentExtendPeriodsPerPage
);
export const getFetchCurrentExtendPeriodsKeyBy = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsKeyBy }) => fetchCurrentExtendPeriodsKeyBy
);
export const getFetchCurrentExtendPeriodsIncludes = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsIncludes }) => fetchCurrentExtendPeriodsIncludes
);
export const getFetchCurrentExtendPeriodsFilterMarketId = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsFilterMarketId }) =>
    fetchCurrentExtendPeriodsFilterMarketId
);
export const getFetchCurrentExtendPeriodsFilterBranchId = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsFilterBranchId }) =>
    fetchCurrentExtendPeriodsFilterBranchId
);
export const getFetchCurrentExtendPeriodsFilterStatus = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsFilterStatus }) =>
    fetchCurrentExtendPeriodsFilterStatus
);
export const getFetchCurrentExtendPeriodsFilterStartAtBefore = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsFilterStartAtBefore }) =>
    fetchCurrentExtendPeriodsFilterStartAtBefore
);
export const getFetchCurrentExtendPeriodsFilterStartAtAfter = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsFilterStartAtAfter }) =>
    fetchCurrentExtendPeriodsFilterStartAtAfter
);
export const getFetchCurrentExtendPeriodsFilterEndAtBefore = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsFilterEndAtBefore }) =>
    fetchCurrentExtendPeriodsFilterEndAtBefore
);
export const getFetchCurrentExtendPeriodsFilterEndAtAfter = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsFilterEndAtAfter }) =>
    fetchCurrentExtendPeriodsFilterEndAtAfter
);
export const getFetchCurrentExtendPeriodsFilterCreatedBy = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsFilterCreatedBy }) =>
    fetchCurrentExtendPeriodsFilterCreatedBy
);
export const getFetchCurrentExtendPeriodsLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsLoading }) => fetchCurrentExtendPeriodsLoading
);
export const getFetchCurrentExtendPeriodsSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsSuccess }) => fetchCurrentExtendPeriodsSuccess
);
export const getFetchCurrentExtendPeriodsFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentExtendPeriodsFailed }) => fetchCurrentExtendPeriodsFailed
);

export const getFetchTodayAttendanceMarketId = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceMarketId }) => fetchTodayAttendanceMarketId
);
export const getFetchTodayAttendanceBranchId = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceBranchId }) => fetchTodayAttendanceBranchId
);
export const getFetchTodayAttendanceLoading = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceLoading }) => fetchTodayAttendanceLoading
);
export const getFetchTodayAttendanceSuccess = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceSuccess }) => fetchTodayAttendanceSuccess
);
export const getFetchTodayAttendanceFailed = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceFailed }) => fetchTodayAttendanceFailed
);

export const getIsFetchCurrentMarketHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentMarketHitted }) => isFetchCurrentMarketHitted
);
export const getIsFetchCurrentBranchHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentBranchHitted }) => isFetchCurrentBranchHitted
);
export const getIsFetchCurrentMerchantHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentMerchantHitted }) => isFetchCurrentMerchantHitted
);
export const getIsFetchCurrentPermissionHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentPermissionHitted }) => isFetchCurrentPermissionHitted
);
export const getIsFetchCurrentSchedulesHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentSchedulesHitted }) => isFetchCurrentSchedulesHitted
);
export const getIsFetchCurrentExtendPeriodsHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentExtendPeriodsHitted }) => isFetchCurrentExtendPeriodsHitted
);
export const getIsFetchTodayAttendanceHitted = createSelector(
  [globalSelector],
  ({ isFetchTodayAttendanceHitted }) => isFetchTodayAttendanceHitted
);
