import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { saveAs } from "file-saver";

import { getFileNameUrl } from "../../utils/formatter.utils";

import EXPORT_PDF_ACTION_TYPES from "./export-pdf.type";

import {
  setExportPdfOrderInvoice,
  setExportPdfOrderInvoiceFailed,
  setExportPdfOrderInvoiceLoading,
  setExportPdfOrderInvoiceSuccess,
  setExportPdfOrderKitchen,
  setExportPdfOrderKitchenFailed,
  setExportPdfOrderKitchenLoading,
  setExportPdfOrderKitchenSuccess,
  setIsExportPdfOrderInvoiceHitted,
  setIsExportPdfOrderKitchenHitted,
} from "./export-pdf.action";
import {} from "./export-pdf.selector";

import {
  exportPdfOrderInvoice,
  exportPdfOrderKitchen,
} from "../../api/export.api";

export function* _exportPdfOrderInvoice({ payload: { orderId, request } }) {
  try {
    yield put(setExportPdfOrderInvoiceLoading(true));

    const {
      meta: { message },
      data: pdfOrderInvoice,
    } = yield call(exportPdfOrderInvoice, orderId, request);

    yield put(setIsExportPdfOrderInvoiceHitted(true));
    yield put(
      setExportPdfOrderInvoice(`${pdfOrderInvoice}?time=${Date.now()}`)
    );

    yield put(setExportPdfOrderInvoiceSuccess(message));
    yield put(setExportPdfOrderInvoiceLoading(false));
  } catch (error) {
    yield put(setExportPdfOrderInvoiceFailed(error));
    yield put(setExportPdfOrderInvoiceLoading(false));
  }
}
export function* _exportPdfOrderKitchen({ payload: { orderId, request } }) {
  try {
    yield put(setExportPdfOrderKitchenLoading(true));

    const {
      meta: { message },
      data: pdfOrderKitchen,
    } = yield call(exportPdfOrderKitchen, orderId, request);

    yield put(setIsExportPdfOrderKitchenHitted(true));
    yield put(
      setExportPdfOrderKitchen(`${pdfOrderKitchen}?time=${Date.now()}`)
    );

    yield put(setExportPdfOrderKitchenSuccess(message));
    yield put(setExportPdfOrderKitchenLoading(false));
  } catch (error) {
    yield put(setExportPdfOrderKitchenFailed(error));
    yield put(setExportPdfOrderKitchenLoading(false));
  }
}

export function* onExportPdfOrderInvoiceStart() {
  yield takeLatest(
    EXPORT_PDF_ACTION_TYPES.EXPORT_PDF_ORDER_INVOICE_START,
    _exportPdfOrderInvoice
  );
}
export function* onExportPdfOrderKitchenStart() {
  yield takeLatest(
    EXPORT_PDF_ACTION_TYPES.EXPORT_PDF_ORDER_KITCHEN_START,
    _exportPdfOrderKitchen
  );
}

export function* exportPdfSaga() {
  yield all([
    call(onExportPdfOrderInvoiceStart),
    call(onExportPdfOrderKitchenStart),
  ]);
}
