import apiService from "./api";

export const doSignIn = async (request) =>
  (await apiService.post("/v2/authentications/sign-in", request)).data;
export const doSignUp = async (request) =>
  (await apiService.post("/v2/authentications/sign-up/subscriber", request))
    .data;
export const getMe = async () => (await apiService.get("/me")).data;
export const updateMe = async (request) =>
  (await apiService.post("/me", request)).data;
