import COUNTRY_ACTION_TYPES from "./country.type";
import { createAction } from "../../utils/store.utils";

export const setCountries = (countries) => createAction(COUNTRY_ACTION_TYPES.SET_COUNTRIES, countries);
export const setSelectCountries = (selectCountries) =>
  createAction(COUNTRY_ACTION_TYPES.SET_SELECT_COUNTRIES, selectCountries);
export const setCountry = (country) => createAction(COUNTRY_ACTION_TYPES.SET_COUNTRY, country);

export const setIsCountriesHasMore = (isCountriesHasMore) =>
  createAction(COUNTRY_ACTION_TYPES.SET_IS_COUNTRIES_HAS_MORE, isCountriesHasMore);
export const setIsSelectCountriesHasMore = (isSelectCountriesHasMore) =>
  createAction(COUNTRY_ACTION_TYPES.SET_IS_SELECT_COUNTRIES_HAS_MORE, isSelectCountriesHasMore);

export const setFetchCountriesSearch = (fetchCountriesSearch) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_SEARCH, fetchCountriesSearch);
export const setFetchCountriesKeyBy = (fetchCountriesKeyBy) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_KEY_BY, fetchCountriesKeyBy);
export const setFetchCountriesPage = (fetchCountriesPage) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_PAGE, fetchCountriesPage);
export const setFetchCountriesPerPage = (fetchCountriesPerPage) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_PER_PAGE, fetchCountriesPerPage);
export const setFetchCountriesIncludes = (fetchCountriesIncludes) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_INCLUDES, fetchCountriesIncludes);
export const setFetchCountriesLoading = (fetchCountriesLoading) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_LOADING, fetchCountriesLoading);
export const setFetchCountriesSuccess = (fetchCountriesSuccess) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_SUCCESS, fetchCountriesSuccess);
export const setFetchCountriesFailed = (fetchCountriesFailed) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_FAILED, fetchCountriesFailed);

export const setFetchSelectCountriesSearch = (fetchSelectCountriesSearch) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_SEARCH, fetchSelectCountriesSearch);
export const setFetchSelectCountriesKeyBy = (fetchSelectCountriesKeyBy) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_KEY_BY, fetchSelectCountriesKeyBy);
export const setFetchSelectCountriesPage = (fetchSelectCountriesPage) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_PAGE, fetchSelectCountriesPage);
export const setFetchSelectCountriesPerPage = (fetchSelectCountriesPerPage) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_PER_PAGE, fetchSelectCountriesPerPage);
export const setFetchSelectCountriesIncludes = (fetchSelectCountriesIncludes) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_INCLUDES, fetchSelectCountriesIncludes);
export const setFetchSelectCountriesLoading = (fetchSelectCountriesLoading) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_LOADING, fetchSelectCountriesLoading);
export const setFetchSelectCountriesSuccess = (fetchSelectCountriesSuccess) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_SUCCESS, fetchSelectCountriesSuccess);
export const setFetchSelectCountriesFailed = (fetchSelectCountriesFailed) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_FAILED, fetchSelectCountriesFailed);

export const setFetchCountryLoading = (fetchCountryLoading) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRY_LOADING, fetchCountryLoading);
export const setFetchCountrySuccess = (fetchCountrySuccess) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRY_SUCCESS, fetchCountrySuccess);
export const setFetchCountryFailed = (fetchCountryFailed) =>
  createAction(COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRY_FAILED, fetchCountryFailed);

export const setCreateCountryLoading = (createCountryLoading) =>
  createAction(COUNTRY_ACTION_TYPES.SET_CREATE_COUNTRY_LOADING, createCountryLoading);
export const setCreateCountrySuccess = (createCountrySuccess) =>
  createAction(COUNTRY_ACTION_TYPES.SET_CREATE_COUNTRY_SUCCESS, createCountrySuccess);
export const setCreateCountryFailed = (createCountryFailed) =>
  createAction(COUNTRY_ACTION_TYPES.SET_CREATE_COUNTRY_FAILED, createCountryFailed);

export const setUpdateCountryLoading = (updateCountryLoading) =>
  createAction(COUNTRY_ACTION_TYPES.SET_UPDATE_COUNTRY_LOADING, updateCountryLoading);
export const setUpdateCountrySuccess = (updateCountrySuccess) =>
  createAction(COUNTRY_ACTION_TYPES.SET_UPDATE_COUNTRY_SUCCESS, updateCountrySuccess);
export const setUpdateCountryFailed = (updateCountryFailed) =>
  createAction(COUNTRY_ACTION_TYPES.SET_UPDATE_COUNTRY_FAILED, updateCountryFailed);

export const setDeleteCountryLoading = (deleteCountryLoading) =>
  createAction(COUNTRY_ACTION_TYPES.SET_DELETE_COUNTRY_LOADING, deleteCountryLoading);
export const setDeleteCountrySuccess = (deleteCountrySuccess) =>
  createAction(COUNTRY_ACTION_TYPES.SET_DELETE_COUNTRY_SUCCESS, deleteCountrySuccess);
export const setDeleteCountryFailed = (deleteCountryFailed) =>
  createAction(COUNTRY_ACTION_TYPES.SET_DELETE_COUNTRY_FAILED, deleteCountryFailed);

export const setSyncRateCountryLoading = (syncRateCountryLoading) =>
  createAction(COUNTRY_ACTION_TYPES.SET_SYNC_RATE_COUNTRY_LOADING, syncRateCountryLoading);
export const setSyncRateCountrySuccess = (syncRateCountrySuccess) =>
  createAction(COUNTRY_ACTION_TYPES.SET_SYNC_RATE_COUNTRY_SUCCESS, syncRateCountrySuccess);
export const setSyncRateCountryFailed = (syncRateCountryFailed) =>
  createAction(COUNTRY_ACTION_TYPES.SET_SYNC_RATE_COUNTRY_FAILED, syncRateCountryFailed);

export const appendCountries = (countries) => createAction(COUNTRY_ACTION_TYPES.APPEND_COUNTRIES, countries);
export const appendSelectCountries = (selectCountries) =>
  createAction(COUNTRY_ACTION_TYPES.APPEND_SELECT_COUNTRIES, selectCountries);

export const setIsFetchCountriesHitted = (isFetchCountriesHitted) =>
  createAction(COUNTRY_ACTION_TYPES.SET_IS_FETCH_COUNTRIES_HITTED, isFetchCountriesHitted);
export const setIsFetchSelectCountriesHitted = (isFetchSelectCountriesHitted) =>
  createAction(COUNTRY_ACTION_TYPES.SET_IS_FETCH_SELECT_COUNTRIES_HITTED, isFetchSelectCountriesHitted);
export const setIsFetchCountryHitted = (isFetchCountryHitted) =>
  createAction(COUNTRY_ACTION_TYPES.SET_IS_FETCH_COUNTRY_HITTED, isFetchCountryHitted);
export const setIsCreateCountryHitted = (isCreateCountryHitted) =>
  createAction(COUNTRY_ACTION_TYPES.SET_IS_CREATE_COUNTRY_HITTED, isCreateCountryHitted);
export const setIsUpdateCountryHitted = (isUpdateCountryHitted) =>
  createAction(COUNTRY_ACTION_TYPES.SET_IS_UPDATE_COUNTRY_HITTED, isUpdateCountryHitted);
export const setIsDeleteCountryHitted = (isDeleteCountryHitted) =>
  createAction(COUNTRY_ACTION_TYPES.SET_IS_DELETE_COUNTRY_HITTED, isDeleteCountryHitted);
export const setIsSyncRateCountryHitted = (isSyncRateCountryHitted) =>
  createAction(COUNTRY_ACTION_TYPES.SET_IS_DELETE_COUNTRY_HITTED, isSyncRateCountryHitted);

export const fetchCountriesStart = () => createAction(COUNTRY_ACTION_TYPES.FETCH_COUNTRIES_START);
export const fetchSelectCountriesStart = () => createAction(COUNTRY_ACTION_TYPES.FETCH_SELECT_COUNTRIES_START);
export const fetchCountryStart = (id) => createAction(COUNTRY_ACTION_TYPES.FETCH_COUNTRY_START, id);
export const createCountryStart = (request) => createAction(COUNTRY_ACTION_TYPES.CREATE_COUNTRY_START, request);
export const updateCountryStart = (id, request) =>
  createAction(COUNTRY_ACTION_TYPES.UPDATE_COUNTRY_START, { id, request });
export const deleteCountryStart = (id) => createAction(COUNTRY_ACTION_TYPES.DELETE_COUNTRY_START, id);
export const syncRateCountryStart = (id, request) =>
  createAction(COUNTRY_ACTION_TYPES.SYNC_RATE_COUNTRY_START, { id, request });

export const resetCountryReducer = () => createAction(COUNTRY_ACTION_TYPES.RESET_COUNTRY_REDUCER);
