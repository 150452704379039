import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PRODUCT_ACTION_TYPES from "./product.type";

import { _getRelatedProductCategories } from "../product-category/product-category.saga";

import { setFetchRelatedProductCategoriesPage } from "../product-category/product-category.action";
import {
  appendOrderProducts,
  appendProducts,
  appendSelectProducts,
  setAppendSelectProductsFailed,
  setAppendSelectProductsLoading,
  setAppendSelectProductsSuccess,
  setCreateProductFailed,
  setCreateProductLoading,
  setCreateProductSuccess,
  setDeleteProductFailed,
  setDeleteProductLoading,
  setDeleteProductSuccess,
  setFetchOrderProductsFailed,
  setFetchOrderProductsLoading,
  setFetchOrderProductsPage,
  setFetchOrderProductsSuccess,
  setFetchProductFailed,
  setFetchProductLoading,
  setFetchProductsFailed,
  setFetchProductsLoading,
  setFetchProductsPage,
  setFetchProductsSuccess,
  setFetchProductSuccess,
  setFetchSelectProductsFailed,
  setFetchSelectProductsLoading,
  setFetchSelectProductsPage,
  setFetchSelectProductsSuccess,
  setIsAppendSelectProductsHitted,
  setIsCreateProductHitted,
  setIsDeleteProductHitted,
  setIsFetchOrderProductsHitted,
  setIsFetchProductHitted,
  setIsFetchSelectProductsHitted,
  setIsOrderProductsHasMore,
  setIsProductsHasMore,
  setIsSelectProductsHasMore,
  setIsUpdateProductHitted,
  setMultipleCreateProductsFailed,
  setMultipleCreateProductsLoading,
  setMultipleCreateProductsSuccess,
  setMultipleDeleteProductsFailed,
  setMultipleDeleteProductsLoading,
  setMultipleDeleteProductsSuccess,
  setOrderProducts,
  setProduct,
  setProducts,
  setSelectProducts,
  setUpdateProductFailed,
  setUpdateProductLoading,
  setUpdateProductSuccess,
} from "./product.action";
import {
  getAppendSelectProductsFilterBranchId,
  getAppendSelectProductsFilterIsActive,
  getAppendSelectProductsFilterMarketId,
  getAppendSelectProductsFilterProductCategoryId,
  getAppendSelectProductsFilterProductsIds,
  getAppendSelectProductsIncludes,
  getAppendSelectProductsKeyBy,
  getAppendSelectProductsPage,
  getAppendSelectProductsPerPage,
  getAppendSelectProductsSearch,
  getAppendSelectProductsSort,
  getFetchOrderProductsFilterBranchId,
  getFetchOrderProductsFilterIsActive,
  getFetchOrderProductsFilterMarketId,
  getFetchOrderProductsFilterProductCategoryId,
  getFetchOrderProductsFilterProductsIds,
  getFetchOrderProductsIncludes,
  getFetchOrderProductsKeyBy,
  getFetchOrderProductsPage,
  getFetchOrderProductsPerPage,
  getFetchOrderProductsSearch,
  getFetchOrderProductsSort,
  getFetchProductsFilterBranchId,
  getFetchProductsFilterIsActive,
  getFetchProductsFilterMarketId,
  getFetchProductsFilterProductCategoryId,
  getFetchProductsFilterProductsIds,
  getFetchProductsIncludes,
  getFetchProductsKeyBy,
  getFetchProductsPage,
  getFetchProductsPerPage,
  getFetchProductsSearch,
  getFetchProductsSort,
  getFetchSelectProductsFilterBranchId,
  getFetchSelectProductsFilterIsActive,
  getFetchSelectProductsFilterMarketId,
  getFetchSelectProductsFilterProductCategoryId,
  getFetchSelectProductsFilterProductsIds,
  getFetchSelectProductsIncludes,
  getFetchSelectProductsKeyBy,
  getFetchSelectProductsPage,
  getFetchSelectProductsPerPage,
  getFetchSelectProductsSearch,
  getFetchSelectProductsSort,
  getOrderProducts,
  getProducts as getProductsSelector,
  getSelectProducts,
} from "./product.selector";
import { getRelatedProductCategories } from "../product-category/product-category.selector";

import {
  getProducts,
  getProduct,
  createProduct,
  updateProduct,
  deleteProduct,
  multiDeleteProducts,
  multiCreateProducts,
} from "../../api/product.api";

export function* _getProducts() {
  try {
    yield put(setFetchProductsLoading(true));

    const search = yield select(getFetchProductsSearch);
    const sort = yield select(getFetchProductsSort);
    const key_by = yield select(getFetchProductsKeyBy);
    const page = yield select(getFetchProductsPage);
    const per_page = yield select(getFetchProductsPerPage);
    const includes = yield select(getFetchProductsIncludes);
    const market_id = yield select(getFetchProductsFilterMarketId);
    const branch_id = yield select(getFetchProductsFilterBranchId);
    const product_category_id = yield select(
      getFetchProductsFilterProductCategoryId
    );
    const products_ids = yield select(getFetchProductsFilterProductsIds);
    const is_active = yield select(getFetchProductsFilterIsActive);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        product_category_id,
        products_ids,
        is_active,
      },
    };

    const {
      meta: { message },
      data: { data: products },
    } = yield call(getProducts, parameters);

    yield put(setIsProductsHasMore(products.length > 0));

    if (page > 1) {
      yield put(appendProducts(products));
    } else {
      yield put(setProducts(products));
    }

    yield put(setFetchProductsSuccess(message));
    yield put(setFetchProductsLoading(false));
  } catch (error) {
    yield put(setFetchProductsFailed(error));
    yield put(setFetchProductsLoading(false));
  }
}
export function* _getSelectProducts() {
  try {
    yield put(setFetchSelectProductsLoading(true));

    const search = yield select(getFetchSelectProductsSearch);
    const sort = yield select(getFetchSelectProductsSort);
    const key_by = yield select(getFetchSelectProductsKeyBy);
    const page = yield select(getFetchSelectProductsPage);
    const per_page = yield select(getFetchSelectProductsPerPage);
    const includes = yield select(getFetchSelectProductsIncludes);
    const market_id = yield select(getFetchSelectProductsFilterMarketId);
    const branch_id = yield select(getFetchSelectProductsFilterBranchId);
    const product_category_id = yield select(
      getFetchSelectProductsFilterProductCategoryId
    );
    const products_ids = yield select(getFetchSelectProductsFilterProductsIds);
    const is_active = yield select(getFetchSelectProductsFilterIsActive);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        product_category_id,
        products_ids,
        is_active,
      },
    };

    const {
      meta: { message },
      data: products,
    } = yield call(getProducts, parameters);

    yield put(setIsFetchSelectProductsHitted(true));
    yield put(setIsSelectProductsHasMore(Object.keys(products).length > 0));

    if (page > 1) {
      yield put(appendSelectProducts(products));
    } else {
      yield put(setSelectProducts(products));
    }

    yield put(setFetchSelectProductsSuccess(message));
    yield put(setFetchSelectProductsLoading(false));
  } catch (error) {
    yield put(setFetchSelectProductsFailed(error));
    yield put(setFetchSelectProductsLoading(false));
  }
}
export function* _getOrderProducts() {
  try {
    yield put(setFetchOrderProductsLoading(true));

    const search = yield select(getFetchOrderProductsSearch);
    const sort = yield select(getFetchOrderProductsSort);
    const key_by = yield select(getFetchOrderProductsKeyBy);
    const page = yield select(getFetchOrderProductsPage);
    const per_page = yield select(getFetchOrderProductsPerPage);
    const includes = yield select(getFetchOrderProductsIncludes);
    const market_id = yield select(getFetchOrderProductsFilterMarketId);
    const branch_id = yield select(getFetchOrderProductsFilterBranchId);
    const product_category_id = yield select(
      getFetchOrderProductsFilterProductCategoryId
    );
    const products_ids = yield select(getFetchOrderProductsFilterProductsIds);
    const is_active = yield select(getFetchOrderProductsFilterIsActive);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        product_category_id,
        products_ids,
        is_active,
      },
    };

    const {
      meta: { message },
      data: { data: products },
    } = yield call(getProducts, parameters);

    yield put(setIsFetchOrderProductsHitted(products.length > 0));
    yield put(setIsOrderProductsHasMore(products.length > 0));

    if (page > 1) {
      yield put(appendOrderProducts(products));
    } else {
      yield put(setOrderProducts(products));
    }

    yield put(setFetchOrderProductsSuccess(message));
    yield put(setFetchOrderProductsLoading(false));
  } catch (error) {
    yield put(setFetchOrderProductsFailed(error));
    yield put(setFetchOrderProductsLoading(false));
  }
}
export function* _getAppendSelectProducts() {
  try {
    yield put(setAppendSelectProductsLoading(true));

    const search = yield select(getAppendSelectProductsSearch);
    const sort = yield select(getAppendSelectProductsSort);
    const key_by = yield select(getAppendSelectProductsKeyBy);
    const page = yield select(getAppendSelectProductsPage);
    const per_page = yield select(getAppendSelectProductsPerPage);
    const includes = yield select(getAppendSelectProductsIncludes);
    const market_id = yield select(getAppendSelectProductsFilterMarketId);
    const branch_id = yield select(getAppendSelectProductsFilterBranchId);
    const product_category_id = yield select(
      getAppendSelectProductsFilterProductCategoryId
    );
    const products_ids = yield select(getAppendSelectProductsFilterProductsIds);
    const is_active = yield select(getAppendSelectProductsFilterIsActive);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        product_category_id,
        products_ids,
        is_active,
      },
    };

    const {
      meta: { message },
      data: products,
    } = yield call(getProducts, parameters);

    yield put(setIsAppendSelectProductsHitted(true));
    yield put(appendSelectProducts(products));

    yield put(setAppendSelectProductsSuccess(message));
    yield put(setAppendSelectProductsLoading(false));
  } catch (error) {
    yield put(setAppendSelectProductsFailed(error));
    yield put(setAppendSelectProductsLoading(false));
  }
}
export function* _getProduct({ payload: productId }) {
  try {
    yield put(setFetchProductLoading(true));

    const {
      meta: { message },
      data: product,
    } = yield call(getProduct, productId);

    yield put(setIsFetchProductHitted(true));
    yield put(setProduct(product));

    yield put(setFetchProductSuccess(message));
    yield put(setFetchProductLoading(false));
  } catch (error) {
    yield put(setFetchProductFailed(error));
    yield put(setFetchProductLoading(false));
  }
}
export function* _createProduct({ payload: request }) {
  try {
    yield put(setCreateProductLoading(true));

    const {
      meta: { message },
    } = yield call(createProduct, request);

    yield put(setIsCreateProductHitted(true));

    const products = yield select(getProductsSelector);
    const selectProducts = yield select(getSelectProducts);
    const orderProducts = yield select(getOrderProducts);
    const relatedProductCategories = yield select(getRelatedProductCategories);

    if (products.length > 0) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (selectProducts.length > 0) {
      yield put(setFetchSelectProductsPage(1));
      yield call(_getSelectProducts);
    }
    if (orderProducts.length > 0) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (relatedProductCategories.length > 0) {
      yield put(setFetchRelatedProductCategoriesPage(1));
      yield call(_getRelatedProductCategories);
    }

    yield put(setCreateProductSuccess(message));
    yield put(setCreateProductLoading(false));
  } catch (error) {
    yield put(setCreateProductFailed(error));
    yield put(setCreateProductLoading(false));
  }
}
export function* _updateProduct({ payload: { productId, request } }) {
  try {
    yield put(setUpdateProductLoading(true));

    const {
      meta: { message },
    } = yield call(updateProduct, productId, request);

    yield put(setIsUpdateProductHitted(true));

    const products = yield select(getProductsSelector);
    const selectProducts = yield select(getSelectProducts);
    const orderProducts = yield select(getOrderProducts);
    const relatedProductCategories = yield select(getRelatedProductCategories);

    if (products.length > 0) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (selectProducts.length > 0) {
      yield put(setFetchSelectProductsPage(1));
      yield call(_getSelectProducts);
    }
    if (orderProducts.length > 0) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (relatedProductCategories.length > 0) {
      yield put(setFetchRelatedProductCategoriesPage(1));
      yield call(_getRelatedProductCategories);
    }

    yield put(setUpdateProductSuccess(message));
    yield put(setUpdateProductLoading(false));
  } catch (error) {
    yield put(setUpdateProductFailed(error));
    yield put(setUpdateProductLoading(false));
  }
}
export function* _deleteProduct({ payload: productId }) {
  try {
    yield put(setDeleteProductLoading(true));

    const {
      meta: { message },
    } = yield call(deleteProduct, productId);

    yield put(setIsDeleteProductHitted(true));

    const products = yield select(getProductsSelector);
    const selectProducts = yield select(getSelectProducts);
    const orderProducts = yield select(getOrderProducts);
    const relatedProductCategories = yield select(getRelatedProductCategories);

    if (products.length > 0) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (selectProducts.length > 0) {
      yield put(setFetchSelectProductsPage(1));
      yield call(_getSelectProducts);
    }
    if (orderProducts.length > 0) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (relatedProductCategories.length > 0) {
      yield put(setFetchRelatedProductCategoriesPage(1));
      yield call(_getRelatedProductCategories);
    }

    yield put(setDeleteProductSuccess(message));
    yield put(setDeleteProductLoading(false));
  } catch (error) {
    yield put(setDeleteProductFailed(error));
    yield put(setDeleteProductLoading(false));
  }
}
export function* _multipleCreateProducts({ payload: request }) {
  try {
    yield put(setMultipleCreateProductsLoading(true));

    const {
      meta: { message },
    } = yield call(multiCreateProducts, request);

    const products = yield select(getProductsSelector);
    const selectProducts = yield select(getSelectProducts);
    const orderProducts = yield select(getOrderProducts);
    const relatedProductCategories = yield select(getRelatedProductCategories);

    if (products.length > 0) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (selectProducts.length > 0) {
      yield put(setFetchSelectProductsPage(1));
      yield call(_getSelectProducts);
    }
    if (orderProducts.length > 0) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (relatedProductCategories.length > 0) {
      yield put(setFetchRelatedProductCategoriesPage(1));
      yield call(_getRelatedProductCategories);
    }

    yield put(setMultipleCreateProductsSuccess(message));
    yield put(setMultipleCreateProductsLoading(false));
  } catch (error) {
    yield put(setMultipleCreateProductsFailed(error));
    yield put(setMultipleCreateProductsLoading(false));
  }
}
export function* _multipleDeleteProducts({ payload: request }) {
  try {
    yield put(setMultipleDeleteProductsLoading(true));

    const {
      meta: { message },
    } = yield call(multiDeleteProducts, request);

    const products = yield select(getProductsSelector);
    const selectProducts = yield select(getSelectProducts);
    const orderProducts = yield select(getOrderProducts);
    const relatedProductCategories = yield select(getRelatedProductCategories);

    if (products.length > 0) {
      yield put(setFetchProductsPage(1));
      yield call(_getProducts);
    }
    if (selectProducts.length > 0) {
      yield put(setFetchSelectProductsPage(1));
      yield call(_getSelectProducts);
    }
    if (orderProducts.length > 0) {
      yield put(setFetchOrderProductsPage(1));
      yield call(_getOrderProducts);
    }
    if (relatedProductCategories.length > 0) {
      yield put(setFetchRelatedProductCategoriesPage(1));
      yield call(_getRelatedProductCategories);
    }

    yield put(setMultipleDeleteProductsSuccess(message));
    yield put(setMultipleDeleteProductsLoading(false));
  } catch (error) {
    yield put(setMultipleDeleteProductsFailed(error));
    yield put(setMultipleDeleteProductsLoading(false));
  }
}

export function* onFetchProductsStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_START, _getProducts);
}
export function* onFetchSelectProductsStart() {
  yield takeLatest(
    PRODUCT_ACTION_TYPES.FETCH_SELECT_PRODUCTS_START,
    _getSelectProducts
  );
}
export function* onFetchOrderProductsStart() {
  yield takeLatest(
    PRODUCT_ACTION_TYPES.FETCH_ORDER_PRODUCTS_START,
    _getOrderProducts
  );
}
export function* onAppendSelectProductsStart() {
  yield takeLatest(
    PRODUCT_ACTION_TYPES.APPEND_SELECT_PRODUCTS_START,
    _getAppendSelectProducts
  );
}
export function* onFetchProductStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.FETCH_PRODUCT_START, _getProduct);
}
export function* onCreateProductStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.CREATE_PRODUCT_START, _createProduct);
}
export function* onUpdateProductStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.UPDATE_PRODUCT_START, _updateProduct);
}
export function* onDeleteProductStart() {
  yield takeLatest(PRODUCT_ACTION_TYPES.DELETE_PRODUCT_START, _deleteProduct);
}
export function* onMultipleCreateProductsStart() {
  yield takeLatest(
    PRODUCT_ACTION_TYPES.MULTIPLE_CREATE_PRODUCTS_START,
    _multipleCreateProducts
  );
}
export function* onMultipleDeleteProductsStart() {
  yield takeLatest(
    PRODUCT_ACTION_TYPES.MULTIPLE_DELETE_PRODUCTS_START,
    _multipleDeleteProducts
  );
}

export function* productSaga() {
  yield all([
    call(onFetchProductsStart),
    call(onFetchSelectProductsStart),
    call(onFetchOrderProductsStart),
    call(onAppendSelectProductsStart),
    call(onFetchProductStart),
    call(onCreateProductStart),
    call(onUpdateProductStart),
    call(onDeleteProductStart),
    call(onMultipleCreateProductsStart),
    call(onMultipleDeleteProductsStart),
  ]);
}
