export const PERMISSION_INCLUDES = {
  MARKET: "market",
  ADMINS: "admins",
  SUBSCRIBERS: "subscribers",
};

export const PERMISSION_SUBSCRIBER_PAGES = {
  ACCOUNT: "ACCOUNT",
  ADVERTISEMENT: "ADVERTISEMENT",
  APPLICATION: "APPLICATION",
  AUTHENTICATION: "AUTHENTICATION",
  BRANCH: "BRANCH",
  CUSTOMER: "CUSTOMER",
  DASHBOARD: "DASHBOARD",
  DEVICE: "DEVICE",
  DISCOUNT: "DISCOUNT",
  DRIVER: "DRIVER",
  GENERAL_SETTINGS: "GENERAL_SETTINGS",
  INVENTORY: "INVENTORY",
  KITCHEN: "KITCHEN",
  ORDER: "ORDER",
  ORDERS_OF_PURCHASE: "ORDERS_OF_PURCHASE",
  PERMISSION: "PERMISSION",
  PRINTER: "PRINTER",
  PRODUCT: "PRODUCT",
  PROFILE: "PROFILE",
  PURCHASE: "PURCHASE",
  RATING: "RATING",
  RECIPE: "RECIPE",
  REPORT: "REPORT",
  SCHEDULE: "SCHEDULE",
  SUBSCRIPTION: "SUBSCRIPTION",
  SUPPLIER: "SUPPLIER",
  TABLE: "TABLE",
  TAX: "TAX",
  PAYMENT_TRANSFER: "PAYMENT_TRANSFER",
  PAYMENT_METHOD: "PAYMENT_METHOD",

  // INVENTORY_ITEM: "INVENTORY",
  // INVENTORY_ORDER: "ORDERS_OF_PURCHASE",
  // INVENTORY_RECIPE: "RECIPE",
  // INVENTORY_SUPPLIER: "SUPPLIER",
  // INVENTORY_PURCHASE: "PURCHASE",

  // SETTING_ACCOUNT: "ACCOUNT",
  // SETTING_ADVERTISEMENT: "ADVERTISEMENT",
  // SETTING_AUTHENTICATION: "AUTHENTICATION",
  // SETTING_BRANCH: "BRANCH",
  // SETTING_DEVICE: "DEVICE",
  // SETTING_GENERAL_SETTINGS: "GENERAL_SETTINGS",
  // SETTING_PAYMENT_METHOD: "PAYMENT_METHOD",
  // SETTING_PERMISSION: "PERMISSION",
  // SETTING_PROFILE: "PROFILE",
  // SETTING_SCHEDULE: "SCHEDULE",
  // SETTING_SUBSCRIPTION: "SUBSCRIPTION",
  // SETTING_TAX: "TAX",
};

export const PERMISSION_SUBSCRIBER_OTHERS = {
  RETURN_ORDER: "RETURN_ORDER",
  CASH_DRAWER: "CASH_DRAWER",
  VERIFY_ORDER_PURCHASE: "VERIFY_ORDER_PURCHASE",
  FAST_OFFER: "FAST_OFFER",
  SPLIT_PAYMENTS: "SPLIT_PAYMENTS",
};

export const PERMISSION_ADMIN_PAGES = {
  ACCOUNT: "ACCOUNT",
  COUNTRY: "COUNTRY",
  DASHBOARD: "DASHBOARD",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  PERMISSION: "PERMISSION",
  PRINTER_TYPE: "PRINTER_TYPE",
  PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
  REPORT: "REPORT",
  SECTOR: "SECTOR",
  APPLICATION: "APPLICATION",
  SMS: "SMS",
  SUBSCRIBER: "SUBSCRIBER",
  MERCHANT: "MERCHANT",
  SUBSCRIPTION: "SUBSCRIPTION",
  TERM_CONDITION: "TERM_CONDITION",

  // SETTING_ACCOUNT: "ACCOUNT",
  // SETTING_PERMISSION: "PERMISSION",
  // SETTING_TERM_CONDITION: "TERM_CONDITION",
};

export const PERMISISON_ADMIN_OTHERS = {};

export const getSectorPages = (sectorPages = [], userPages = []) => {
  const sectorPagesSet = new Set(sectorPages);
  const pages = userPages.filter((page) => sectorPagesSet.has(page));

  return pages;
};
