import CUSTOMER_ACTION_TYPES from "./customer.type";

export const CUSTOMER_INITIAL_STATE = {
  customers: [],
  selectCustomers: [],
  customer: null,
  foundCustomer: null,
  createCustomer: null,
  updateCustomer: null,

  isCustomersHasMore: true,
  isSelectCustomersHasMore: true,

  fetchCustomersSearch: null,
  fetchCustomersPage: 1,
  fetchCustomersPerPage: null,
  fetchCustomersIncludes: null,
  fetchCustomersFilterMarketId: null,
  fetchCustomersLoading: false,
  fetchCustomersSuccess: null,
  fetchCustomersFailed: null,

  fetchSelectCustomersSearch: null,
  fetchSelectCustomersPage: 1,
  fetchSelectCustomersPerPage: null,
  fetchSelectCustomersIncludes: null,
  fetchSelectCustomersFilterMarketId: null,
  fetchSelectCustomersLoading: false,
  fetchSelectCustomersSuccess: null,
  fetchSelectCustomersFailed: null,

  fetchCustomerLoading: false,
  fetchCustomerSuccess: null,
  fetchCustomerFailed: null,

  fetchFindCustomerLoading: false,
  fetchFindCustomerSuccess: null,
  fetchFindCustomerFailed: null,

  createCustomerLoading: false,
  createCustomerSuccess: null,
  createCustomerFailed: null,

  updateCustomerLoading: false,
  updateCustomerSuccess: null,
  updateCustomerFailed: null,

  isFetchCustomersHitted: false,
  isFetchSelectCustomersHitted: false,
  isFetchCustomerHitted: false,
  isFetchFindCustomerHitted: false,
  isCreateCustomerHitted: false,
  isUpdateCustomerHitted: false,
};

export const customerReducer = (state = CUSTOMER_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CUSTOMER_ACTION_TYPES.SET_CUSTOMERS:
      return { ...state, customers: payload };
    case CUSTOMER_ACTION_TYPES.SET_SELECT_CUSTOMERS:
      return { ...state, selectCustomers: payload };
    case CUSTOMER_ACTION_TYPES.SET_CUSTOMER:
      return { ...state, customer: payload };
    case CUSTOMER_ACTION_TYPES.SET_FOUND_CUSTOMER:
      return { ...state, foundCustomer: payload };
    case CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER:
      return { ...state, createCustomer: payload };
    case CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER:
      return { ...state, updateCustomer: payload };

    case CUSTOMER_ACTION_TYPES.SET_IS_CUSTOMERS_HAS_MORE:
      return { ...state, isCustomersHasMore: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_SELECT_CUSTOMERS_HAS_MORE:
      return { ...state, isSelectCustomersHasMore: payload };

    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SEARCH:
      return { ...state, fetchCustomersSearch: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PAGE:
      return { ...state, fetchCustomersPage: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PER_PAGE:
      return { ...state, fetchCustomersPerPage: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_INCLUDES:
      return { ...state, fetchCustomersIncludes: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FILTER_MARKET_ID:
      return { ...state, fetchCustomersFilterMarketId: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_LOADING:
      return { ...state, fetchCustomersLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SUCCESS:
      return { ...state, fetchCustomersSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FAILED:
      return { ...state, fetchCustomersFailed: payload };

    case CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_SEARCH:
      return { ...state, fetchSelectCustomersSearch: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_PAGE:
      return { ...state, fetchSelectCustomersPage: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_PER_PAGE:
      return { ...state, fetchSelectCustomersPerPage: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_INCLUDES:
      return { ...state, fetchSelectCustomersIncludes: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_FILTER_MARKET_ID:
      return { ...state, fetchSelectCustomersFilterMarketId: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_LOADING:
      return { ...state, fetchSelectCustomersLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_SUCCESS:
      return { ...state, fetchSelectCustomersSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_FAILED:
      return { ...state, fetchSelectCustomersFailed: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_LOADING:
      return { ...state, fetchCustomerLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_SUCCESS:
      return { ...state, fetchCustomerSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_FAILED:
      return { ...state, fetchCustomerFailed: payload };

    case CUSTOMER_ACTION_TYPES.SET_FETCH_FIND_CUSTOMER_LOADING:
      return { ...state, fetchFindCustomerLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_FIND_CUSTOMER_SUCCESS:
      return { ...state, fetchFindCustomerSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_FETCH_FIND_CUSTOMER_FAILED:
      return { ...state, fetchFindCustomerFailed: payload };

    case CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_LOADING:
      return { ...state, createCustomerLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_SUCCESS:
      return { ...state, createCustomerSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_FAILED:
      return { ...state, createCustomerFailed: payload };

    case CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_LOADING:
      return { ...state, updateCustomerLoading: payload };
    case CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_SUCCESS:
      return { ...state, updateCustomerSuccess: payload };
    case CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_FAILED:
      return { ...state, updateCustomerFailed: payload };

    case CUSTOMER_ACTION_TYPES.APPEND_CUSTOMERS:
      return { ...state, customers: [...state.customers, ...payload] };
    case CUSTOMER_ACTION_TYPES.APPEND_SELECT_CUSTOMERS:
      return { ...state, selectCustomers: [...state.selectCustomers, ...payload] };

    case CUSTOMER_ACTION_TYPES.SET_IS_FETCH_CUSTOMERS_HITTED:
      return { ...state, isFetchCustomersHitted: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_FETCH_SELECT_CUSTOMERS_HITTED:
      return { ...state, isFetchSelectCustomersHitted: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_FETCH_CUSTOMER_HITTED:
      return { ...state, isFetchCustomerHitted: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_FETCH_FIND_CUSTOMER_HITTED:
      return { ...state, isFetchFindCustomerHitted: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_CREATE_CUSTOMER_HITTED:
      return { ...state, isCreateCustomerHitted: payload };
    case CUSTOMER_ACTION_TYPES.SET_IS_UPDATE_CUSTOMER_HITTED:
      return { ...state, isUpdateCustomerHitted: payload };

    case CUSTOMER_ACTION_TYPES.RESET_CUSTOMER_REDUCER:
      return CUSTOMER_INITIAL_STATE;
    default:
      return state;
  }
};
