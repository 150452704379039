import apiService from "./api";

export const getReportTotals = async (parameters) =>
  (await apiService.get("/v2/reports/totals", parameters)).data;
export const getReportCategories = async (parameters) =>
  (await apiService.get("/v2/reports/categories", parameters)).data;
export const getReportProducts = async (parameters) =>
  (await apiService.get("/v2/reports/products", parameters)).data;
export const getReportCashiers = async (parameters) =>
  (await apiService.get("/v2/reports/cashiers", parameters)).data;
export const getReportPayments = async (parameters) =>
  (await apiService.get("/v2/reports/payments", parameters)).data;
