const EXTEND_PERIOD_ACTION_TYPES = {
  RESET_EXTEND_PERIOD_REDUCER: "extend-period/RESET_EXTEND_PERIOD_REDUCER",

  SET_EXTEND_PERIODS: "extend-period/SET_EXTEND_PERIODS",
  SET_EXTEND_PERIOD: "extend-period/SET_EXTEND_PERIOD",
  SET_CREATE_EXTEND_PERIOD: "extend-period/SET_CREATE_EXTEND_PERIOD",
  SET_CUSTOM_EXTEND_PERIOD: "extend-period/SET_CUSTOM_EXTEND_PERIOD",

  SET_IS_EXTEND_PERIODS_HAS_MORE:
    "extend-period/SET_IS_EXTEND_PERIODS_HAS_MORE",

  SET_FETCH_EXTEND_PERIODS_SEARCH:
    "extend-period/SET_FETCH_EXTEND_PERIODS_SEARCH",
  SET_FETCH_EXTEND_PERIODS_SORT: "extend-period/SET_FETCH_EXTEND_PERIODS_SORT",
  SET_FETCH_EXTEND_PERIODS_KEY_BY:
    "extend-period/SET_FETCH_EXTEND_PERIODS_KEY_BY",
  SET_FETCH_EXTEND_PERIODS_PAGE: "extend-period/SET_FETCH_EXTEND_PERIODS_PAGE",
  SET_FETCH_EXTEND_PERIODS_PER_PAGE:
    "extend-period/SET_FETCH_EXTEND_PERIODS_PER_PAGE",
  SET_FETCH_EXTEND_PERIODS_INCLUDES:
    "extend-period/SET_FETCH_EXTEND_PERIODS_INCLUDES",
  SET_FETCH_EXTEND_PERIODS_FILTER_MARKET_ID:
    "extend-period/SET_FETCH_EXTEND_PERIODS_FILTER_MARKET_ID",
  SET_FETCH_EXTEND_PERIODS_FILTER_BRANCH_ID:
    "extend-period/SET_FETCH_EXTEND_PERIODS_FILTER_BRANCH_ID",
  SET_FETCH_EXTEND_PERIODS_FILTER_STATUS:
    "extend-period/SET_FETCH_EXTEND_PERIODS_FILTER_STATUS",
  SET_FETCH_EXTEND_PERIODS_FILTER_START_AT_VALUE:
    "extend-period/SET_FETCH_EXTEND_PERIODS_FILTER_START_AT_VALUE",
  SET_FETCH_EXTEND_PERIODS_FILTER_START_AT_OPERATOR:
    "extend-period/SET_FETCH_EXTEND_PERIODS_FILTER_START_AT_OPERATOR",
  SET_FETCH_EXTEND_PERIODS_FILTER_END_AT_VALUE:
    "extend-period/SET_FETCH_EXTEND_PERIODS_FILTER_END_AT_VALUE",
  SET_FETCH_EXTEND_PERIODS_FILTER_END_AT_OPERATOR:
    "extend-period/SET_FETCH_EXTEND_PERIODS_FILTER_END_AT_OPERATOR",
  SET_FETCH_EXTEND_PERIODS_FILTER_CREATED_BY:
    "extend-period/SET_FETCH_EXTEND_PERIODS_FILTER_CREATED_BY",
  SET_FETCH_EXTEND_PERIODS_LOADING:
    "extend-period/SET_FETCH_EXTEND_PERIODS_LOADING",
  SET_FETCH_EXTEND_PERIODS_SUCCESS:
    "extend-period/SET_FETCH_EXTEND_PERIODS_SUCCESS",
  SET_FETCH_EXTEND_PERIODS_FAILED:
    "extend-period/SET_FETCH_EXTEND_PERIODS_FAILED",

  SET_FETCH_EXTEND_PERIOD_LOADING:
    "extend-period/SET_FETCH_EXTEND_PERIOD_LOADING",
  SET_FETCH_EXTEND_PERIOD_SUCCESS:
    "extend-period/SET_FETCH_EXTEND_PERIOD_SUCCESS",
  SET_FETCH_EXTEND_PERIOD_FAILED:
    "extend-period/SET_FETCH_EXTEND_PERIOD_FAILED",

  SET_CREATE_EXTEND_PERIOD_LOADING:
    "extend-period/SET_CREATE_EXTEND_PERIOD_LOADING",
  SET_CREATE_EXTEND_PERIOD_SUCCESS:
    "extend-period/SET_CREATE_EXTEND_PERIOD_SUCCESS",
  SET_CREATE_EXTEND_PERIOD_FAILED:
    "extend-period/SET_CREATE_EXTEND_PERIOD_FAILED",

  SET_CUSTOM_EXTEND_PERIOD_LOADING:
    "extend-period/SET_CUSTOM_EXTEND_PERIOD_LOADING",
  SET_CUSTOM_EXTEND_PERIOD_SUCCESS:
    "extend-period/SET_CUSTOM_EXTEND_PERIOD_SUCCESS",
  SET_CUSTOM_EXTEND_PERIOD_FAILED:
    "extend-period/SET_CUSTOM_EXTEND_PERIOD_FAILED",

  SET_STOP_EXTEND_PERIOD_LOADING:
    "extend-period/SET_STOP_EXTEND_PERIOD_LOADING",
  SET_STOP_EXTEND_PERIOD_SUCCESS:
    "extend-period/SET_STOP_EXTEND_PERIOD_SUCCESS",
  SET_STOP_EXTEND_PERIOD_FAILED: "extend-period/SET_STOP_EXTEND_PERIOD_FAILED",

  APPEND_EXTEND_PERIODS: "extend-period/APPEND_EXTEND_PERIODS",

  SET_IS_FETCH_EXTEND_PERIODS_HITTED:
    "extend-period/SET_IS_FETCH_EXTEND_PERIODS_HITTED",
  SET_IS_FETCH_EXTEND_PERIOD_HITTED:
    "extend-period/SET_IS_FETCH_EXTEND_PERIOD_HITTED",
  SET_IS_CREATE_EXTEND_PERIOD_HITTED:
    "extend-period/SET_IS_CREATE_EXTEND_PERIOD_HITTED",
  SET_IS_CUSTOM_EXTEND_PERIOD_HITTED:
    "extend-period/SET_IS_CUSTOM_EXTEND_PERIOD_HITTED",
  SET_IS_STOP_EXTEND_PERIOD_HITTED:
    "extend-period/SET_IS_STOP_EXTEND_PERIOD_HITTED",

  FETCH_EXTEND_PERIODS_START: "extend-period/FETCH_EXTEND_PERIODS_START",
  FETCH_EXTEND_PERIOD_START: "extend-period/FETCH_EXTEND_PERIOD_START",
  CREATE_EXTEND_PERIOD_START: "extend-period/CREATE_EXTEND_PERIOD_START",
  CUSTOM_EXTEND_PERIOD_START: "extend-period/CUSTOM_EXTEND_PERIOD_START",
  STOP_EXTEND_PERIOD_START: "extend-period/STOP_EXTEND_PERIOD_START",
};

export default EXTEND_PERIOD_ACTION_TYPES;
