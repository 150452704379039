const GLOBAL_ACTION_TYPES = {
  RESET_GLOBAL_REDUCER: "global/RESET_GLOBAL_REDUCER",

  SET_IS_RIGHT_TO_LEFT: "global/SET_IS_RIGHT_TO_LEFT",
  SET_IS_GLOBAL_LOADING: "global/SET_IS_GLOBAL_LOADING",

  SET_AUTHORIZE_PAGES: "global/SET_AUTHORIZE_PAGES",
  SET_IS_MARKET_ADMINISTRATOR: "global/SET_IS_MARKET_ADMINISTRATOR",
  SET_IS_CENTER_ADMINISTRATOR: "global/SET_IS_CENTER_ADMINISTRATOR",

  SET_CURRENT_MARKET: "global/SET_CURRENT_MARKET",
  SET_CURRENT_BRANCH: "global/SET_CURRENT_BRANCH",
  SET_CURRENT_MERCHANT: "global/SET_CURRENT_MERCHANT",
  SET_CURRENT_PERMISSION: "global/SET_CURRENT_PERMISSION",
  SET_CURRENT_SCHEDULES: "global/SET_CURRENT_SCHEDULES",
  SET_CURRENT_EXTEND_PERIODS: "global/SET_CURRENT_EXTEND_PERIODS",

  SET_CURRENT_MARKET_ID: "global/SET_CURRENT_MARKET_ID",
  SET_CURRENT_BRANCH_ID: "global/SET_CURRENT_BRANCH_ID",
  SET_CURRENT_MERCHANT_ID: "global/SET_CURRENT_MERCHANT_ID",
  SET_CURRENT_PERMISSION_ID: "global/SET_CURRENT_PERMISSION_ID",
  SET_IS_CURRENT_KITCHEN_ACTIVE: "global/SET_IS_CURRENT_KITCHEN_ACTIVE",

  SET_TODAY_SCHEDULE: "global/SET_TODAY_SCHEDULE",
  SET_IS_TODAY_IN_SCHEDULE: "global/SET_IS_TODAY_IN_SCHEDULE",

  SET_TODAY_ATTENDANCE: "global/SET_TODAY_ATTENDANCE",
  SET_IS_TODAY_HAS_ATTENDANCE: "global/SET_IS_TODAY_HAS_ATTENDANCE",

  SET_ACTIVE_SUBSCRIPTION: "global/SET_ACTIVE_SUBSCRIPTION",
  SET_IS_SUBSCRIPTION: "global/SET_IS_SUBSCRIPTION",
  SET_IS_ADVANCED_SUBSCRIPTION: "global/SET_IS_ADVANCED_SUBSCRIPTION",
  SET_IS_HAS_SUBSCRIPTION: "global/SET_IS_HAS_SUBSCRIPTION",
  SET_IS_HAS_ADVANCED_SUBSCRIPTION: "global/SET_IS_HAS_ADVANCED_SUBSCRIPTION",

  SET_FETCH_CURRENT_MARKET_LOADING: "global/SET_FETCH_CURRENT_MARKET_LOADING",
  SET_FETCH_CURRENT_MARKET_SUCCESS: "global/SET_FETCH_CURRENT_MARKET_SUCCESS",
  SET_FETCH_CURRENT_MARKET_FAILED: "global/SET_FETCH_CURRENT_MARKET_FAILED",

  SET_FETCH_CURRENT_BRANCH_LOADING: "global/SET_FETCH_CURRENT_BRANCH_LOADING",
  SET_FETCH_CURRENT_BRANCH_SUCCESS: "global/SET_FETCH_CURRENT_BRANCH_SUCCESS",
  SET_FETCH_CURRENT_BRANCH_FAILED: "global/SET_FETCH_CURRENT_BRANCH_FAILED",

  SET_FETCH_CURRENT_MERCHANT_LOADING:
    "global/SET_FETCH_CURRENT_MERCHANT_LOADING",
  SET_FETCH_CURRENT_MERCHANT_SUCCESS:
    "global/SET_FETCH_CURRENT_MERCHANT_SUCCESS",
  SET_FETCH_CURRENT_MERCHANT_FAILED: "global/SET_FETCH_CURRENT_MERCHANT_FAILED",

  SET_FETCH_CURRENT_PERMISSION_LOADING:
    "global/SET_FETCH_CURRENT_PERMISSION_LOADING",
  SET_FETCH_CURRENT_PERMISSION_SUCCESS:
    "global/SET_FETCH_CURRENT_PERMISSION_SUCCESS",
  SET_FETCH_CURRENT_PERMISSION_FAILED:
    "global/SET_FETCH_CURRENT_PERMISSION_FAILED",

  SET_FETCH_CURRENT_SCHEDULES_INCLUDES:
    "global/SET_FETCH_CURRENT_SCHEDULES_INCLUDES",
  SET_FETCH_CURRENT_SCHEDULES_FILTER_MARKET_ID:
    "global/SET_FETCH_CURRENT_SCHEDULES_FILTER_MARKET_ID",
  SET_FETCH_CURRENT_SCHEDULES_FILTER_BRANCH_ID:
    "global/SET_FETCH_CURRENT_SCHEDULES_FILTER_BRANCH_ID",
  SET_FETCH_CURRENT_SCHEDULES_LOADING:
    "global/SET_FETCH_CURRENT_SCHEDULES_LOADING",
  SET_FETCH_CURRENT_SCHEDULES_SUCCESS:
    "global/SET_FETCH_CURRENT_SCHEDULES_SUCCESS",
  SET_FETCH_CURRENT_SCHEDULES_FAILED:
    "global/SET_FETCH_CURRENT_SCHEDULES_FAILED",

  SET_FETCH_CURRENT_EXTEND_PERIODS_SEARCH:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_SEARCH",
  SET_FETCH_CURRENT_EXTEND_PERIODS_PAGE:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_PAGE",
  SET_FETCH_CURRENT_EXTEND_PERIODS_PER_PAGE:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_PER_PAGE",
  SET_FETCH_CURRENT_EXTEND_PERIODS_KEY_BY:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_KEY_BY",
  SET_FETCH_CURRENT_EXTEND_PERIODS_INCLUDES:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_INCLUDES",
  SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_MARKET_ID:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_MARKET_ID",
  SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_BRANCH_ID:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_BRANCH_ID",
  SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_STATUS:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_STATUS",
  SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_START_AT_BEFORE:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_START_AT_BEFORE",
  SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_START_AT_AFTER:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_START_AT_AFTER",
  SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_END_AT_BEFORE:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_END_AT_BEFORE",
  SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_END_AT_AFTER:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_END_AT_AFTER",
  SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_CREATED_BY:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_CREATED_BY",
  SET_FETCH_CURRENT_EXTEND_PERIODS_LOADING:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_LOADING",
  SET_FETCH_CURRENT_EXTEND_PERIODS_SUCCESS:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_SUCCESS",
  SET_FETCH_CURRENT_EXTEND_PERIODS_FAILED:
    "global/SET_FETCH_CURRENT_EXTEND_PERIODS_FAILED",

  SET_FETCH_TODAY_ATTENDANCE_MARKET_ID:
    "global/SET_FETCH_TODAY_ATTENDANCE_MARKET_ID",
  SET_FETCH_TODAY_ATTENDANCE_BRANCH_ID:
    "global/SET_FETCH_TODAY_ATTENDANCE_BRANCH_ID",
  SET_FETCH_TODAY_ATTENDANCE_LOADING:
    "global/SET_FETCH_TODAY_ATTENDANCE_LOADING",
  SET_FETCH_TODAY_ATTENDANCE_SUCCESS:
    "global/SET_FETCH_TODAY_ATTENDANCE_SUCCESS",
  SET_FETCH_TODAY_ATTENDANCE_FAILED: "global/SET_FETCH_TODAY_ATTENDANCE_FAILED",

  SET_IS_FETCH_CURRENT_MARKET_HITTED:
    "global/SET_IS_FETCH_CURRENT_MARKET_HITTED",
  SET_IS_FETCH_CURRENT_BRANCH_HITTED:
    "global/SET_IS_FETCH_CURRENT_BRANCH_HITTED",
  SET_IS_FETCH_CURRENT_MERCHANT_HITTED:
    "global/SET_IS_FETCH_CURRENT_MERCHANT_HITTED",
  SET_IS_FETCH_CURRENT_PERMISSION_HITTED:
    "global/SET_IS_FETCH_CURRENT_PERMISSION_HITTED",
  SET_IS_FETCH_CURRENT_SCHEDULES_HITTED:
    "global/SET_IS_FETCH_CURRENT_SCHEDULES_HITTED",
  SET_IS_FETCH_CURRENT_EXTEND_PERIODS_HITTED:
    "global/SET_IS_FETCH_CURRENT_EXTEND_PERIODS_HITTED",
  SET_IS_FETCH_TODAY_ATTENDANCE_HITTED:
    "global/SET_IS_FETCH_TODAY_ATTENDANCE_HITTED",

  FETCH_CURRENT_MARKET_START: "global/FETCH_CURRENT_MARKET_START",
  FETCH_CURRENT_BRANCH_START: "global/FETCH_CURRENT_BRANCH_START",
  FETCH_CURRENT_MERCHANT_START: "global/FETCH_CURRENT_MERCHANT_START",
  FETCH_CURRENT_PERMISSION_START: "global/FETCH_CURRENT_PERMISSION_START",
  FETCH_CURRENT_SCHEDULES_START: "global/FETCH_CURRENT_SCHEDULES_START",
  FETCH_CURRENT_EXTEND_PERIODS_START:
    "global/FETCH_CURRENT_EXTEND_PERIODS_START",
  FETCH_TODAY_ATTENDANCE_START: "global/FETCH_TODAY_ATTENDANCE_START",
};

export default GLOBAL_ACTION_TYPES;
