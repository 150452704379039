const EXPORT_PDF_ACTION_TYPES = {
  RESET_EXPORT_PDF_REDUCER: "export-pdf/RESET_EXPORT_PDF_REDUCER",

  SET_EXPORT_PDF_ORDER_INVOICE: "export-pdf/SET_EXPORT_PDF_ORDER_INVOICE",
  SET_EXPORT_PDF_ORDER_KITCHEN: "export-pdf/SET_EXPORT_PDF_ORDER_KITCHEN",

  SET_EXPORT_PDF_ORDER_INVOICE_LOADING:
    "export-pdf/SET_EXPORT_PDF_ORDER_INVOICE_LOADING",
  SET_EXPORT_PDF_ORDER_INVOICE_SUCCESS:
    "export-pdf/SET_EXPORT_PDF_ORDER_INVOICE_SUCCESS",
  SET_EXPORT_PDF_ORDER_INVOICE_FAILED:
    "export-pdf/SET_EXPORT_PDF_ORDER_INVOICE_FAILED",

  SET_EXPORT_PDF_ORDER_KITCHEN_LOADING:
    "export-pdf/SET_EXPORT_PDF_ORDER_KITCHEN_LOADING",
  SET_EXPORT_PDF_ORDER_KITCHEN_SUCCESS:
    "export-pdf/SET_EXPORT_PDF_ORDER_KITCHEN_SUCCESS",
  SET_EXPORT_PDF_ORDER_KITCHEN_FAILED:
    "export-pdf/SET_EXPORT_PDF_ORDER_KITCHEN_FAILED",

  SET_IS_EXPORT_PDF_ORDER_INVOICE_HITTED:
    "export-pdf/SET_IS_EXPORT_PDF_ORDER_INVOICE_HITTED",
  SET_IS_EXPORT_PDF_ORDER_KITCHEN_HITTED:
    "export-pdf/SET_IS_EXPORT_PDF_ORDER_KITCHEN_HITTED",

  EXPORT_PDF_ORDER_INVOICE_START: "export-pdf/EXPORT_PDF_ORDER_INVOICE_START",
  EXPORT_PDF_ORDER_KITCHEN_START: "export-pdf/EXPORT_PDF_ORDER_KITCHEN_START",
};

export default EXPORT_PDF_ACTION_TYPES;
