import apiService from "./api";

export const getSubscriptions = async (parameters) => (await apiService.get("/v2/subscriptions", parameters)).data;

export const getSubscription = async (id) => (await apiService.get(`/v2/subscriptions/${id}`)).data;

export const createSubscription = async (request) => (await apiService.post("/v2/subscriptions", request)).data;

export const updateSubscription = async (id, request) =>
  (await apiService.post(`/v2/subscriptions/${id}`, request)).data;

export const deleteSubscription = async (id) => (await apiService.delete(`/v2/subscriptions/${id}`)).data;
