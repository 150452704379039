import GLOBAL_ACTION_TYPES from "./global.type";

export const GLOBAL_INITIAL_STATE = {
  isRightToLeft: false,
  isGlobalLoading: false,

  authorizePages: [],
  isMarketAdministrator: false,
  isCenterAdministrator: false,

  currentMarket: null,
  currentBranch: null,
  currentMerchant: null,
  currentPermission: null,
  currentSchedules: null,
  currentExtendPeriods: [],

  currentMarketId: null,
  currentBranchId: null,
  currentMerchantId: null,
  currentPermissionId: null,
  isCurrentKitchenActive: false,

  todaySchedule: null,
  isTodayInSchedule: false,

  todayAttendance: null,
  isTodayHasAttendance: false,

  activeSubscription: null,
  isSubscription: false,
  isAdvancedSubscription: false,
  isHasSubscription: false,
  isHasAdvancedSubscription: false,

  fetchCurrentMarketLoading: false,
  fetchCurrentMarketSuccess: null,
  fetchCurrentMarketFailed: null,

  fetchCurrentBranchLoading: false,
  fetchCurrentBranchSuccess: null,
  fetchCurrentBranchFailed: null,

  fetchCurrentMerchantLoading: false,
  fetchCurrentMerchantSuccess: null,
  fetchCurrentMerchantFailed: null,

  fetchCurrentPermissionLoading: false,
  fetchCurrentPermissionSuccess: null,
  fetchCurrentPermissionFailed: null,

  fetchCurrentSchedulesIncludes: null,
  fetchCurrentSchedulesFilterMarketId: null,
  fetchCurrentSchedulesFilterBranchId: null,
  fetchCurrentSchedulesLoading: false,
  fetchCurrentSchedulesSuccess: null,
  fetchCurrentSchedulesFailed: null,

  fetchCurrentExtendPeriodsSearch: null,
  fetchCurrentExtendPeriodsPage: 1,
  fetchCurrentExtendPeriodsPerPage: null,
  fetchCurrentExtendPeriodsKeyBy: null,
  fetchCurrentExtendPeriodsIncludes: null,
  fetchCurrentExtendPeriodsFilterMarketId: null,
  fetchCurrentExtendPeriodsFilterBranchId: null,
  fetchCurrentExtendPeriodsFilterStatus: null,
  fetchCurrentExtendPeriodsFilterStartAtBefore: null,
  fetchCurrentExtendPeriodsFilterStartAtAfter: null,
  fetchCurrentExtendPeriodsFilterEndAtBefore: null,
  fetchCurrentExtendPeriodsFilterEndAtAfter: null,
  fetchCurrentExtendPeriodsFilterCreatedBy: null,
  fetchCurrentExtendPeriodsLoading: false,
  fetchCurrentExtendPeriodsSuccess: null,
  fetchCurrentExtendPeriodsFailed: null,

  fetchTodayAttendanceMarketId: null,
  fetchTodayAttendanceBranchId: null,
  fetchTodayAttendanceLoading: false,
  fetchTodayAttendanceSuccess: null,
  fetchTodayAttendanceFailed: null,

  isFetchCurrentMarketHitted: false,
  isFetchCurrentBranchHitted: false,
  isFetchCurrentMerchantHitted: false,
  isFetchCurrentPermissionHitted: false,
  isFetchCurrentSchedulesHitted: false,
  isFetchCurrentExtendPeriodsHitted: false,
  isFetchTodayAttendanceHitted: false,
};

export const globalReducer = (state = GLOBAL_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBAL_ACTION_TYPES.SET_IS_RIGHT_TO_LEFT:
      return { ...state, isRightToLeft: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_LOADING:
      return { ...state, isGlobalLoading: payload };

    case GLOBAL_ACTION_TYPES.SET_AUTHORIZE_PAGES:
      return { ...state, authorizePages: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_MARKET_ADMINISTRATOR:
      return { ...state, isMarketAdministrator: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_CENTER_ADMINISTRATOR:
      return { ...state, isCenterAdministrator: payload };

    case GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET:
      return { ...state, currentMarket: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH:
      return { ...state, currentBranch: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT:
      return { ...state, currentMerchant: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_PERMISSION:
      return { ...state, currentPermission: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_SCHEDULES:
      return { ...state, currentSchedules: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_EXTEND_PERIODS:
      return { ...state, currentExtendPeriods: payload };

    case GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET_ID:
      return { ...state, currentMarketId: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH_ID:
      return { ...state, currentBranchId: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT_ID:
      return { ...state, currentMerchantId: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_PERMISSION_ID:
      return { ...state, currentPermissionId: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_CURRENT_KITCHEN_ACTIVE:
      return { ...state, isCurrentKitchenActive: payload };

    case GLOBAL_ACTION_TYPES.SET_TODAY_SCHEDULE:
      return { ...state, todaySchedule: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_TODAY_IN_SCHEDULE:
      return { ...state, isTodayInSchedule: payload };

    case GLOBAL_ACTION_TYPES.SET_TODAY_ATTENDANCE:
      return { ...state, todayAttendance: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_TODAY_HAS_ATTENDANCE:
      return { ...state, isTodayHasAttendance: payload };

    case GLOBAL_ACTION_TYPES.SET_ACTIVE_SUBSCRIPTION:
      return { ...state, activeSubscription: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_SUBSCRIPTION:
      return { ...state, isSubscription: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_ADVANCED_SUBSCRIPTION:
      return { ...state, isAdvancedSubscription: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_HAS_SUBSCRIPTION:
      return { ...state, isHasSubscription: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_HAS_ADVANCED_SUBSCRIPTION:
      return { ...state, isHasAdvancedSubscription: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_LOADING:
      return { ...state, fetchCurrentMarketLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_SUCCESS:
      return { ...state, fetchCurrentMarketSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_FAILED:
      return { ...state, fetchCurrentMarketFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_LOADING:
      return { ...state, fetchCurrentBranchLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_SUCCESS:
      return { ...state, fetchCurrentBranchSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_FAILED:
      return { ...state, fetchCurrentBranchFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_LOADING:
      return { ...state, fetchCurrentMerchantLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_SUCCESS:
      return { ...state, fetchCurrentMerchantSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_FAILED:
      return { ...state, fetchCurrentMerchantFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_LOADING:
      return { ...state, fetchCurrentPermissionLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_SUCCESS:
      return { ...state, fetchCurrentPermissionSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_FAILED:
      return { ...state, fetchCurrentPermissionFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_INCLUDES:
      return { ...state, fetchCurrentSchedulesIncludes: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_MARKET_ID:
      return { ...state, fetchCurrentSchedulesFilterMarketId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_BRANCH_ID:
      return { ...state, fetchCurrentSchedulesFilterBranchId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_LOADING:
      return { ...state, fetchCurrentSchedulesLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_SUCCESS:
      return { ...state, fetchCurrentSchedulesSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FAILED:
      return { ...state, fetchCurrentSchedulesFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_SEARCH:
      return { ...state, fetchCurrentExtendPeriodsSearch: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_PAGE:
      return { ...state, fetchCurrentExtendPeriodsPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_PER_PAGE:
      return { ...state, fetchCurrentExtendPeriodsPerPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_KEY_BY:
      return { ...state, fetchCurrentExtendPeriodsKeyBy: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_INCLUDES:
      return { ...state, fetchCurrentExtendPeriodsIncludes: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_MARKET_ID:
      return { ...state, fetchCurrentExtendPeriodsFilterMarketId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_BRANCH_ID:
      return { ...state, fetchCurrentExtendPeriodsFilterBranchId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_STATUS:
      return { ...state, fetchCurrentExtendPeriodsFilterStatus: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_START_AT_BEFORE:
      return {
        ...state,
        fetchCurrentExtendPeriodsFilterStartAtBefore: payload,
      };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_START_AT_AFTER:
      return { ...state, fetchCurrentExtendPeriodsFilterStartAtAfter: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_END_AT_BEFORE:
      return { ...state, fetchCurrentExtendPeriodsFilterEndAtBefore: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_END_AT_AFTER:
      return { ...state, fetchCurrentExtendPeriodsFilterEndAtAfter: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_CREATED_BY:
      return { ...state, fetchCurrentExtendPeriodsFilterCreatedBy: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_LOADING:
      return { ...state, fetchCurrentExtendPeriodsLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_SUCCESS:
      return { ...state, fetchCurrentExtendPeriodsSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FAILED:
      return { ...state, fetchCurrentExtendPeriodsFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_MARKET_ID:
      return { ...state, fetchTodayAttendanceMarketId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_BRANCH_ID:
      return { ...state, fetchTodayAttendanceBranchId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_LOADING:
      return { ...state, fetchTodayAttendanceLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_SUCCESS:
      return { ...state, fetchTodayAttendanceSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_FAILED:
      return { ...state, fetchTodayAttendanceFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MARKET_HITTED:
      return { ...state, isFetchCurrentMarketHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_BRANCH_HITTED:
      return { ...state, isFetchCurrentBranchHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MERCHANT_HITTED:
      return { ...state, isFetchCurrentMerchantHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_PERMISSION_HITTED:
      return { ...state, isFetchCurrentPermissionHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_SCHEDULES_HITTED:
      return { ...state, isFetchCurrentSchedulesHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_EXTEND_PERIODS_HITTED:
      return { ...state, isFetchCurrentExtendPeriodsHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_TODAY_ATTENDANCE_HITTED:
      return { ...state, isFetchTodayAttendanceHitted: payload };

    case GLOBAL_ACTION_TYPES.RESET_GLOBAL_REDUCER:
      return GLOBAL_INITIAL_STATE;
    default:
      return state;
  }
};
