import RESTOCK_REQUEST_ACTION_TYPES from "./restock-request.type";

export const RESTOCK_REQUEST_INITIAL_STATE = {
  restockRequests: [],
  restockRequest: null,

  isRestockRequestsHasMore: true,

  fetchRestockRequestsSearch: null,
  fetchRestockRequestsPage: 1,
  fetchRestockRequestsPerPage: null,
  fetchRestockRequestsIncludes: null,
  fetchRestockRequestsFilterMarketId: null,
  fetchRestockRequestsFilterBranchId: null,
  fetchRestockRequestsFilterStatuses: null,
  fetchRestockRequestsLoading: false,
  fetchRestockRequestsSuccess: null,
  fetchRestockRequestsFailed: null,

  fetchRestockRequestLoading: false,
  fetchRestockRequestSuccess: null,
  fetchRestockRequestFailed: null,

  createRestockRequestLoading: false,
  createRestockRequestSuccess: null,
  createRestockRequestFailed: null,

  updateRestockRequestLoading: false,
  updateRestockRequestSuccess: null,
  updateRestockRequestFailed: null,

  deleteRestockRequestLoading: false,
  deleteRestockRequestSuccess: null,
  deleteRestockRequestFailed: null,
};

export const restockRequestReducer = (state = RESTOCK_REQUEST_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESTOCK_REQUEST_ACTION_TYPES.SET_RESTOCK_REQUESTS:
      return { ...state, restockRequests: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_RESTOCK_REQUEST:
      return { ...state, restockRequest: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_IS_RESTOCK_REQUESTS_HAS_MORE:
      return { ...state, isRestockRequestsHasMore: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_SEARCH:
      return { ...state, fetchRestockRequestsSearch: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_PAGE:
      return { ...state, fetchRestockRequestsPage: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_PER_PAGE:
      return { ...state, fetchRestockRequestsPerPage: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_INCLUDES:
      return { ...state, fetchRestockRequestsIncludes: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_FILTER_MARKET_ID:
      return { ...state, fetchRestockRequestsFilterMarketId: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_FILTER_BRANCH_ID:
      return { ...state, fetchRestockRequestsFilterBranchId: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_FILTER_STATUSES:
      return { ...state, fetchRestockRequestsFilterStatuses: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_LOADING:
      return { ...state, fetchRestockRequestsLoading: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_SUCCESS:
      return { ...state, fetchRestockRequestsSuccess: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUESTS_FAILED:
      return { ...state, fetchRestockRequestsFailed: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUEST_LOADING:
      return { ...state, fetchRestockRequestLoading: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUEST_SUCCESS:
      return { ...state, fetchRestockRequestSuccess: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_FETCH_RESTOCK_REQUEST_FAILED:
      return { ...state, fetchRestockRequestFailed: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_CREATE_RESTOCK_REQUEST_LOADING:
      return { ...state, createRestockRequestLoading: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_CREATE_RESTOCK_REQUEST_SUCCESS:
      return { ...state, createRestockRequestSuccess: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_CREATE_RESTOCK_REQUEST_FAILED:
      return { ...state, createRestockRequestFailed: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_UPDATE_RESTOCK_REQUEST_LOADING:
      return { ...state, updateRestockRequestLoading: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_UPDATE_RESTOCK_REQUEST_SUCCESS:
      return { ...state, updateRestockRequestSuccess: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_UPDATE_RESTOCK_REQUEST_FAILED:
      return { ...state, updateRestockRequestFailed: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_DELETE_RESTOCK_REQUEST_LOADING:
      return { ...state, deleteRestockRequestLoading: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_DELETE_RESTOCK_REQUEST_SUCCESS:
      return { ...state, deleteRestockRequestSuccess: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.SET_DELETE_RESTOCK_REQUEST_FAILED:
      return { ...state, deleteRestockRequestFailed: payload };
    case RESTOCK_REQUEST_ACTION_TYPES.APPEND_RESTOCK_REQUESTS:
      return { ...state, restockRequests: [...state.restockRequests, ...payload] };
    case RESTOCK_REQUEST_ACTION_TYPES.RESET_RESTOCK_REQUEST_REDUCER:
      return RESTOCK_REQUEST_INITIAL_STATE;
    default:
      return state;
  }
};
