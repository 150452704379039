import { createSelector } from "reselect";

const userCustomerSelector = ({ userCustomer }) => userCustomer;

export const getUserCustomers = createSelector(
  [userCustomerSelector],
  ({ userCustomers }) => userCustomers
);
export const getUserCustomer = createSelector(
  [userCustomerSelector],
  ({ userCustomer }) => userCustomer
);

export const getIsUserCustomersHasMore = createSelector(
  [userCustomerSelector],
  ({ isUserCustomersHasMore }) => isUserCustomersHasMore
);

export const getFetchUserCustomersSearch = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersSearch }) => fetchUserCustomersSearch
);
export const getFetchUserCustomersSort = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersSort }) => fetchUserCustomersSort
);
export const getFetchUserCustomersKeyBy = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersKeyBy }) => fetchUserCustomersKeyBy
);
export const getFetchUserCustomersPage = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersPage }) => fetchUserCustomersPage
);
export const getFetchUserCustomersPerPage = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersPerPage }) => fetchUserCustomersPerPage
);
export const getFetchUserCustomersIncludes = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersIncludes }) => fetchUserCustomersIncludes
);
export const getFetchUserCustomersFilterMarketId = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersFilterMarketId }) => fetchUserCustomersFilterMarketId
);
export const getFetchUserCustomersLoading = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersLoading }) => fetchUserCustomersLoading
);
export const getFetchUserCustomersSuccess = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersSuccess }) => fetchUserCustomersSuccess
);
export const getFetchUserCustomersFailed = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomersFailed }) => fetchUserCustomersFailed
);

export const getFetchUserCustomerLoading = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomerLoading }) => fetchUserCustomerLoading
);
export const getFetchUserCustomerSuccess = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomerSuccess }) => fetchUserCustomerSuccess
);
export const getFetchUserCustomerFailed = createSelector(
  [userCustomerSelector],
  ({ fetchUserCustomerFailed }) => fetchUserCustomerFailed
);

export const getCreateUserCustomerLoading = createSelector(
  [userCustomerSelector],
  ({ createUserCustomerLoading }) => createUserCustomerLoading
);
export const getCreateUserCustomerSuccess = createSelector(
  [userCustomerSelector],
  ({ createUserCustomerSuccess }) => createUserCustomerSuccess
);
export const getCreateUserCustomerFailed = createSelector(
  [userCustomerSelector],
  ({ createUserCustomerFailed }) => createUserCustomerFailed
);

export const getUpdateUserCustomerLoading = createSelector(
  [userCustomerSelector],
  ({ updateUserCustomerLoading }) => updateUserCustomerLoading
);
export const getUpdateUserCustomerSuccess = createSelector(
  [userCustomerSelector],
  ({ updateUserCustomerSuccess }) => updateUserCustomerSuccess
);
export const getUpdateUserCustomerFailed = createSelector(
  [userCustomerSelector],
  ({ updateUserCustomerFailed }) => updateUserCustomerFailed
);

export const getAddMarketUserCustomerLoading = createSelector(
  [userCustomerSelector],
  ({ addMarketUserCustomerLoading }) => addMarketUserCustomerLoading
);
export const getAddMarketUserCustomerSuccess = createSelector(
  [userCustomerSelector],
  ({ addMarketUserCustomerSuccess }) => addMarketUserCustomerSuccess
);
export const getAddMarketUserCustomerFailed = createSelector(
  [userCustomerSelector],
  ({ addMarketUserCustomerFailed }) => addMarketUserCustomerFailed
);

export const getRemoveMarketUserCustomerLoading = createSelector(
  [userCustomerSelector],
  ({ removeMarketUserCustomerLoading }) => removeMarketUserCustomerLoading
);
export const getRemoveMarketUserCustomerSuccess = createSelector(
  [userCustomerSelector],
  ({ removeMarketUserCustomerSuccess }) => removeMarketUserCustomerSuccess
);
export const getRemoveMarketUserCustomerFailed = createSelector(
  [userCustomerSelector],
  ({ removeMarketUserCustomerFailed }) => removeMarketUserCustomerFailed
);

export const getIsFetchUserCustomersHitted = createSelector(
  [userCustomerSelector],
  ({ isFetchUserCustomersHitted }) => isFetchUserCustomersHitted
);
export const getIsFetchUserCustomerHitted = createSelector(
  [userCustomerSelector],
  ({ isFetchUserCustomerHitted }) => isFetchUserCustomerHitted
);
export const getIsCreateUserCustomerHitted = createSelector(
  [userCustomerSelector],
  ({ isCreateUserCustomerHitted }) => isCreateUserCustomerHitted
);
export const getIsUpdateUserCustomerHitted = createSelector(
  [userCustomerSelector],
  ({ isUpdateUserCustomerHitted }) => isUpdateUserCustomerHitted
);
export const getIsAddMarketUserCustomerHitted = createSelector(
  [userCustomerSelector],
  ({ isAddMarketUserCustomerHitted }) => isAddMarketUserCustomerHitted
);
export const getIsRemoveMarketUserCustomerHitted = createSelector(
  [userCustomerSelector],
  ({ isRemoveMarketUserCustomerHitted }) => isRemoveMarketUserCustomerHitted
);
