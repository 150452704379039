import { takeLatest, put, all, call } from "redux-saga/effects";

import PRODUCT_CATEGORY_SORT_ACTION_TYPES from "./product-category-sort.type";

import {
  setFetchRelatedProductCategoriesPage,
  setRelatedProductCategories,
} from "../product-category/product-category.action";
import {
  setCreateOrUpdateProductCategorySortFailed,
  setCreateOrUpdateProductCategorySortLoading,
  setCreateOrUpdateProductCategorySortSuccess,
} from "./product-category-sort.action";

import { createOrUpdateProductCategorySort } from "../../api/product-category-sort.api";

export function* _createOrUpdateProductCategorySort({ payload: request }) {
  try {
    yield put(setCreateOrUpdateProductCategorySortLoading(true));

    const {
      meta: { message },
    } = yield call(createOrUpdateProductCategorySort, request);

    yield put(setCreateOrUpdateProductCategorySortSuccess(message));
    yield put(setCreateOrUpdateProductCategorySortLoading(false));

    yield put(setFetchRelatedProductCategoriesPage(1));
    yield put(setRelatedProductCategories([]));
  } catch (error) {
    yield put(setCreateOrUpdateProductCategorySortFailed(error));
    yield put(setCreateOrUpdateProductCategorySortLoading(false));
  }
}

export function* onCreateOrUpdateProductCategorySortStart() {
  yield takeLatest(
    PRODUCT_CATEGORY_SORT_ACTION_TYPES.CREATE_OR_UPDATE_PRODUCT_CATEGORY_SORT_START,
    _createOrUpdateProductCategorySort
  );
}

export function* productCategorySortSaga() {
  yield all([call(onCreateOrUpdateProductCategorySortStart)]);
}
