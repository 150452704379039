import { t } from "i18next";

import { getConstantFormat } from "../utils/formatter.utils";
import { isEmpty } from "../utils/validation.utils";

import { ReactComponent as CouponActiveSvg } from "../assets/icons/CouponActive.svg";
import { ReactComponent as OfferActiveSvg } from "../assets/icons/OfferActive.svg";
import { ReactComponent as PromotionActiveSvg } from "../assets/icons/PromotionActive.svg";
import { ReactComponent as LoyaProSvg } from "../assets/icons/LoyaPro.svg";

import { ReactComponent as CouponNonActiveSvg } from "../assets/icons/CouponNonActive.svg";
import { ReactComponent as OfferNonActiveSvg } from "../assets/icons/OfferNonActive.svg";
import { ReactComponent as PromotionNonActiveSvg } from "../assets/icons/PromotionNonActive.svg";

export const DISCOUNT_INCLUDES = {
  MARKET: "market",
  BRANCH: "branch",
  MODIFIERS: "modifiers",
  MODIFIERS_COUNT: "modifiersCount",
  REQUIRE_MODIFIERS: "requireModifiers",
  OBTAIN_MODIFIERS: "obtainModifiers",
  FEES: "fees",
};

export const DISCOUNT_TYPES = {
  COUPON: "COUPON",
  OFFER: "OFFER",
  PROMOTION: "PROMOTION",
  FAST_OFFER: "FAST_OFFER",
  LOYAPRO: "LOYAPRO",
};

export const DISCOUNT_TARGETS = {
  ORDER: "ORDER",
  ORDER_PRODUCT: "ORDER_PRODUCT",
};

export const DISCOUNT_VALUE_TYPES = {
  PERCENT: "PERCENT",
  NOMINAL: "NOMINAL",
};

export const getTypeName = (type) =>
  ({
    [DISCOUNT_TYPES.COUPON]: t("Coupon"),
    [DISCOUNT_TYPES.OFFER]: t("Offer"),
    [DISCOUNT_TYPES.PROMOTION]: t("Promotion"),
    [DISCOUNT_TYPES.FAST_OFFER]: t("Fast Offer"),
    [DISCOUNT_TYPES.LOYAPRO]: t("LoyaPro"),
  }?.[type] ?? (!isEmpty(type) ? getConstantFormat(type) : "-"));

export const getTypeActiveIcon = (type) =>
  ({
    [DISCOUNT_TYPES.COUPON]: CouponActiveSvg,
    [DISCOUNT_TYPES.OFFER]: OfferActiveSvg,
    [DISCOUNT_TYPES.PROMOTION]: PromotionActiveSvg,
    [DISCOUNT_TYPES.FAST_OFFER]: OfferActiveSvg,
    [DISCOUNT_TYPES.LOYAPRO]: LoyaProSvg,
  }?.[type] ?? CouponActiveSvg);

export const getTypeNonActiveIcon = (type) =>
  ({
    [DISCOUNT_TYPES.COUPON]: CouponNonActiveSvg,
    [DISCOUNT_TYPES.OFFER]: OfferNonActiveSvg,
    [DISCOUNT_TYPES.PROMOTION]: PromotionNonActiveSvg,
    [DISCOUNT_TYPES.FAST_OFFER]: OfferNonActiveSvg,
    [DISCOUNT_TYPES.LOYAPRO]: LoyaProSvg,
  }?.[type] ?? CouponNonActiveSvg);
