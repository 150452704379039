import { takeLatest, put, all, call, select } from "redux-saga/effects";

import SUBSCRIPTION_ACTION_TYPES from "./subscription.type";

import {
  appendSubscriptions,
  setCreateSubscriptionFailed,
  setCreateSubscriptionLoading,
  setCreateSubscriptionSuccess,
  setDeleteSubscriptionFailed,
  setDeleteSubscriptionLoading,
  setDeleteSubscriptionSuccess,
  setFetchSubscriptionFailed,
  setFetchSubscriptionLoading,
  setFetchSubscriptionSuccess,
  setFetchSubscriptionsFailed,
  setFetchSubscriptionsLoading,
  setFetchSubscriptionsPage,
  setFetchSubscriptionsSuccess,
  setIsCreateSubscriptionHitted,
  setIsDeleteSubscriptionHitted,
  setIsFetchSubscriptionHitted,
  setIsFetchSubscriptionsHitted,
  setIsSubscriptionsHasMore,
  setIsUpdateSubscriptionHitted,
  setSubscription,
  setSubscriptions,
  setUpdateSubscriptionFailed,
  setUpdateSubscriptionLoading,
  setUpdateSubscriptionSuccess,
} from "./subscription.action";
import {
  getFetchSubscriptionsPage,
  getFetchSubscriptionsPerPage,
  getFetchSubscriptionsSearch,
  getIsFetchSubscriptionsHitted,
} from "./subscription.selector";

import {
  createSubscription,
  deleteSubscription,
  getSubscription,
  getSubscriptions,
  updateSubscription,
} from "../../api/subscription.api";

export function* _getSubscriptions() {
  try {
    yield put(setFetchSubscriptionsLoading(true));

    const search = yield select(getFetchSubscriptionsSearch);
    const page = yield select(getFetchSubscriptionsPage);
    const per_page = yield select(getFetchSubscriptionsPerPage);

    const parameters = { search, page, per_page };

    const {
      meta: { message },
      data: { data: subscriptions },
    } = yield call(getSubscriptions, parameters);

    yield put(setIsFetchSubscriptionsHitted(true));
    yield put(setIsSubscriptionsHasMore(subscriptions.length > 0));

    if (page > 1) {
      yield put(appendSubscriptions(subscriptions));
    } else {
      yield put(setSubscriptions(subscriptions));
    }

    yield put(setFetchSubscriptionsSuccess(message));
    yield put(setFetchSubscriptionsLoading(false));
  } catch (error) {
    yield put(setFetchSubscriptionsFailed(error));
    yield put(setFetchSubscriptionsLoading(false));
  }
}

export function* _getSubscription({ payload: key }) {
  try {
    yield put(setFetchSubscriptionLoading(true));

    const {
      meta: { message },
      data: subscription,
    } = yield call(getSubscription, key);

    yield put(setIsFetchSubscriptionHitted(true));
    yield put(setSubscription(subscription));

    yield put(setFetchSubscriptionSuccess(message));
    yield put(setFetchSubscriptionLoading(false));
  } catch (error) {
    yield put(setFetchSubscriptionFailed(error));
    yield put(setFetchSubscriptionLoading(false));
  }
}

export function* _createSubscription({ payload: request }) {
  try {
    yield put(setCreateSubscriptionLoading(true));

    const {
      meta: { message },
    } = yield call(createSubscription, request);

    yield put(setIsCreateSubscriptionHitted(true));

    const isFetchSubscriptionsHitted = yield select(getIsFetchSubscriptionsHitted);

    if (isFetchSubscriptionsHitted) {
      yield put(setFetchSubscriptionsPage(1));
      yield call(_getSubscriptions);
    }

    yield put(setCreateSubscriptionSuccess(message));
    yield put(setCreateSubscriptionLoading(false));
  } catch (error) {
    yield put(setCreateSubscriptionFailed(error));
    yield put(setCreateSubscriptionLoading(false));
  }
}

export function* _updateSubscription({ payload: { key, request } }) {
  try {
    yield put(setUpdateSubscriptionLoading(true));

    const {
      meta: { message },
    } = yield call(updateSubscription, key, request);

    yield put(setIsUpdateSubscriptionHitted(true));

    const isFetchSubscriptionsHitted = yield select(getIsFetchSubscriptionsHitted);

    if (isFetchSubscriptionsHitted) {
      yield put(setFetchSubscriptionsPage(1));
      yield call(_getSubscriptions);
    }

    yield put(setUpdateSubscriptionSuccess(message));
    yield put(setUpdateSubscriptionLoading(false));
  } catch (error) {
    yield put(setUpdateSubscriptionFailed(error));
    yield put(setUpdateSubscriptionLoading(false));
  }
}

export function* _deleteSubscription({ payload: key }) {
  try {
    yield put(setDeleteSubscriptionLoading(true));

    const {
      meta: { message },
    } = yield call(deleteSubscription, key);

    yield put(setIsDeleteSubscriptionHitted(true));

    const isFetchSubscriptionsHitted = yield select(getIsFetchSubscriptionsHitted);

    if (isFetchSubscriptionsHitted) {
      yield put(setFetchSubscriptionsPage(1));
      yield call(_getSubscriptions);
    }

    yield put(setDeleteSubscriptionSuccess(message));
    yield put(setDeleteSubscriptionLoading(false));
  } catch (error) {
    yield put(setDeleteSubscriptionFailed(error));
    yield put(setDeleteSubscriptionLoading(false));
  }
}

export function* onFetchSubscriptionsStart() {
  yield takeLatest(SUBSCRIPTION_ACTION_TYPES.FETCH_SUBSCRIPTIONS_START, _getSubscriptions);
}

export function* onFetchSubscriptionStart() {
  yield takeLatest(SUBSCRIPTION_ACTION_TYPES.FETCH_SUBSCRIPTION_START, _getSubscription);
}

export function* onCreateSubscriptionStart() {
  yield takeLatest(SUBSCRIPTION_ACTION_TYPES.CREATE_SUBSCRIPTION_START, _createSubscription);
}

export function* onUpdateSubscriptionStart() {
  yield takeLatest(SUBSCRIPTION_ACTION_TYPES.UPDATE_SUBSCRIPTION_START, _updateSubscription);
}

export function* onDeleteSubscriptionStart() {
  yield takeLatest(SUBSCRIPTION_ACTION_TYPES.DELETE_SUBSCRIPTION_START, _deleteSubscription);
}

export function* subscriptionSaga() {
  yield all([
    call(onFetchSubscriptionsStart),
    call(onFetchSubscriptionStart),
    call(onCreateSubscriptionStart),
    call(onUpdateSubscriptionStart),
    call(onDeleteSubscriptionStart),
  ]);
}
