const PRODUCT_CATEGORY_ACTION_TYPES = {
  RESET_PRODUCT_CATEGORY_REDUCER:
    "product-category/RESET_PRODUCT_CATEGORY_REDUCER",

  SET_PRODUCT_CATEGORIES: "product-category/SET_PRODUCT_CATEGORIES",
  SET_SELECT_PRODUCT_CATEGORIES:
    "product-category/SET_SELECT_PRODUCT_CATEGORIES",
  SET_RELATED_PRODUCT_CATEGORIES:
    "product-category/SET_RELATED_PRODUCT_CATEGORIES",
  SET_PRODUCT_CATEGORY: "product-category/SET_PRODUCT_CATEGORY",

  SET_IS_PRODUCT_CATEGORIES_HAS_MORE:
    "product-category/SET_IS_PRODUCT_CATEGORIES_HAS_MORE",
  SET_IS_SELECT_PRODUCT_CATEGORIES_HAS_MORE:
    "product-category/SET_IS_SELECT_PRODUCT_CATEGORIES_HAS_MORE",
  SET_IS_RELATED_PRODUCT_CATEGORIES_HAS_MORE:
    "product-category/SET_IS_RELATED_PRODUCT_CATEGORIES_HAS_MORE",

  SET_FETCH_PRODUCT_CATEGORIES_SEARCH:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_SEARCH",
  SET_FETCH_PRODUCT_CATEGORIES_SORT:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_SORT",
  SET_FETCH_PRODUCT_CATEGORIES_KEY_BY:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_KEY_BY",
  SET_FETCH_PRODUCT_CATEGORIES_PAGE:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_PAGE",
  SET_FETCH_PRODUCT_CATEGORIES_PER_PAGE:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_PER_PAGE",
  SET_FETCH_PRODUCT_CATEGORIES_INCLUDES:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_INCLUDES",
  SET_FETCH_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_FILTER_SECTOR_ID",
  SET_FETCH_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_FILTER_MARKET_ID",
  SET_FETCH_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_FILTER_BRANCH_ID",
  SET_FETCH_PRODUCT_CATEGORIES_LOADING:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_LOADING",
  SET_FETCH_PRODUCT_CATEGORIES_SUCCESS:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_SUCCESS",
  SET_FETCH_PRODUCT_CATEGORIES_FAILED:
    "product-category/SET_FETCH_PRODUCT_CATEGORIES_FAILED",

  SET_FETCH_SELECT_PRODUCT_CATEGORIES_SEARCH:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_SEARCH",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_SORT:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_SORT",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_KEY_BY:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_KEY_BY",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_PAGE:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_PAGE",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_PER_PAGE:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_PER_PAGE",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_INCLUDES:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_INCLUDES",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_SECTOR_ID",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_MARKET_ID",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_BRANCH_ID",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_PRODUCT_CATEGORIES_IDS:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_LOADING:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_LOADING",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_SUCCESS:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_SUCCESS",
  SET_FETCH_SELECT_PRODUCT_CATEGORIES_FAILED:
    "product-category/SET_FETCH_SELECT_PRODUCT_CATEGORIES_FAILED",

  SET_FETCH_RELATED_PRODUCT_CATEGORIES_SEARCH:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_SEARCH",
  SET_FETCH_RELATED_PRODUCT_CATEGORIES_SORT:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_SORT",
  SET_FETCH_RELATED_PRODUCT_CATEGORIES_KEY_BY:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_KEY_BY",
  SET_FETCH_RELATED_PRODUCT_CATEGORIES_PAGE:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_PAGE",
  SET_FETCH_RELATED_PRODUCT_CATEGORIES_PER_PAGE:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_PER_PAGE",
  SET_FETCH_RELATED_PRODUCT_CATEGORIES_INCLUDES:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_INCLUDES",
  SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_SECTOR_ID",
  SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_MARKET_ID",
  SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_BRANCH_ID",
  SET_FETCH_RELATED_PRODUCT_CATEGORIES_LOADING:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_LOADING",
  SET_FETCH_RELATED_PRODUCT_CATEGORIES_SUCCESS:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_SUCCESS",
  SET_FETCH_RELATED_PRODUCT_CATEGORIES_FAILED:
    "product-category/SET_FETCH_RELATED_PRODUCT_CATEGORIES_FAILED",

  SET_APPEND_SELECT_PRODUCT_CATEGORIES_SEARCH:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_SEARCH",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_SORT:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_SORT",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_KEY_BY:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_KEY_BY",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_PAGE:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_PAGE",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_PER_PAGE:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_PER_PAGE",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_INCLUDES:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_INCLUDES",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_SECTOR_ID",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_MARKET_ID",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_BRANCH_ID",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_PRODUCT_CATEGORIES_IDS:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_PRODUCT_CATEGORIES_IDS",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_LOADING:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_LOADING",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_SUCCESS:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_SUCCESS",
  SET_APPEND_SELECT_PRODUCT_CATEGORIES_FAILED:
    "product-category/SET_APPEND_SELECT_PRODUCT_CATEGORIES_FAILED",

  SET_FETCH_PRODUCT_CATEGORY_LOADING:
    "product-category/SET_FETCH_PRODUCT_CATEGORY_LOADING",
  SET_FETCH_PRODUCT_CATEGORY_SUCCESS:
    "product-category/SET_FETCH_PRODUCT_CATEGORY_SUCCESS",
  SET_FETCH_PRODUCT_CATEGORY_FAILED:
    "product-category/SET_FETCH_PRODUCT_CATEGORY_FAILED",

  SET_SORT_PRODUCT_CATEGORIES_LOADING:
    "product-category/SET_SORT_PRODUCT_CATEGORIES_LOADING",
  SET_SORT_PRODUCT_CATEGORIES_SUCCESS:
    "product-category/SET_SORT_PRODUCT_CATEGORIES_SUCCESS",
  SET_SORT_PRODUCT_CATEGORIES_FAILED:
    "product-category/SET_SORT_PRODUCT_CATEGORIES_FAILED",

  SET_CREATE_PRODUCT_CATEGORY_LOADING:
    "product-category/SET_CREATE_PRODUCT_CATEGORY_LOADING",
  SET_CREATE_PRODUCT_CATEGORY_SUCCESS:
    "product-category/SET_CREATE_PRODUCT_CATEGORY_SUCCESS",
  SET_CREATE_PRODUCT_CATEGORY_FAILED:
    "product-category/SET_CREATE_PRODUCT_CATEGORY_FAILED",

  SET_UPDATE_PRODUCT_CATEGORY_LOADING:
    "product-category/SET_UPDATE_PRODUCT_CATEGORY_LOADING",
  SET_UPDATE_PRODUCT_CATEGORY_SUCCESS:
    "product-category/SET_UPDATE_PRODUCT_CATEGORY_SUCCESS",
  SET_UPDATE_PRODUCT_CATEGORY_FAILED:
    "product-category/SET_UPDATE_PRODUCT_CATEGORY_FAILED",

  SET_DELETE_PRODUCT_CATEGORY_LOADING:
    "product-category/SET_DELETE_PRODUCT_CATEGORY_LOADING",
  SET_DELETE_PRODUCT_CATEGORY_SUCCESS:
    "product-category/SET_DELETE_PRODUCT_CATEGORY_SUCCESS",
  SET_DELETE_PRODUCT_CATEGORY_FAILED:
    "product-category/SET_DELETE_PRODUCT_CATEGORY_FAILED",

  APPEND_PRODUCT_CATEGORIES: "product-category/APPEND_PRODUCT_CATEGORIES",
  APPEND_SELECT_PRODUCT_CATEGORIES:
    "product-category/APPEND_SELECT_PRODUCT_CATEGORIES",
  APPEND_RELATED_PRODUCT_CATEGORIES:
    "product-category/APPEND_RELATED_PRODUCT_CATEGORIES",

  IS_FETCH_PRODUCT_CATEGORIES_HITTED:
    "product-category/IS_FETCH_PRODUCT_CATEGORIES_HITTED",
  IS_FETCH_SELECT_PRODUCT_CATEGORIES_HITTED:
    "product-category/IS_FETCH_SELECT_PRODUCT_CATEGORIES_HITTED",
  IS_FETCH_RELATED_PRODUCT_CATEGORIES_HITTED:
    "product-category/IS_FETCH_RELATED_PRODUCT_CATEGORIES_HITTED",
  IS_APPEND_SELECT_PRODUCT_CATEGORIES_HITTED:
    "product-category/IS_APPEND_SELECT_PRODUCT_CATEGORIES_HITTED",
  IS_FETCH_PRODUCT_CATEGORY_HITTED:
    "product-category/IS_FETCH_PRODUCT_CATEGORY_HITTED",
  IS_SORT_PRODUCT_CATEGORIES_HITTED:
    "product-category/IS_SORT_PRODUCT_CATEGORIES_HITTED",
  IS_CREATE_PRODUCT_CATEGORY_HITTED:
    "product-category/IS_CREATE_PRODUCT_CATEGORY_HITTED",
  IS_UPDATE_PRODUCT_CATEGORY_HITTED:
    "product-category/IS_UPDATE_PRODUCT_CATEGORY_HITTED",
  IS_DELETE_PRODUCT_CATEGORY_HITTED:
    "product-category/IS_DELETE_PRODUCT_CATEGORY_HITTED",

  FETCH_PRODUCT_CATEGORIES_START:
    "product-category/FETCH_PRODUCT_CATEGORIES_START",
  FETCH_SELECT_PRODUCT_CATEGORIES_START:
    "product-category/FETCH_SELECT_PRODUCT_CATEGORIES_START",
  FETCH_RELATED_PRODUCT_CATEGORIES_START:
    "product-category/FETCH_RELATED_PRODUCT_CATEGORIES_START",
  APPEND_SELECT_PRODUCT_CATEGORIES_START:
    "product-category/APPEND_SELECT_PRODUCT_CATEGORIES_START",
  FETCH_PRODUCT_CATEGORY_START: "product-category/FETCH_PRODUCT_CATEGORY_START",
  SORT_PRODUCT_CATEGORIES_START:
    "product-category/SORT_PRODUCT_CATEGORIES_START",
  CREATE_PRODUCT_CATEGORY_START:
    "product-category/CREATE_PRODUCT_CATEGORY_START",
  UPDATE_PRODUCT_CATEGORY_START:
    "product-category/UPDATE_PRODUCT_CATEGORY_START",
  DELETE_PRODUCT_CATEGORY_START:
    "product-category/DELETE_PRODUCT_CATEGORY_START",
};

export default PRODUCT_CATEGORY_ACTION_TYPES;
