import BRANCH_ACTION_TYPES from "./branch.type";
import { createAction } from "../../utils/store.utils";

export const setIsModalAutoClose = (isModalAutoClose) =>
  createAction(BRANCH_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE, isModalAutoClose);

export const setBranches = (branches) =>
  createAction(BRANCH_ACTION_TYPES.SET_BRANCHES, branches);
export const setSelectBranches = (selectBranches) =>
  createAction(BRANCH_ACTION_TYPES.SET_SELECT_BRANCHES, selectBranches);
export const setActiveBranches = (activeBranches) =>
  createAction(BRANCH_ACTION_TYPES.SET_ACTIVE_BRANCHES, activeBranches);
export const setBranch = (branch) =>
  createAction(BRANCH_ACTION_TYPES.SET_BRANCH, branch);
export const setSelectBranch = (selectBranch) =>
  createAction(BRANCH_ACTION_TYPES.SET_SELECT_BRANCH, selectBranch);
export const setActiveBranch = (activeBranch) =>
  createAction(BRANCH_ACTION_TYPES.SET_ACTIVE_BRANCH, activeBranch);

export const setSubscriptionBranch = (subscriptionBranch) =>
  createAction(BRANCH_ACTION_TYPES.SET_SUBSCRIPTION_BRANCH, subscriptionBranch);

export const setIsBranchesHasMore = (isBranchesHasMore) =>
  createAction(BRANCH_ACTION_TYPES.SET_IS_BRANCHES_HAS_MORE, isBranchesHasMore);
export const setIsSelectBranchesHasMore = (isSelectBranchesHasMore) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_SELECT_BRANCHES_HAS_MORE,
    isSelectBranchesHasMore
  );
export const setIsActiveBranchesHasMore = (isActiveBranchesHasMore) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_ACTIVE_BRANCHES_HAS_MORE,
    isActiveBranchesHasMore
  );

export const setFetchBranchesSearch = (fetchBranchesSearch) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SEARCH,
    fetchBranchesSearch
  );
export const setFetchBranchesPage = (fetchBranchesPage) =>
  createAction(BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PAGE, fetchBranchesPage);
export const setFetchBranchesPerPage = (fetchBranchesPerPage) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PER_PAGE,
    fetchBranchesPerPage
  );
export const setFetchBranchesIncludes = (fetchBranchesIncludes) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_INCLUDES,
    fetchBranchesIncludes
  );
export const setFetchBranchesFilterMarketId = (fetchBranchesFilterMarketId) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_MARKET_ID,
    fetchBranchesFilterMarketId
  );
export const setFetchBranchesFilterIsActive = (fetchBranchesFilterIsActive) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_IS_ACTIVE,
    fetchBranchesFilterIsActive
  );
export const setFetchBranchesLoading = (fetchBranchesLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_LOADING,
    fetchBranchesLoading
  );
export const setFetchBranchesSuccess = (fetchBranchesSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SUCCESS,
    fetchBranchesSuccess
  );
export const setFetchBranchesFailed = (fetchBranchesFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FAILED,
    fetchBranchesFailed
  );

export const setFetchSelectBranchesPage = (fetchSelectBranchesPage) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_PAGE,
    fetchSelectBranchesPage
  );
export const setFetchSelectBranchesPerPage = (fetchSelectBranchesPerPage) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_PER_PAGE,
    fetchSelectBranchesPerPage
  );
export const setFetchSelectBranchesIncludes = (fetchSelectBranchesIncludes) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_INCLUDES,
    fetchSelectBranchesIncludes
  );
export const setFetchSelectBranchesFilterMarketId = (
  fetchSelectBranchesFilterMarketId
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_FILTER_MARKET_ID,
    fetchSelectBranchesFilterMarketId
  );
export const setFetchSelectBranchesFilterIsActive = (
  fetchSelectBranchesFilterIsActive
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_FILTER_IS_ACTIVE,
    fetchSelectBranchesFilterIsActive
  );
export const setFetchSelectBranchesLoading = (fetchSelectBranchesLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_LOADING,
    fetchSelectBranchesLoading
  );
export const setFetchSelectBranchesSuccess = (fetchSelectBranchesSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_SUCCESS,
    fetchSelectBranchesSuccess
  );
export const setFetchSelectBranchesFailed = (fetchSelectBranchesFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_FAILED,
    fetchSelectBranchesFailed
  );

export const setFetchActiveBranchesPage = (fetchActiveBranchesPage) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_PAGE,
    fetchActiveBranchesPage
  );
export const setFetchActiveBranchesPerPage = (fetchActiveBranchesPerPage) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_PER_PAGE,
    fetchActiveBranchesPerPage
  );
export const setFetchActiveBranchesIncludes = (fetchActiveBranchesIncludes) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_INCLUDES,
    fetchActiveBranchesIncludes
  );
export const setFetchActiveBranchesFilterMarketId = (
  fetchActiveBranchesFilterMarketId
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_FILTER_MARKET_ID,
    fetchActiveBranchesFilterMarketId
  );
export const setFetchActiveBranchesFilterIsActive = (
  fetchActiveBranchesFilterIsActive
) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_FILTER_IS_ACTIVE,
    fetchActiveBranchesFilterIsActive
  );
export const setFetchActiveBranchesLoading = (fetchActiveBranchesLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_LOADING,
    fetchActiveBranchesLoading
  );
export const setFetchActiveBranchesSuccess = (fetchActiveBranchesSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_SUCCESS,
    fetchActiveBranchesSuccess
  );
export const setFetchActiveBranchesFailed = (fetchActiveBranchesFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_FAILED,
    fetchActiveBranchesFailed
  );

export const setFetchBranchLoading = (fetchBranchLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_LOADING,
    fetchBranchLoading
  );
export const setFetchBranchSuccess = (fetchBranchSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_SUCCESS,
    fetchBranchSuccess
  );
export const setFetchBranchFailed = (fetchBranchFailed) =>
  createAction(BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_FAILED, fetchBranchFailed);

export const setFetchSelectBranchLoading = (fetchSelectBranchLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCH_LOADING,
    fetchSelectBranchLoading
  );
export const setFetchSelectBranchSuccess = (fetchSelectBranchSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCH_SUCCESS,
    fetchSelectBranchSuccess
  );
export const setFetchSelectBranchFailed = (fetchSelectBranchFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCH_FAILED,
    fetchSelectBranchFailed
  );

export const setFetchActiveBranchLoading = (fetchActiveBranchLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCH_LOADING,
    fetchActiveBranchLoading
  );
export const setFetchActiveBranchSuccess = (fetchActiveBranchSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCH_SUCCESS,
    fetchActiveBranchSuccess
  );
export const setFetchActiveBranchFailed = (fetchActiveBranchFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCH_FAILED,
    fetchActiveBranchFailed
  );

export const setCreateBranchLoading = (createBranchLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_LOADING,
    createBranchLoading
  );
export const setCreateBranchSuccess = (createBranchSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_SUCCESS,
    createBranchSuccess
  );
export const setCreateBranchFailed = (createBranchFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_FAILED,
    createBranchFailed
  );

export const setUpdateBranchLoading = (updateBranchLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_LOADING,
    updateBranchLoading
  );
export const setUpdateBranchSuccess = (updateBranchSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_SUCCESS,
    updateBranchSuccess
  );
export const setUpdateBranchFailed = (updateBranchFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_FAILED,
    updateBranchFailed
  );

export const setDeleteBranchLoading = (deleteBranchLoading) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_LOADING,
    deleteBranchLoading
  );
export const setDeleteBranchSuccess = (deleteBranchSuccess) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_SUCCESS,
    deleteBranchSuccess
  );
export const setDeleteBranchFailed = (deleteBranchFailed) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_FAILED,
    deleteBranchFailed
  );

export const appendBranches = (branches) =>
  createAction(BRANCH_ACTION_TYPES.APPEND_BRANCHES, branches);
export const appendSelectBranches = (selectBranches) =>
  createAction(BRANCH_ACTION_TYPES.APPEND_SELECT_BRANCHES, selectBranches);
export const appendActiveBranches = (activeBranches) =>
  createAction(BRANCH_ACTION_TYPES.APPEND_ACTIVE_BRANCHES, activeBranches);

export const fetchBranchesStart = () =>
  createAction(BRANCH_ACTION_TYPES.FETCH_BRANCHES_START);
export const fetchSelectBranchesStart = () =>
  createAction(BRANCH_ACTION_TYPES.FETCH_SELECT_BRANCHES_START);
export const fetchActiveBranchesStart = () =>
  createAction(BRANCH_ACTION_TYPES.FETCH_ACTIVE_BRANCHES_START);
export const fetchBranchStart = (branchId) =>
  createAction(BRANCH_ACTION_TYPES.FETCH_BRANCH_START, branchId);
export const fetchSelectBranchStart = (branchId) =>
  createAction(BRANCH_ACTION_TYPES.FETCH_SELECT_BRANCH_START, branchId);
export const fetchActiveBranchStart = (branchId) =>
  createAction(BRANCH_ACTION_TYPES.FETCH_ACTIVE_BRANCH_START, branchId);
export const createBranchStart = (request) =>
  createAction(BRANCH_ACTION_TYPES.CREATE_BRANCH_START, request);
export const updateBranchStart = (branchId, request) =>
  createAction(BRANCH_ACTION_TYPES.UPDATE_BRANCH_START, { branchId, request });
export const deleteBranchStart = (branchId) =>
  createAction(BRANCH_ACTION_TYPES.DELETE_BRANCH_START, branchId);

export const setIsFetchBranchesHitted = (isFetchBranchesHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCHES_HITTED,
    isFetchBranchesHitted
  );
export const setIsFetchSelectBranchesHitted = (isFetchSelectBranchesHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_SELECT_BRANCHES_HITTED,
    isFetchSelectBranchesHitted
  );
export const setIsFetchActiveBranchesHitted = (isFetchActiveBranchesHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_ACTIVE_BRANCHES_HITTED,
    isFetchActiveBranchesHitted
  );
export const setIsFetchBranchHitted = (isFetchBranchHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCH_HITTED,
    isFetchBranchHitted
  );
export const setIsFetchSelectBranchHitted = (isFetchSelectBranchHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_SELECT_BRANCH_HITTED,
    isFetchSelectBranchHitted
  );
export const setIsFetchActiveBranchHitted = (isFetchActiveBranchHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_FETCH_ACTIVE_BRANCH_HITTED,
    isFetchActiveBranchHitted
  );
export const setIsCreateBranchHitted = (isCreateBranchHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_CREATE_BRANCH_HITTED,
    isCreateBranchHitted
  );
export const setIsUpdateBranchHitted = (isUpdateBranchHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_UPDATE_BRANCH_HITTED,
    isUpdateBranchHitted
  );
export const setIsDeleteBranchHitted = (isDeleteBranchHitted) =>
  createAction(
    BRANCH_ACTION_TYPES.SET_IS_DELETE_BRANCH_HITTED,
    isDeleteBranchHitted
  );

export const resetBranchReducer = () =>
  createAction(BRANCH_ACTION_TYPES.RESET_BRANCH_REDUCER);
