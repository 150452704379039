import { createSelector } from "reselect";

const marketSelector = ({ market }) => market;

export const getMarketTableColumns = createSelector(
  [marketSelector],
  ({ marketTableColumns }) => marketTableColumns
);

export const getCountries = createSelector(
  [marketSelector],
  ({ countries }) => countries
);
export const getCountriesByCurrency = createSelector(
  [marketSelector],
  ({ countriesByCurrency }) => countriesByCurrency
);
export const getCountriesByPhonePrefix = createSelector(
  [marketSelector],
  ({ countriesByPhonePrefix }) => countriesByPhonePrefix
);

export const getIsModalAutoClose = createSelector(
  [marketSelector],
  ({ isModalAutoClose }) => isModalAutoClose
);

export const getMarkets = createSelector(
  [marketSelector],
  ({ markets }) => markets
);
export const getSelectMarkets = createSelector(
  [marketSelector],
  ({ selectMarkets }) => selectMarkets
);
export const getSubscriptionMarkets = createSelector(
  [marketSelector],
  ({ subscriptionMarkets }) => subscriptionMarkets
);
export const getMarket = createSelector(
  [marketSelector],
  ({ market }) => market
);
export const getSubscriptionMarket = createSelector(
  [marketSelector],
  ({ subscriptionMarket }) => subscriptionMarket
);

export const getIsMarketsHasMore = createSelector(
  [marketSelector],
  ({ isMarketsHasMore }) => isMarketsHasMore
);
export const getIsSelectMarketsHasMore = createSelector(
  [marketSelector],
  ({ isSelectMarketsHasMore }) => isSelectMarketsHasMore
);
export const getIsSubscriptionMarketsHasMore = createSelector(
  [marketSelector],
  ({ isSubscriptionMarketsHasMore }) => isSubscriptionMarketsHasMore
);

export const getFetchMarketsSearch = createSelector(
  [marketSelector],
  ({ fetchMarketsSearch }) => fetchMarketsSearch
);
export const getFetchMarketsSort = createSelector(
  [marketSelector],
  ({ fetchMarketsSort }) => fetchMarketsSort
);
export const getFetchMarketsKeyBy = createSelector(
  [marketSelector],
  ({ fetchMarketsKeyBy }) => fetchMarketsKeyBy
);
export const getFetchMarketsPage = createSelector(
  [marketSelector],
  ({ fetchMarketsPage }) => fetchMarketsPage
);
export const getFetchMarketsPerPage = createSelector(
  [marketSelector],
  ({ fetchMarketsPerPage }) => fetchMarketsPerPage
);
export const getFetchMarketsIncludes = createSelector(
  [marketSelector],
  ({ fetchMarketsIncludes }) => fetchMarketsIncludes
);
export const getFetchMarketsFilterMarketsIds = createSelector(
  [marketSelector],
  ({ fetchMarketsFilterMarketsIds }) => fetchMarketsFilterMarketsIds
);
export const getFetchMarketsFilterSectorId = createSelector(
  [marketSelector],
  ({ fetchMarketsFilterSectorId }) => fetchMarketsFilterSectorId
);
export const getFetchMarketsFilterMerchantId = createSelector(
  [marketSelector],
  ({ fetchMarketsFilterMerchantId }) => fetchMarketsFilterMerchantId
);
export const getFetchMarketsFilterResellerId = createSelector(
  [marketSelector],
  ({ fetchMarketsFilterResellerId }) => fetchMarketsFilterResellerId
);
export const getFetchMarketsFilterCreatedBy = createSelector(
  [marketSelector],
  ({ fetchMarketsFilterCreatedBy }) => fetchMarketsFilterCreatedBy
);
export const getFetchMarketsLoading = createSelector(
  [marketSelector],
  ({ fetchMarketsLoading }) => fetchMarketsLoading
);
export const getFetchMarketsSuccess = createSelector(
  [marketSelector],
  ({ fetchMarketsSuccess }) => fetchMarketsSuccess
);
export const getFetchMarketsFailed = createSelector(
  [marketSelector],
  ({ fetchMarketsFailed }) => fetchMarketsFailed
);

export const getFetchSelectMarketsSearch = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsSearch }) => fetchSelectMarketsSearch
);
export const getFetchSelectMarketsSort = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsSort }) => fetchSelectMarketsSort
);
export const getFetchSelectMarketsKeyBy = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsKeyBy }) => fetchSelectMarketsKeyBy
);
export const getFetchSelectMarketsPage = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsPage }) => fetchSelectMarketsPage
);
export const getFetchSelectMarketsPerPage = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsPerPage }) => fetchSelectMarketsPerPage
);
export const getFetchSelectMarketsIncludes = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsIncludes }) => fetchSelectMarketsIncludes
);
export const getFetchSelectMarketsFilterMarketsIds = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsFilterMarketsIds }) => fetchSelectMarketsFilterMarketsIds
);
export const getFetchSelectMarketsFilterSectorId = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsFilterSectorId }) => fetchSelectMarketsFilterSectorId
);
export const getFetchSelectMarketsFilterMerchantId = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsFilterMerchantId }) => fetchSelectMarketsFilterMerchantId
);
export const getFetchSelectMarketsFilterResellerId = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsFilterResellerId }) => fetchSelectMarketsFilterResellerId
);
export const getFetchSelectMarketsFilterCreatedBy = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsFilterCreatedBy }) => fetchSelectMarketsFilterCreatedBy
);
export const getFetchSelectMarketsLoading = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsLoading }) => fetchSelectMarketsLoading
);
export const getFetchSelectMarketsSuccess = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsSuccess }) => fetchSelectMarketsSuccess
);
export const getFetchSelectMarketsFailed = createSelector(
  [marketSelector],
  ({ fetchSelectMarketsFailed }) => fetchSelectMarketsFailed
);

export const getFetchSubscriptionMarketsSearch = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsSearch }) => fetchSubscriptionMarketsSearch
);
export const getFetchSubscriptionMarketsSort = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsSort }) => fetchSubscriptionMarketsSort
);
export const getFetchSubscriptionMarketsKeyBy = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsKeyBy }) => fetchSubscriptionMarketsKeyBy
);
export const getFetchSubscriptionMarketsPage = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsPage }) => fetchSubscriptionMarketsPage
);
export const getFetchSubscriptionMarketsPerPage = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsPerPage }) => fetchSubscriptionMarketsPerPage
);
export const getFetchSubscriptionMarketsIncludes = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsIncludes }) => fetchSubscriptionMarketsIncludes
);
export const getFetchSubscriptionMarketsFilterMarketsIds = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsFilterMarketsIds }) =>
    fetchSubscriptionMarketsFilterMarketsIds
);
export const getFetchSubscriptionMarketsFilterSectorId = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsFilterSectorId }) =>
    fetchSubscriptionMarketsFilterSectorId
);
export const getFetchSubscriptionMarketsFilterMerchantId = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsFilterMerchantId }) =>
    fetchSubscriptionMarketsFilterMerchantId
);
export const getFetchSubscriptionMarketsFilterResellerId = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsFilterResellerId }) =>
    fetchSubscriptionMarketsFilterResellerId
);
export const getFetchSubscriptionMarketsFilterCreatedBy = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsFilterCreatedBy }) =>
    fetchSubscriptionMarketsFilterCreatedBy
);
export const getFetchSubscriptionMarketsLoading = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsLoading }) => fetchSubscriptionMarketsLoading
);
export const getFetchSubscriptionMarketsSuccess = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsSuccess }) => fetchSubscriptionMarketsSuccess
);
export const getFetchSubscriptionMarketsFailed = createSelector(
  [marketSelector],
  ({ fetchSubscriptionMarketsFailed }) => fetchSubscriptionMarketsFailed
);

export const getAppendSelectMarketsSearch = createSelector(
  [marketSelector],
  ({ appendSelectMarketsSearch }) => appendSelectMarketsSearch
);
export const getAppendSelectMarketsSort = createSelector(
  [marketSelector],
  ({ appendSelectMarketsSort }) => appendSelectMarketsSort
);
export const getAppendSelectMarketsKeyBy = createSelector(
  [marketSelector],
  ({ appendSelectMarketsKeyBy }) => appendSelectMarketsKeyBy
);
export const getAppendSelectMarketsPage = createSelector(
  [marketSelector],
  ({ appendSelectMarketsPage }) => appendSelectMarketsPage
);
export const getAppendSelectMarketsPerPage = createSelector(
  [marketSelector],
  ({ appendSelectMarketsPerPage }) => appendSelectMarketsPerPage
);
export const getAppendSelectMarketsIncludes = createSelector(
  [marketSelector],
  ({ appendSelectMarketsIncludes }) => appendSelectMarketsIncludes
);
export const getAppendSelectMarketsFilterMarketsIds = createSelector(
  [marketSelector],
  ({ appendSelectMarketsFilterMarketsIds }) =>
    appendSelectMarketsFilterMarketsIds
);
export const getAppendSelectMarketsFilterSectorId = createSelector(
  [marketSelector],
  ({ appendSelectMarketsFilterSectorId }) => appendSelectMarketsFilterSectorId
);
export const getAppendSelectMarketsFilterMerchantId = createSelector(
  [marketSelector],
  ({ appendSelectMarketsFilterMerchantId }) =>
    appendSelectMarketsFilterMerchantId
);
export const getAppendSelectMarketsFilterResellerId = createSelector(
  [marketSelector],
  ({ appendSelectMarketsFilterResellerId }) =>
    appendSelectMarketsFilterResellerId
);
export const getAppendSelectMarketsFilterCreatedBy = createSelector(
  [marketSelector],
  ({ appendSelectMarketsFilterCreatedBy }) => appendSelectMarketsFilterCreatedBy
);
export const getAppendSelectMarketsLoading = createSelector(
  [marketSelector],
  ({ appendSelectMarketsLoading }) => appendSelectMarketsLoading
);
export const getAppendSelectMarketsSuccess = createSelector(
  [marketSelector],
  ({ appendSelectMarketsSuccess }) => appendSelectMarketsSuccess
);
export const getAppendSelectMarketsFailed = createSelector(
  [marketSelector],
  ({ appendSelectMarketsFailed }) => appendSelectMarketsFailed
);

export const getFetchMarketLoading = createSelector(
  [marketSelector],
  ({ fetchMarketLoading }) => fetchMarketLoading
);
export const getFetchMarketSuccess = createSelector(
  [marketSelector],
  ({ fetchMarketSuccess }) => fetchMarketSuccess
);
export const getFetchMarketFailed = createSelector(
  [marketSelector],
  ({ fetchMarketFailed }) => fetchMarketFailed
);

export const getCreateMarketLoading = createSelector(
  [marketSelector],
  ({ createMarketLoading }) => createMarketLoading
);
export const getCreateMarketSuccess = createSelector(
  [marketSelector],
  ({ createMarketSuccess }) => createMarketSuccess
);
export const getCreateMarketFailed = createSelector(
  [marketSelector],
  ({ createMarketFailed }) => createMarketFailed
);

export const getUpdateMarketLoading = createSelector(
  [marketSelector],
  ({ updateMarketLoading }) => updateMarketLoading
);
export const getUpdateMarketSuccess = createSelector(
  [marketSelector],
  ({ updateMarketSuccess }) => updateMarketSuccess
);
export const getUpdateMarketFailed = createSelector(
  [marketSelector],
  ({ updateMarketFailed }) => updateMarketFailed
);

export const getDeleteMarketLoading = createSelector(
  [marketSelector],
  ({ deleteMarketLoading }) => deleteMarketLoading
);
export const getDeleteMarketSuccess = createSelector(
  [marketSelector],
  ({ deleteMarketSuccess }) => deleteMarketSuccess
);
export const getDeleteMarketFailed = createSelector(
  [marketSelector],
  ({ deleteMarketFailed }) => deleteMarketFailed
);

export const getSendVerificationMarketLoading = createSelector(
  [marketSelector],
  ({ sendVerificationMarketLoading }) => sendVerificationMarketLoading
);
export const getSendVerificationMarketSuccess = createSelector(
  [marketSelector],
  ({ sendVerificationMarketSuccess }) => sendVerificationMarketSuccess
);
export const getSendVerificationMarketFailed = createSelector(
  [marketSelector],
  ({ sendVerificationMarketFailed }) => sendVerificationMarketFailed
);

export const getVerifyVerificationMarketLoading = createSelector(
  [marketSelector],
  ({ verifyVerificationMarketLoading }) => verifyVerificationMarketLoading
);
export const getVerifyVerificationMarketSuccess = createSelector(
  [marketSelector],
  ({ verifyVerificationMarketSuccess }) => verifyVerificationMarketSuccess
);
export const getVerifyVerificationMarketFailed = createSelector(
  [marketSelector],
  ({ verifyVerificationMarketFailed }) => verifyVerificationMarketFailed
);

export const getIsFetchMarketsHitted = createSelector(
  [marketSelector],
  ({ isFetchMarketsHitted }) => isFetchMarketsHitted
);
export const getIsFetchSelectMarketsHitted = createSelector(
  [marketSelector],
  ({ isFetchSelectMarketsHitted }) => isFetchSelectMarketsHitted
);
export const getIsFetchSubscriptionMarketsHitted = createSelector(
  [marketSelector],
  ({ isFetchSubscriptionMarketsHitted }) => isFetchSubscriptionMarketsHitted
);
export const getIsFetchMarketHitted = createSelector(
  [marketSelector],
  ({ isFetchMarketHitted }) => isFetchMarketHitted
);
export const getIsCreateMarketHitted = createSelector(
  [marketSelector],
  ({ isCreateMarketHitted }) => isCreateMarketHitted
);
export const getIsUpdateMarketHitted = createSelector(
  [marketSelector],
  ({ isUpdateMarketHitted }) => isUpdateMarketHitted
);
export const getIsDeleteMarketHitted = createSelector(
  [marketSelector],
  ({ isDeleteMarketHitted }) => isDeleteMarketHitted
);
export const getIsSendVerificationMarketHitted = createSelector(
  [marketSelector],
  ({ isSendVerificationMarketHitted }) => isSendVerificationMarketHitted
);
export const getIsVerifyVerificationMarketHitted = createSelector(
  [marketSelector],
  ({ isVerifyVerificationMarketHitted }) => isVerifyVerificationMarketHitted
);
export const getIsAppendSelectMarketsHitted = createSelector(
  [marketSelector],
  ({ isAppendSelectMarketsHitted }) => isAppendSelectMarketsHitted
);
