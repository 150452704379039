import COMPONENT_ACTION_TYPES from "./component.type";
import { createAction } from "../../utils/store.utils";

export const setScreenWidth = (screenWidth) => createAction(COMPONENT_ACTION_TYPES.SET_SCREEN_WIDTH, screenWidth);

export const setIsSidenavOpen = (isSidenavOpen) =>
  createAction(COMPONENT_ACTION_TYPES.SET_IS_SIDENAV_OPEN, isSidenavOpen);
export const setIsModalOpen = (isModalOpen) => createAction(COMPONENT_ACTION_TYPES.SET_IS_MODAL_OPEN, isModalOpen);
export const setCurrentModal = (currentModal) => createAction(COMPONENT_ACTION_TYPES.SET_CURRENT_MODAL, currentModal);

export const setOrderCustomerIpAddress = (orderCustomerIpAddress) =>
  createAction(COMPONENT_ACTION_TYPES.SET_ORDER_CUSTOMER_IP_ADDRESS, orderCustomerIpAddress);
export const setIsOrderCustomerHideSidebar = (isOrderCustomerHideSidebar) =>
  createAction(COMPONENT_ACTION_TYPES.SET_IS_ORDER_CUSTOMER_HIDE_SIDEBAR, isOrderCustomerHideSidebar);
export const setIsOrderCustomerHideBackButton = (isOrderCustomerHideBackButton) =>
  createAction(COMPONENT_ACTION_TYPES.SET_IS_ORDER_CUSTOMER_HIDE_BACK_BUTTON, isOrderCustomerHideBackButton);
export const setIsOrderCustomerHideTitle = (isOrderCustomerHideTitle) =>
  createAction(COMPONENT_ACTION_TYPES.SET_IS_ORDER_CUSTOMER_HIDE_TITLE, isOrderCustomerHideTitle);
export const setIsOrderCustomerHideTopToolbar = (isOrderCustomerHideTopToolbar) =>
  createAction(COMPONENT_ACTION_TYPES.SET_IS_ORDER_CUSTOMER_HIDE_TOP_TOOLBAR, isOrderCustomerHideTopToolbar);

export const setOrderWaitingDisplay = (orderWaitingDisplay) =>
  createAction(COMPONENT_ACTION_TYPES.SET_ORDER_WAITING_DISPLAY, orderWaitingDisplay);
export const setIsOrderWaitingHideSidebar = (isOrderWaitingHideSidebar) =>
  createAction(COMPONENT_ACTION_TYPES.SET_IS_ORDER_WAITING_HIDE_SIDEBAR, isOrderWaitingHideSidebar);
export const setIsOrderWaitingHideBackButton = (isOrderWaitingHideBackButton) =>
  createAction(COMPONENT_ACTION_TYPES.SET_IS_ORDER_WAITING_HIDE_BACK_BUTTON, isOrderWaitingHideBackButton);
export const setIsOrderWaitingHideTitle = (isOrderWaitingHideTitle) =>
  createAction(COMPONENT_ACTION_TYPES.SET_IS_ORDER_WAITING_HIDE_TITLE, isOrderWaitingHideTitle);
export const setIsOrderWaitingHideTopToolbar = (isOrderWaitingHideTopToolbar) =>
  createAction(COMPONENT_ACTION_TYPES.SET_IS_ORDER_WAITING_HIDE_TOP_TOOLBAR, isOrderWaitingHideTopToolbar);

export const resetComponentReducer = () => createAction(COMPONENT_ACTION_TYPES.RESET_COMPONENT_REDUCER);
