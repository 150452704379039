import { getRemovedDuplicates } from "../../utils/formatter.utils";

import PRODUCT_CATEGORY_ACTION_TYPES from "./product-category.type";

export const PRODUCT_CATEGORY_INITIAL_STATE = {
  productCategories: [],
  selectProductCategories: {},
  relatedProductCategories: [],
  productCategory: null,

  isProductCategoriesHasMore: true,
  isSelectProductCategoriesHasMore: true,
  isRelatedProductCategoriesHasMore: true,

  fetchProductCategoriesSearch: null,
  fetchProductCategoriesSort: null,
  fetchProductCategoriesKeyBy: null,
  fetchProductCategoriesPage: 1,
  fetchProductCategoriesPerPage: null,
  fetchProductCategoriesIncludes: null,
  fetchProductCategoriesFilterSectorId: null,
  fetchProductCategoriesFilterMarketId: null,
  fetchProductCategoriesFilterBranchId: null,
  fetchProductCategoriesLoading: false,
  fetchProductCategoriesSuccess: null,
  fetchProductCategoriesFailed: null,

  fetchSelectProductCategoriesSearch: null,
  fetchSelectProductCategoriesSort: null,
  fetchSelectProductCategoriesKeyBy: null,
  fetchSelectProductCategoriesPage: 1,
  fetchSelectProductCategoriesPerPage: null,
  fetchSelectProductCategoriesIncludes: null,
  fetchSelectProductCategoriesFilterSectorId: null,
  fetchSelectProductCategoriesFilterMarketId: null,
  fetchSelectProductCategoriesFilterBranchId: null,
  fetchSelectProductCategoriesFilterProductCategoriesIds: null,
  fetchSelectProductCategoriesLoading: false,
  fetchSelectProductCategoriesSuccess: null,
  fetchSelectProductCategoriesFailed: null,

  fetchRelatedProductCategoriesSearch: null,
  fetchRelatedProductCategoriesSort: null,
  fetchRelatedProductCategoriesKeyBy: null,
  fetchRelatedProductCategoriesPage: 1,
  fetchRelatedProductCategoriesPerPage: null,
  fetchRelatedProductCategoriesIncludes: null,
  fetchRelatedProductCategoriesFilterSectorId: null,
  fetchRelatedProductCategoriesFilterMarketId: null,
  fetchRelatedProductCategoriesFilterBranchId: null,
  fetchRelatedProductCategoriesLoading: false,
  fetchRelatedProductCategoriesSuccess: null,
  fetchRelatedProductCategoriesFailed: null,

  appendSelectProductCategoriesSearch: null,
  appendSelectProductCategoriesSort: null,
  appendSelectProductCategoriesKeyBy: null,
  appendSelectProductCategoriesPage: 1,
  appendSelectProductCategoriesPerPage: null,
  appendSelectProductCategoriesIncludes: null,
  appendSelectProductCategoriesFilterSectorId: null,
  appendSelectProductCategoriesFilterMarketId: null,
  appendSelectProductCategoriesFilterBranchId: null,
  appendSelectProductCategoriesFilterProductCategoriesIds: null,
  appendSelectProductCategoriesLoading: false,
  appendSelectProductCategoriesSuccess: null,
  appendSelectProductCategoriesFailed: null,

  fetchProductCategoryLoading: false,
  fetchProductCategorySuccess: null,
  fetchProductCategoryFailed: null,

  sortProductCategoriesLoading: false,
  sortProductCategoriesSuccess: null,
  sortProductCategoriesFailed: null,

  createProductCategoryLoading: false,
  createProductCategorySuccess: null,
  createProductCategoryFailed: null,

  updateProductCategoryLoading: false,
  updateProductCategorySuccess: null,
  updateProductCategoryFailed: null,

  deleteProductCategoryLoading: false,
  deleteProductCategorySuccess: null,
  deleteProductCategoryFailed: null,

  isFetchProductCategoriesHitted: false,
  isFetchSelectProductCategoriesHitted: false,
  isFetchRelatedProductCategoriesHitted: false,
  isAppendSelectProductCategoriesHitted: false,
  isFetchProductCategoryHitted: false,
  isSortProductCategoriesHitted: false,
  isCreateProductCategoryHitted: false,
  isUpdateProductCategoryHitted: false,
  isDeleteProductCategoryHitted: false,
};

export const productCategoryReducer = (
  state = PRODUCT_CATEGORY_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORIES:
      return { ...state, productCategories: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_SELECT_PRODUCT_CATEGORIES:
      return { ...state, selectProductCategories: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_RELATED_PRODUCT_CATEGORIES:
      return { ...state, relatedProductCategories: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_PRODUCT_CATEGORY:
      return { ...state, productCategory: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_PRODUCT_CATEGORIES_HAS_MORE:
      return { ...state, isProductCategoriesHasMore: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_SELECT_PRODUCT_CATEGORIES_HAS_MORE:
      return { ...state, isSelectProductCategoriesHasMore: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_IS_RELATED_PRODUCT_CATEGORIES_HAS_MORE:
      return { ...state, isRelatedProductCategoriesHasMore: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SEARCH:
      return { ...state, fetchProductCategoriesSearch: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SORT:
      return { ...state, fetchProductCategoriesSort: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_KEY_BY:
      return { ...state, fetchProductCategoriesKeyBy: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PAGE:
      return { ...state, fetchProductCategoriesPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_PER_PAGE:
      return { ...state, fetchProductCategoriesPerPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_INCLUDES:
      return { ...state, fetchProductCategoriesIncludes: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
      return { ...state, fetchProductCategoriesFilterSectorId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
      return { ...state, fetchProductCategoriesFilterMarketId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
      return { ...state, fetchProductCategoriesFilterBranchId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_LOADING:
      return { ...state, fetchProductCategoriesLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, fetchProductCategoriesSuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORIES_FAILED:
      return { ...state, fetchProductCategoriesFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_SEARCH:
      return { ...state, fetchSelectProductCategoriesSearch: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_SORT:
      return { ...state, fetchSelectProductCategoriesSort: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_KEY_BY:
      return { ...state, fetchSelectProductCategoriesKeyBy: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_PAGE:
      return { ...state, fetchSelectProductCategoriesPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_PER_PAGE:
      return { ...state, fetchSelectProductCategoriesPerPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_INCLUDES:
      return { ...state, fetchSelectProductCategoriesIncludes: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
      return { ...state, fetchSelectProductCategoriesFilterSectorId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
      return { ...state, fetchSelectProductCategoriesFilterMarketId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
      return { ...state, fetchSelectProductCategoriesFilterBranchId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FILTER_PRODUCT_CATEGORIES_IDS:
      return {
        ...state,
        fetchSelectProductCategoriesFilterProductCategoriesIds: payload,
      };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_LOADING:
      return { ...state, fetchSelectProductCategoriesLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, fetchSelectProductCategoriesSuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_SELECT_PRODUCT_CATEGORIES_FAILED:
      return { ...state, fetchSelectProductCategoriesFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_SEARCH:
      return { ...state, fetchRelatedProductCategoriesSearch: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_SORT:
      return { ...state, fetchRelatedProductCategoriesSort: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_KEY_BY:
      return { ...state, fetchRelatedProductCategoriesKeyBy: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_PAGE:
      return { ...state, fetchRelatedProductCategoriesPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_PER_PAGE:
      return { ...state, fetchRelatedProductCategoriesPerPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_INCLUDES:
      return { ...state, fetchRelatedProductCategoriesIncludes: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
      return { ...state, fetchRelatedProductCategoriesFilterSectorId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
      return { ...state, fetchRelatedProductCategoriesFilterMarketId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
      return { ...state, fetchRelatedProductCategoriesFilterBranchId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_LOADING:
      return { ...state, fetchRelatedProductCategoriesLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, fetchRelatedProductCategoriesSuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_RELATED_PRODUCT_CATEGORIES_FAILED:
      return { ...state, fetchRelatedProductCategoriesFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_SEARCH:
      return { ...state, appendSelectProductCategoriesSearch: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_SORT:
      return { ...state, appendSelectProductCategoriesSort: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_KEY_BY:
      return { ...state, appendSelectProductCategoriesKeyBy: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_PAGE:
      return { ...state, appendSelectProductCategoriesPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_PER_PAGE:
      return { ...state, appendSelectProductCategoriesPerPage: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_INCLUDES:
      return { ...state, appendSelectProductCategoriesIncludes: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_SECTOR_ID:
      return { ...state, appendSelectProductCategoriesFilterSectorId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_MARKET_ID:
      return { ...state, appendSelectProductCategoriesFilterMarketId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_BRANCH_ID:
      return { ...state, appendSelectProductCategoriesFilterBranchId: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FILTER_PRODUCT_CATEGORIES_IDS:
      return {
        ...state,
        appendSelectProductCategoriesFilterProductCategoriesIds: payload,
      };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_LOADING:
      return { ...state, appendSelectProductCategoriesLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, appendSelectProductCategoriesSuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_APPEND_SELECT_PRODUCT_CATEGORIES_FAILED:
      return { ...state, appendSelectProductCategoriesFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_LOADING:
      return { ...state, fetchProductCategoryLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, fetchProductCategorySuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_FETCH_PRODUCT_CATEGORY_FAILED:
      return { ...state, fetchProductCategoryFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_SORT_PRODUCT_CATEGORIES_LOADING:
      return { ...state, sortProductCategoriesLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_SORT_PRODUCT_CATEGORIES_SUCCESS:
      return { ...state, sortProductCategoriesSuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_SORT_PRODUCT_CATEGORIES_FAILED:
      return { ...state, sortProductCategoriesFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_CREATE_PRODUCT_CATEGORY_LOADING:
      return { ...state, createProductCategoryLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_CREATE_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, createProductCategorySuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_CREATE_PRODUCT_CATEGORY_FAILED:
      return { ...state, createProductCategoryFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_UPDATE_PRODUCT_CATEGORY_LOADING:
      return { ...state, updateProductCategoryLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_UPDATE_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, updateProductCategorySuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_UPDATE_PRODUCT_CATEGORY_FAILED:
      return { ...state, updateProductCategoryFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.SET_DELETE_PRODUCT_CATEGORY_LOADING:
      return { ...state, deleteProductCategoryLoading: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_DELETE_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, deleteProductCategorySuccess: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.SET_DELETE_PRODUCT_CATEGORY_FAILED:
      return { ...state, deleteProductCategoryFailed: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.APPEND_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: [...state.productCategories, ...payload],
      };
    case PRODUCT_CATEGORY_ACTION_TYPES.APPEND_SELECT_PRODUCT_CATEGORIES:
      return {
        ...state,
        selectProductCategories: {
          ...state.selectProductCategories,
          ...payload,
        },
      };
    case PRODUCT_CATEGORY_ACTION_TYPES.APPEND_RELATED_PRODUCT_CATEGORIES:
      return {
        ...state,
        relatedProductCategories: [
          ...state.relatedProductCategories,
          ...payload,
        ],
      };

    case PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isFetchProductCategoriesHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_SELECT_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isFetchSelectProductCategoriesHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_RELATED_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isFetchRelatedProductCategoriesHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_APPEND_SELECT_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isAppendSelectProductCategoriesHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_FETCH_PRODUCT_CATEGORY_HITTED:
      return { ...state, isFetchProductCategoryHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_SORT_PRODUCT_CATEGORIES_HITTED:
      return { ...state, isSortProductCategoriesHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_CREATE_PRODUCT_CATEGORY_HITTED:
      return { ...state, isCreateProductCategoryHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_UPDATE_PRODUCT_CATEGORY_HITTED:
      return { ...state, isUpdateProductCategoryHitted: payload };
    case PRODUCT_CATEGORY_ACTION_TYPES.IS_DELETE_PRODUCT_CATEGORY_HITTED:
      return { ...state, isDeleteProductCategoryHitted: payload };

    case PRODUCT_CATEGORY_ACTION_TYPES.RESET_PRODUCT_CATEGORY_REDUCER:
      return PRODUCT_CATEGORY_INITIAL_STATE;
    default:
      return state;
  }
};
