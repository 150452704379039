const MARKET_ACTION_TYPES = {
  RESET_MARKET_REDUCER: "market/RESET_MARKET_REDUCER",

  SET_MARKET_TABLE_COLUMNS: "market/SET_MARKET_TABLE_COLUMNS",
  SET_ADMIN_MARKET_TABLE_COLUMNS: "market/SET_ADMIN_MARKET_TABLE_COLUMNS",
  SET_MERCHANT_MARKET_TABLE_COLUMNS: "market/SET_MERCHANT_MARKET_TABLE_COLUMNS",

  SET_IS_MODAL_AUTO_CLOSE: "market/SET_IS_MODAL_AUTO_CLOSE",
  SET_MARKETS: "market/SET_MARKETS",
  SET_SELECT_MARKETS: "market/SET_SELECT_MARKETS",
  SET_SUBSCRIPTION_MARKETS: "market/SET_SUBSCRIPTION_MARKETS",
  SET_MARKET: "market/SET_MARKET",
  SET_SUBSCRIPTION_MARKET: "market/SET_SUBSCRIPTION_MARKET",

  SET_IS_MARKETS_HAS_MORE: "market/SET_IS_MARKETS_HAS_MORE",
  SET_IS_SELECT_MARKETS_HAS_MORE: "market/SET_IS_SELECT_MARKETS_HAS_MORE",
  SET_IS_SUBSCRIPTION_MARKETS_HAS_MORE: "market/SET_IS_SUBSCRIPTION_MARKETS_HAS_MORE",

  SET_FETCH_MARKETS_SEARCH: "market/SET_FETCH_MARKETS_SEARCH",
  SET_FETCH_MARKETS_SORT: "market/SET_FETCH_MARKETS_SORT",
  SET_FETCH_MARKETS_KEY_BY: "market/SET_FETCH_MARKETS_KEY_BY",
  SET_FETCH_MARKETS_PAGE: "market/SET_FETCH_MARKETS_PAGE",
  SET_FETCH_MARKETS_PER_PAGE: "market/SET_FETCH_MARKETS_PER_PAGE",
  SET_FETCH_MARKETS_INCLUDES: "market/SET_FETCH_MARKETS_INCLUDES",
  SET_FETCH_MARKETS_FILTER_MARKETS_IDS: "market/SET_FETCH_MARKETS_FILTER_MARKETS_IDS",
  SET_FETCH_MARKETS_FILTER_MERCHANT_ID: "market/SET_FETCH_MARKETS_FILTER_MERCHANT_ID",
  SET_FETCH_MARKETS_FILTER_RESELLER_ID: "market/SET_FETCH_MARKETS_FILTER_RESELLER_ID",
  SET_FETCH_MARKETS_FILTER_SECTOR_ID: "market/SET_FETCH_MARKETS_FILTER_SECTOR_ID",
  SET_FETCH_MARKETS_FILTER_CREATED_BY: "market/SET_FETCH_MARKETS_FILTER_CREATED_BY",
  SET_FETCH_MARKETS_LOADING: "market/SET_FETCH_MARKETS_LOADING",
  SET_FETCH_MARKETS_SUCCESS: "market/SET_FETCH_MARKETS_SUCCESS",
  SET_FETCH_MARKETS_FAILED: "market/SET_FETCH_MARKETS_FAILED",

  SET_FETCH_SELECT_MARKETS_SEARCH: "market/SET_FETCH_SELECT_MARKETS_SEARCH",
  SET_FETCH_SELECT_MARKETS_SORT: "market/SET_FETCH_SELECT_MARKETS_SORT",
  SET_FETCH_SELECT_MARKETS_KEY_BY: "market/SET_FETCH_SELECT_MARKETS_KEY_BY",
  SET_FETCH_SELECT_MARKETS_PAGE: "market/SET_FETCH_SELECT_MARKETS_PAGE",
  SET_FETCH_SELECT_MARKETS_PER_PAGE: "market/SET_FETCH_SELECT_MARKETS_PER_PAGE",
  SET_FETCH_SELECT_MARKETS_INCLUDES: "market/SET_FETCH_SELECT_MARKETS_INCLUDES",
  SET_FETCH_SELECT_MARKETS_FILTER_MARKETS_IDS: "market/SET_FETCH_SELECT_MARKETS_FILTER_MARKETS_IDS",
  SET_FETCH_SELECT_MARKETS_FILTER_SECTOR_ID: "market/SET_FETCH_SELECT_MARKETS_FILTER_SECTOR_ID",
  SET_FETCH_SELECT_MARKETS_FILTER_MERCHANT_ID: "market/SET_FETCH_SELECT_MARKETS_FILTER_MERCHANT_ID",
  SET_FETCH_SELECT_MARKETS_FILTER_RESELLER_ID: "market/SET_FETCH_SELECT_MARKETS_FILTER_RESELLER_ID",
  SET_FETCH_SELECT_MARKETS_FILTER_CREATED_BY: "market/SET_FETCH_SELECT_MARKETS_FILTER_CREATED_BY",
  SET_FETCH_SELECT_MARKETS_LOADING: "market/SET_FETCH_SELECT_MARKETS_LOADING",
  SET_FETCH_SELECT_MARKETS_SUCCESS: "market/SET_FETCH_SELECT_MARKETS_SUCCESS",
  SET_FETCH_SELECT_MARKETS_FAILED: "market/SET_FETCH_SELECT_MARKETS_FAILED",

  SET_APPEND_SELECT_MARKETS_SEARCH: "market/SET_APPEND_SELECT_MARKETS_SEARCH",
  SET_APPEND_SELECT_MARKETS_SORT: "market/SET_APPEND_SELECT_MARKETS_SORT",
  SET_APPEND_SELECT_MARKETS_KEY_BY: "market/SET_APPEND_SELECT_MARKETS_KEY_BY",
  SET_APPEND_SELECT_MARKETS_PAGE: "market/SET_APPEND_SELECT_MARKETS_PAGE",
  SET_APPEND_SELECT_MARKETS_PER_PAGE: "market/SET_APPEND_SELECT_MARKETS_PER_PAGE",
  SET_APPEND_SELECT_MARKETS_INCLUDES: "market/SET_APPEND_SELECT_MARKETS_INCLUDES",
  SET_APPEND_SELECT_MARKETS_FILTER_MARKETS_IDS: "market/SET_APPEND_SELECT_MARKETS_FILTER_MARKETS_IDS",
  SET_APPEND_SELECT_MARKETS_FILTER_SECTOR_ID: "market/SET_APPEND_SELECT_MARKETS_FILTER_SECTOR_ID",
  SET_APPEND_SELECT_MARKETS_FILTER_MERCHANT_ID: "market/SET_APPEND_SELECT_MARKETS_FILTER_MERCHANT_ID",
  SET_APPEND_SELECT_MARKETS_FILTER_RESELLER_ID: "market/SET_APPEND_SELECT_MARKETS_FILTER_RESELLER_ID",
  SET_APPEND_SELECT_MARKETS_FILTER_CREATED_BY: "market/SET_APPEND_SELECT_MARKETS_FILTER_CREATED_BY",
  SET_APPEND_SELECT_MARKETS_LOADING: "market/SET_APPEND_SELECT_MARKETS_LOADING",
  SET_APPEND_SELECT_MARKETS_SUCCESS: "market/SET_APPEND_SELECT_MARKETS_SUCCESS",
  SET_APPEND_SELECT_MARKETS_FAILED: "market/SET_APPEND_SELECT_MARKETS_FAILED",

  SET_FETCH_SUBSCRIPTION_MARKETS_SEARCH: "market/SET_FETCH_SUBSCRIPTION_MARKETS_SEARCH",
  SET_FETCH_SUBSCRIPTION_MARKETS_SORT: "market/SET_FETCH_SUBSCRIPTION_MARKETS_SORT",
  SET_FETCH_SUBSCRIPTION_MARKETS_KEY_BY: "market/SET_FETCH_SUBSCRIPTION_MARKETS_KEY_BY",
  SET_FETCH_SUBSCRIPTION_MARKETS_PAGE: "market/SET_FETCH_SUBSCRIPTION_MARKETS_PAGE",
  SET_FETCH_SUBSCRIPTION_MARKETS_PER_PAGE: "market/SET_FETCH_SUBSCRIPTION_MARKETS_PER_PAGE",
  SET_FETCH_SUBSCRIPTION_MARKETS_INCLUDES: "market/SET_FETCH_SUBSCRIPTION_MARKETS_INCLUDES",
  SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_MARKETS_IDS: "market/SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_MARKETS_IDS",
  SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_SECTOR_ID: "market/SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_SECTOR_ID",
  SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_MERCHANT_ID: "market/SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_MERCHANT_ID",
  SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_RESELLER_ID: "market/SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_RESELLER_ID",
  SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_CREATED_BY: "market/SET_FETCH_SUBSCRIPTION_MARKETS_FILTER_CREATED_BY",
  SET_FETCH_SUBSCRIPTION_MARKETS_LOADING: "market/SET_FETCH_SUBSCRIPTION_MARKETS_LOADING",
  SET_FETCH_SUBSCRIPTION_MARKETS_SUCCESS: "market/SET_FETCH_SUBSCRIPTION_MARKETS_SUCCESS",
  SET_FETCH_SUBSCRIPTION_MARKETS_FAILED: "market/SET_FETCH_SUBSCRIPTION_MARKETS_FAILED",

  SET_FETCH_MARKET_LOADING: "market/SET_FETCH_MARKET_LOADING",
  SET_FETCH_MARKET_SUCCESS: "market/SET_FETCH_MARKET_SUCCESS",
  SET_FETCH_MARKET_FAILED: "market/SET_FETCH_MARKET_FAILED",

  SET_CREATE_MARKET_LOADING: "market/SET_CREATE_MARKET_LOADING",
  SET_CREATE_MARKET_SUCCESS: "market/SET_CREATE_MARKET_SUCCESS",
  SET_CREATE_MARKET_FAILED: "market/SET_CREATE_MARKET_FAILED",

  SET_UPDATE_MARKET_LOADING: "market/SET_UPDATE_MARKET_LOADING",
  SET_UPDATE_MARKET_SUCCESS: "market/SET_UPDATE_MARKET_SUCCESS",
  SET_UPDATE_MARKET_FAILED: "market/SET_UPDATE_MARKET_FAILED",

  SET_DELETE_MARKET_LOADING: "market/SET_DELETE_MARKET_LOADING",
  SET_DELETE_MARKET_SUCCESS: "market/SET_DELETE_MARKET_SUCCESS",
  SET_DELETE_MARKET_FAILED: "market/SET_DELETE_MARKET_FAILED",

  SET_SEND_VERIFICATION_MARKET_LOADING: "market/SET_SEND_VERIFICATION_MARKET_LOADING",
  SET_SEND_VERIFICATION_MARKET_SUCCESS: "market/SET_SEND_VERIFICATION_MARKET_SUCCESS",
  SET_SEND_VERIFICATION_MARKET_FAILED: "market/SET_SEND_VERIFICATION_MARKET_FAILED",

  SET_VERIFY_VERIFICATION_MARKET_LOADING: "market/SET_VERIFY_VERIFICATION_MARKET_LOADING",
  SET_VERIFY_VERIFICATION_MARKET_SUCCESS: "market/SET_VERIFY_VERIFICATION_MARKET_SUCCESS",
  SET_VERIFY_VERIFICATION_MARKET_FAILED: "market/SET_VERIFY_VERIFICATION_MARKET_FAILED",

  APPEND_MARKETS: "market/APPEND_MARKETS",
  APPEND_SELECT_MARKETS: "market/APPEND_SELECT_MARKETS",
  APPEND_SUBSCRIPTION_MARKETS: "market/APPEND_SUBSCRIPTION_MARKETS",

  SET_IS_FETCH_MARKETS_HITTED: "market/SET_IS_FETCH_MARKETS_HITTED",
  SET_IS_FETCH_SELECT_MARKETS_HITTED: "market/SET_IS_FETCH_SELECT_MARKETS_HITTED",
  SET_IS_FETCH_SUBSCRIPTION_MARKETS_HITTED: "market/SET_IS_FETCH_SUBSCRIPTION_MARKETS_HITTED",
  SET_IS_FETCH_MARKET_HITTED: "market/SET_IS_FETCH_MARKET_HITTED",
  SET_IS_CREATE_MARKET_HITTED: "market/SET_IS_CREATE_MARKET_HITTED",
  SET_IS_UPDATE_MARKET_HITTED: "market/SET_IS_UPDATE_MARKET_HITTED",
  SET_IS_DELETE_MARKET_HITTED: "market/SET_IS_DELETE_MARKET_HITTED",
  SET_IS_SEND_VERIFICATION_MARKET_HITTED: "market/SET_IS_SEND_VERIFICATION_MARKET_HITTED",
  SET_IS_VERIFY_VERIFICATION_MARKET_HITTED: "market/SET_IS_VERIFY_VERIFICATION_MARKET_HITTED",
  SET_IS_APPEND_SELECT_MARKETS_HITTED: "market/SET_IS_APPEND_SELECT_MARKETS_HITTED",

  FETCH_MARKETS_START: "market/FETCH_MARKETS_START",
  FETCH_SELECT_MARKETS_START: "market/FETCH_SELECT_MARKETS_START",
  FETCH_SUBSCRIPTION_MARKETS_START: "market/FETCH_SUBSCRIPTION_MARKETS_START",
  FETCH_MARKET_START: "market/FETCH_MARKET_START",
  CREATE_MARKET_START: "market/CREATE_MARKET_START",
  UPDATE_MARKET_START: "market/UPDATE_MARKET_START",
  DELETE_MARKET_START: "market/DELETE_MARKET_START",
  SEND_VERIFICATION_MARKET_START: "market/SEND_VERIFICATION_MARKET_START",
  VERIFY_VERIFICATION_MARKET_START: "market/VERIFY_VERIFICATION_MARKET_START",
  APPEND_SELECT_MARKETS_START: "market/APPEND_SELECT_MARKETS_START",
};

export default MARKET_ACTION_TYPES;
