import { useTranslation } from "react-i18next";

import { LANGUAGES } from "../../constants/common.constant";

import { SpinnerContainer } from "./spinner.style";

export const SPINNER_COLORS = {
  PRIMARY: "PRIMARY",
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  WARNING: "WARNING",
  SECONDARY: "SECONDARY",
  WHITE: "WHITE",
  LIGHT: "LIGHT",
  DARK: "DARK",
  DARK_SECONDARY: "DARK_SECONDARY",
};

export const SPINNER_SIZES = {
  SMALL: "SMALL",
  NORMAL: "NORMAL",
  LARGE: "LARGE",
};

const Spinner = ({
  spinnerColor = SPINNER_COLORS.DARK,
  spinnerSize = SPINNER_SIZES.NORMAL,
  marginLeft,
  marginRight,
  ...otherProps
}) => {
  const { i18n } = useTranslation();

  otherProps.isRtl = i18n.language === LANGUAGES.AR;
  otherProps.spinnerColor = spinnerColor;
  otherProps.spinnerSize = spinnerSize;
  otherProps.marginLeft = marginLeft;
  otherProps.marginRight = marginRight;

  return <SpinnerContainer {...otherProps} />;
};

export default Spinner;
