import EXTEND_PERIOD_ACTION_TYPES from "./extend-period.type";

export const EXTEND_PERIOD_INITIAL_STATE = {
  extendPeriods: [],
  extendPeriod: null,
  createExtendPeriod: null,
  customExtendPeriod: null,

  isExtendPeriodsHasMore: true,

  fetchExtendPeriodsSearch: null,
  fetchExtendPeriodsSort: null,
  fetchExtendPeriodsKeyBy: null,
  fetchExtendPeriodsPage: 1,
  fetchExtendPeriodsPerPage: null,
  fetchExtendPeriodsIncludes: null,
  fetchExtendPeriodsFilterMarketId: null,
  fetchExtendPeriodsFilterBranchId: null,
  fetchExtendPeriodsFilterStatus: null,
  fetchExtendPeriodsFilterStartAtValue: null,
  fetchExtendPeriodsFilterStartAtOperator: null,
  fetchExtendPeriodsFilterEndAtValue: null,
  fetchExtendPeriodsFilterEndAtOperator: null,
  fetchExtendPeriodsFilterCreatedBy: null,
  fetchExtendPeriodsLoading: false,
  fetchExtendPeriodsSuccess: null,
  fetchExtendPeriodsFailed: null,

  fetchExtendPeriodLoading: false,
  fetchExtendPeriodSuccess: null,
  fetchExtendPeriodFailed: null,

  createExtendPeriodLoading: false,
  createExtendPeriodSuccess: null,
  createExtendPeriodFailed: null,

  customExtendPeriodLoading: false,
  customExtendPeriodSuccess: null,
  customExtendPeriodFailed: null,

  stopExtendPeriodLoading: false,
  stopExtendPeriodSuccess: null,
  stopExtendPeriodFailed: null,

  isFetchExtendPeriodsHitted: false,
  isFetchExtendPeriodHitted: false,
  isCreateExtendPeriodHitted: false,
  isCustomExtendPeriodHitted: false,
  isStopExtendPeriodHitted: false,
};

export const extendPeriodReducer = (
  state = EXTEND_PERIOD_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case EXTEND_PERIOD_ACTION_TYPES.SET_EXTEND_PERIODS:
      return { ...state, extendPeriods: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_EXTEND_PERIOD:
      return { ...state, extendPeriod: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_CREATE_EXTEND_PERIOD:
      return { ...state, createExtendPeriod: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_CUSTOM_EXTEND_PERIOD:
      return { ...state, customExtendPeriod: payload };

    case EXTEND_PERIOD_ACTION_TYPES.SET_IS_EXTEND_PERIODS_HAS_MORE:
      return { ...state, isExtendPeriodsHasMore: payload };

    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_SEARCH:
      return { ...state, fetchExtendPeriodsSearch: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_SORT:
      return { ...state, fetchExtendPeriodsSort: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_KEY_BY:
      return { ...state, fetchExtendPeriodsKeyBy: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_PAGE:
      return { ...state, fetchExtendPeriodsPage: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_PER_PAGE:
      return { ...state, fetchExtendPeriodsPerPage: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_INCLUDES:
      return { ...state, fetchExtendPeriodsIncludes: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_MARKET_ID:
      return { ...state, fetchExtendPeriodsFilterMarketId: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_BRANCH_ID:
      return { ...state, fetchExtendPeriodsFilterBranchId: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_STATUS:
      return { ...state, fetchExtendPeriodsFilterStatus: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_START_AT_VALUE:
      return { ...state, fetchExtendPeriodsFilterStartAtValue: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_START_AT_OPERATOR:
      return { ...state, fetchExtendPeriodsFilterStartAtOperator: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_END_AT_VALUE:
      return { ...state, fetchExtendPeriodsFilterEndAtValue: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_END_AT_OPERATOR:
      return { ...state, fetchExtendPeriodsFilterEndAtOperator: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_CREATED_BY:
      return { ...state, fetchExtendPeriodsFilterCreatedBy: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_LOADING:
      return { ...state, fetchExtendPeriodsLoading: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_SUCCESS:
      return { ...state, fetchExtendPeriodsSuccess: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FAILED:
      return { ...state, fetchExtendPeriodsFailed: payload };

    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIOD_LOADING:
      return { ...state, fetchExtendPeriodLoading: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIOD_SUCCESS:
      return { ...state, fetchExtendPeriodSuccess: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIOD_FAILED:
      return { ...state, fetchExtendPeriodFailed: payload };

    case EXTEND_PERIOD_ACTION_TYPES.SET_CREATE_EXTEND_PERIOD_LOADING:
      return { ...state, createExtendPeriodLoading: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_CREATE_EXTEND_PERIOD_SUCCESS:
      return { ...state, createExtendPeriodSuccess: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_CREATE_EXTEND_PERIOD_FAILED:
      return { ...state, createExtendPeriodFailed: payload };

    case EXTEND_PERIOD_ACTION_TYPES.SET_CUSTOM_EXTEND_PERIOD_LOADING:
      return { ...state, customExtendPeriodLoading: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_CUSTOM_EXTEND_PERIOD_SUCCESS:
      return { ...state, customExtendPeriodSuccess: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_CUSTOM_EXTEND_PERIOD_FAILED:
      return { ...state, customExtendPeriodFailed: payload };

    case EXTEND_PERIOD_ACTION_TYPES.SET_STOP_EXTEND_PERIOD_LOADING:
      return { ...state, stopExtendPeriodLoading: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_STOP_EXTEND_PERIOD_SUCCESS:
      return { ...state, stopExtendPeriodSuccess: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_STOP_EXTEND_PERIOD_FAILED:
      return { ...state, stopExtendPeriodFailed: payload };

    case EXTEND_PERIOD_ACTION_TYPES.APPEND_EXTEND_PERIODS:
      return { ...state, extendPeriods: [...state.extendPeriods, ...payload] };

    case EXTEND_PERIOD_ACTION_TYPES.SET_IS_FETCH_EXTEND_PERIODS_HITTED:
      return { ...state, isFetchExtendPeriodsHitted: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_IS_FETCH_EXTEND_PERIOD_HITTED:
      return { ...state, isFetchExtendPeriodHitted: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_IS_CREATE_EXTEND_PERIOD_HITTED:
      return { ...state, isCreateExtendPeriodHitted: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_IS_CUSTOM_EXTEND_PERIOD_HITTED:
      return { ...state, isCustomExtendPeriodHitted: payload };
    case EXTEND_PERIOD_ACTION_TYPES.SET_IS_STOP_EXTEND_PERIOD_HITTED:
      return { ...state, isStopExtendPeriodHitted: payload };

    case EXTEND_PERIOD_ACTION_TYPES.RESET_EXTEND_PERIOD_REDUCER:
      return EXTEND_PERIOD_INITIAL_STATE;
    default:
      return state;
  }
};
