import BRANCH_ACTION_TYPES from "./branch.type";

export const BRANCH_INITIAL_STATE = {
  isModalAutoClose: true,

  branches: [],
  selectBranches: [],
  activeBranches: [],
  branch: null,
  selectBranch: null,
  activeBranch: null,
  subscriptionBranch: null,

  isBranchesHasMore: true,
  isSelectBranchesHasMore: true,
  isActiveBranchesHasMore: true,

  fetchBranchesSearch: null,
  fetchBranchesPage: 1,
  fetchBranchesPerPage: null,
  fetchBranchesIncludes: null,
  fetchBranchesFilterMarketId: null,
  fetchBranchesFilterIsActive: null,
  fetchBranchesLoading: false,
  fetchBranchesSuccess: null,
  fetchBranchesFailed: null,

  fetchSelectBranchesPage: 1,
  fetchSelectBranchesPerPage: null,
  fetchSelectBranchesIncludes: null,
  fetchSelectBranchesFilterMarketId: null,
  fetchSelectBranchesFilterIsActive: null,
  fetchSelectBranchesLoading: false,
  fetchSelectBranchesSuccess: null,
  fetchSelectBranchesFailed: null,

  fetchActiveBranchesPage: 1,
  fetchActiveBranchesPerPage: null,
  fetchActiveBranchesIncludes: null,
  fetchActiveBranchesFilterMarketId: null,
  fetchActiveBranchesFilterIsActive: null,
  fetchActiveBranchesLoading: false,
  fetchActiveBranchesSuccess: null,
  fetchActiveBranchesFailed: null,

  fetchBranchLoading: false,
  fetchBranchSuccess: null,
  fetchBranchFailed: null,

  fetchSelectBranchLoading: false,
  fetchSelectBranchSuccess: null,
  fetchSelectBranchFailed: null,

  fetchActiveBranchLoading: false,
  fetchActiveBranchSuccess: null,
  fetchActiveBranchFailed: null,

  createBranchLoading: false,
  createBranchSuccess: null,
  createBranchFailed: null,

  updateBranchLoading: false,
  updateBranchSuccess: null,
  updateBranchFailed: null,

  deleteBranchLoading: false,
  deleteBranchSuccess: null,
  deleteBranchFailed: null,

  isFetchBranchesHitted: false,
  isFetchSelectBranchesHitted: false,
  isFetchActiveBranchesHitted: false,
  isFetchBranchHitted: false,
  isFetchSelectBranchHitted: false,
  isFetchActiveBranchHitted: false,
  isCreateBranchHitted: false,
  isUpdateBranchHitted: false,
  isDeleteBranchHitted: false,
};

export const branchReducer = (state = BRANCH_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case BRANCH_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE:
      return { ...state, isModalAutoClose: payload };

    case BRANCH_ACTION_TYPES.SET_BRANCHES:
      return { ...state, branches: payload };
    case BRANCH_ACTION_TYPES.SET_SELECT_BRANCHES:
      return { ...state, selectBranches: payload };
    case BRANCH_ACTION_TYPES.SET_ACTIVE_BRANCHES:
      return { ...state, activeBranches: payload };
    case BRANCH_ACTION_TYPES.SET_BRANCH:
      return { ...state, branch: payload };
    case BRANCH_ACTION_TYPES.SET_SELECT_BRANCH:
      return { ...state, selectBranch: payload };
    case BRANCH_ACTION_TYPES.SET_ACTIVE_BRANCH:
      return { ...state, activeBranch: payload };
    case BRANCH_ACTION_TYPES.SET_SUBSCRIPTION_BRANCH:
      return { ...state, subscriptionBranch: payload };

    case BRANCH_ACTION_TYPES.SET_IS_BRANCHES_HAS_MORE:
      return { ...state, isBranchesHasMore: payload };
    case BRANCH_ACTION_TYPES.SET_IS_SELECT_BRANCHES_HAS_MORE:
      return { ...state, isSelectBranchesHasMore: payload };
    case BRANCH_ACTION_TYPES.SET_IS_ACTIVE_BRANCHES_HAS_MORE:
      return { ...state, isActiveBranchesHasMore: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SEARCH:
      return { ...state, fetchBranchesSearch: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PAGE:
      return { ...state, fetchBranchesPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_PER_PAGE:
      return { ...state, fetchBranchesPerPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_INCLUDES:
      return { ...state, fetchBranchesIncludes: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_MARKET_ID:
      return { ...state, fetchBranchesFilterMarketId: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FILTER_IS_ACTIVE:
      return { ...state, fetchBranchesFilterIsActive: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_LOADING:
      return { ...state, fetchBranchesLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_SUCCESS:
      return { ...state, fetchBranchesSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCHES_FAILED:
      return { ...state, fetchBranchesFailed: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_PAGE:
      return { ...state, fetchSelectBranchesPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_PER_PAGE:
      return { ...state, fetchSelectBranchesPerPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_INCLUDES:
      return { ...state, fetchSelectBranchesIncludes: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_FILTER_MARKET_ID:
      return { ...state, fetchSelectBranchesFilterMarketId: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_FILTER_IS_ACTIVE:
      return { ...state, fetchSelectBranchesFilterIsActive: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_LOADING:
      return { ...state, fetchSelectBranchesLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_SUCCESS:
      return { ...state, fetchSelectBranchesSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCHES_FAILED:
      return { ...state, fetchSelectBranchesFailed: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_PAGE:
      return { ...state, fetchActiveBranchesPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_PER_PAGE:
      return { ...state, fetchActiveBranchesPerPage: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_INCLUDES:
      return { ...state, fetchActiveBranchesIncludes: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_FILTER_MARKET_ID:
      return { ...state, fetchActiveBranchesFilterMarketId: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_FILTER_IS_ACTIVE:
      return { ...state, fetchActiveBranchesFilterIsActive: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_LOADING:
      return { ...state, fetchActiveBranchesLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_SUCCESS:
      return { ...state, fetchActiveBranchesSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCHES_FAILED:
      return { ...state, fetchActiveBranchesFailed: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_LOADING:
      return { ...state, fetchBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_SUCCESS:
      return { ...state, fetchBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_BRANCH_FAILED:
      return { ...state, fetchBranchFailed: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCH_LOADING:
      return { ...state, fetchSelectBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCH_SUCCESS:
      return { ...state, fetchSelectBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_SELECT_BRANCH_FAILED:
      return { ...state, fetchSelectBranchFailed: payload };

    case BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCH_LOADING:
      return { ...state, fetchActiveBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCH_SUCCESS:
      return { ...state, fetchActiveBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_FETCH_ACTIVE_BRANCH_FAILED:
      return { ...state, fetchActiveBranchFailed: payload };

    case BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_LOADING:
      return { ...state, createBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_SUCCESS:
      return { ...state, createBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_CREATE_BRANCH_FAILED:
      return { ...state, createBranchFailed: payload };

    case BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_LOADING:
      return { ...state, updateBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_SUCCESS:
      return { ...state, updateBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_UPDATE_BRANCH_FAILED:
      return { ...state, updateBranchFailed: payload };

    case BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_LOADING:
      return { ...state, deleteBranchLoading: payload };
    case BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_SUCCESS:
      return { ...state, deleteBranchSuccess: payload };
    case BRANCH_ACTION_TYPES.SET_DELETE_BRANCH_FAILED:
      return { ...state, deleteBranchFailed: payload };

    case BRANCH_ACTION_TYPES.APPEND_BRANCHES:
      return { ...state, branches: [...state.branches, ...payload] };
    case BRANCH_ACTION_TYPES.APPEND_SELECT_BRANCHES:
      return { ...state, selectBranches: [...state.selectBranches, ...payload] };
    case BRANCH_ACTION_TYPES.APPEND_ACTIVE_BRANCHES:
      return { ...state, activeBranches: [...state.activeBranches, ...payload] };

    case BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCHES_HITTED:
      return { ...state, isFetchBranchesHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_FETCH_SELECT_BRANCHES_HITTED:
      return { ...state, isFetchSelectBranchesHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_FETCH_ACTIVE_BRANCHES_HITTED:
      return { ...state, isFetchActiveBranchesHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_FETCH_BRANCH_HITTED:
      return { ...state, isFetchBranchHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_FETCH_SELECT_BRANCH_HITTED:
      return { ...state, isFetchSelectBranchHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_FETCH_ACTIVE_BRANCH_HITTED:
      return { ...state, isFetchActiveBranchHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_CREATE_BRANCH_HITTED:
      return { ...state, isCreateBranchHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_UPDATE_BRANCH_HITTED:
      return { ...state, isUpdateBranchHitted: payload };
    case BRANCH_ACTION_TYPES.SET_IS_DELETE_BRANCH_HITTED:
      return { ...state, isDeleteBranchHitted: payload };

    case BRANCH_ACTION_TYPES.RESET_BRANCH_REDUCER:
      return BRANCH_INITIAL_STATE;
    default:
      return state;
  }
};
