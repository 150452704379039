export const PERMISISON_ADMIN_TYPES = {
  ADMIN: "ADMIN",
  SUBSCRIBER: "SUBSCRIBER",
};

export const PERMISSION_ADMIN_PAGES = {
  PAGE_ACCOUNT: "ACCOUNT",
  PAGE_APPLICATION: "APPLICATION",
  PAGE_COUNTRY: "COUNTRY",
  PAGE_DASHBOARD: "DASHBOARD",
  PAGE_MERCHANT: "MERCHANT",
  PAGE_PAYMENT_METHOD: "PAYMENT_METHOD",
  PAGE_PERMISSION: "PERMISSION",
  PAGE_PRINTER_TYPE: "PRINTER_TYPE",
  PAGE_PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
  PAGE_REPORT: "REPORT",
  PAGE_SECTOR: "SECTOR",
  PAGE_SMS: "SMS",
  PAGE_SUBSCRIBER: "SUBSCRIBER",
  PAGE_SUBSCRIPTION: "SUBSCRIPTION",
  PAGE_TERM_CONDITION: "TERM_CONDITION",
};

export const PERMISISON_ADMIN_OTHERS = {};
