import { createSelector } from "reselect";

const subscriptionSelector = ({ subscription }) => subscription;

export const getIsModalAutoClose = createSelector([subscriptionSelector], ({ isModalAutoClose }) => isModalAutoClose);

export const getSubscriptions = createSelector([subscriptionSelector], ({ subscriptions }) => subscriptions);

export const getSubscription = createSelector([subscriptionSelector], ({ subscription }) => subscription);

export const getExtendSubscription = createSelector(
  [subscriptionSelector],
  ({ extendSubscription }) => extendSubscription
);

export const getExtendSubscriptionKey = createSelector(
  [subscriptionSelector],
  ({ extendSubscriptionKey }) => extendSubscriptionKey
);

export const getExtendSubscriptionOptionKey = createSelector(
  [subscriptionSelector],
  ({ extendSubscriptionOptionKey }) => extendSubscriptionOptionKey
);

export const getIsSubscriptionsHasMore = createSelector(
  [subscriptionSelector],
  ({ isSubscriptionsHasMore }) => isSubscriptionsHasMore
);

export const getFetchSubscriptionsSearch = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionsSearch }) => fetchSubscriptionsSearch
);

export const getFetchSubscriptionsPage = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionsPage }) => fetchSubscriptionsPage
);

export const getFetchSubscriptionsPerPage = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionsPerPage }) => fetchSubscriptionsPerPage
);

export const getFetchSubscriptionsLoading = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionsLoading }) => fetchSubscriptionsLoading
);

export const getFetchSubscriptionsSuccess = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionsSuccess }) => fetchSubscriptionsSuccess
);

export const getFetchSubscriptionsFailed = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionsFailed }) => fetchSubscriptionsFailed
);

export const getFetchSubscriptionLoading = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionLoading }) => fetchSubscriptionLoading
);

export const getFetchSubscriptionSuccess = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionSuccess }) => fetchSubscriptionSuccess
);

export const getFetchSubscriptionFailed = createSelector(
  [subscriptionSelector],
  ({ fetchSubscriptionFailed }) => fetchSubscriptionFailed
);

export const getCreateSubscriptionLoading = createSelector(
  [subscriptionSelector],
  ({ createSubscriptionLoading }) => createSubscriptionLoading
);

export const getCreateSubscriptionSuccess = createSelector(
  [subscriptionSelector],
  ({ createSubscriptionSuccess }) => createSubscriptionSuccess
);

export const getCreateSubscriptionFailed = createSelector(
  [subscriptionSelector],
  ({ createSubscriptionFailed }) => createSubscriptionFailed
);

export const getUpdateSubscriptionLoading = createSelector(
  [subscriptionSelector],
  ({ updateSubscriptionLoading }) => updateSubscriptionLoading
);

export const getUpdateSubscriptionSuccess = createSelector(
  [subscriptionSelector],
  ({ updateSubscriptionSuccess }) => updateSubscriptionSuccess
);

export const getUpdateSubscriptionFailed = createSelector(
  [subscriptionSelector],
  ({ updateSubscriptionFailed }) => updateSubscriptionFailed
);

export const getDeleteSubscriptionLoading = createSelector(
  [subscriptionSelector],
  ({ deleteSubscriptionLoading }) => deleteSubscriptionLoading
);

export const getDeleteSubscriptionSuccess = createSelector(
  [subscriptionSelector],
  ({ deleteSubscriptionSuccess }) => deleteSubscriptionSuccess
);

export const getDeleteSubscriptionFailed = createSelector(
  [subscriptionSelector],
  ({ deleteSubscriptionFailed }) => deleteSubscriptionFailed
);

export const getIsFetchSubscriptionsHitted = createSelector(
  [subscriptionSelector],
  ({ isFetchSubscriptionsHitted }) => isFetchSubscriptionsHitted
);

export const getIsFetchSubscriptionHitted = createSelector(
  [subscriptionSelector],
  ({ isFetchSubscriptionHitted }) => isFetchSubscriptionHitted
);

export const getIsCreateSubscriptionHitted = createSelector(
  [subscriptionSelector],
  ({ isCreateSubscriptionHitted }) => isCreateSubscriptionHitted
);

export const getIsUpdateSubscriptionHitted = createSelector(
  [subscriptionSelector],
  ({ isUpdateSubscriptionHitted }) => isUpdateSubscriptionHitted
);

export const getIsDeleteSubscriptionHitted = createSelector(
  [subscriptionSelector],
  ({ isDeleteSubscriptionHitted }) => isDeleteSubscriptionHitted
);
