const AUTHENTICATION_ACTION_TYPES = {
  RESET_AUTH_REDUCER: "authentication/RESET_AUTH_REDUCER",

  SET_ACTIVE_SUBSCRIPTIONS: "authentication/SET_ACTIVE_SUBSCRIPTIONS",
  SET_IS_HAS_ADVANCED_SUBSCRIPTION: "authentication/SET_IS_HAS_ADVANCED_SUBSCRIPTION",
  SET_USER: "authentication/SET_USER",

  SET_AUTH_CHECK_LOADING: "authentication/SET_AUTH_CHECK_LOADING",
  SET_AUTH_CHECK_SUCCESS: "authentication/SET_AUTH_CHECK_SUCCESS",
  SET_AUTH_CHECK_FAILED: "authentication/SET_AUTH_CHECK_FAILED",

  SET_FETCH_ME_LOADING: "authentication/SET_FETCH_ME_LOADING",
  SET_FETCH_ME_SUCCESS: "authentication/SET_FETCH_ME_SUCCESS",
  SET_FETCH_ME_FAILED: "authentication/SET_FETCH_ME_FAILED",

  SET_UPDATE_ME_LOADING: "authentication/SET_UPDATE_ME_LOADING",
  SET_UPDATE_ME_SUCCESS: "authentication/SET_UPDATE_ME_SUCCESS",
  SET_UPDATE_ME_FAILED: "authentication/SET_UPDATE_ME_FAILED",

  SET_SIGN_IN_LOADING: "authentication/SET_SIGN_IN_LOADING",
  SET_SIGN_IN_SUCCESS: "authentication/SET_SIGN_IN_SUCCESS",
  SET_SIGN_IN_FAILED: "authentication/SET_SIGN_IN_FAILED",

  SET_SIGN_UP_LOADING: "authentication/SET_SIGN_UP_LOADING",
  SET_SIGN_UP_SUCCESS: "authentication/SET_SIGN_UP_SUCCESS",
  SET_SIGN_UP_FAILED: "authentication/SET_SIGN_UP_FAILED",

  SET_SIGN_OUT_LOADING: "authentication/SET_SIGN_OUT_LOADING",
  SET_SIGN_OUT_SUCCESS: "authentication/SET_SIGN_OUT_SUCCESS",
  SET_SIGN_OUT_FAILED: "authentication/SET_SIGN_OUT_FAILED",

  SET_IS_AUTH_CHECK_HITTED: "authentication/SET_IS_AUTH_CHECK_HITTED",
  SET_IS_FETCH_ME_HITTED: "authentication/SET_IS_FETCH_ME_HITTED",
  SET_IS_UPDATE_ME_HITTED: "authentication/SET_IS_UPDATE_ME_HITTED",
  SET_IS_SIGN_IN_HITTED: "authentication/SET_IS_SIGN_IN_HITTED",
  SET_IS_SIGN_UP_HITTED: "authentication/SET_IS_SIGN_UP_HITTED",
  SET_IS_SIGN_OUT_HITTED: "authentication/SET_IS_SIGN_OUT_HITTED",

  AUTH_CHECK_START: "authentication/AUTH_CHECK_START",
  FETCH_ME_START: "authentication/FETCH_ME_START",
  UPDATE_ME_START: "authentication/UPDATE_ME_START",
  SIGN_IN_START: "authentication/SIGN_IN_START",
  SIGN_UP_START: "authentication/SIGN_UP_START",
  SIGN_OUT_START: "authentication/SIGN_OUT_START",
};

export default AUTHENTICATION_ACTION_TYPES;
