import apiService from "./api";

export const getCountries = async (parameters) => (await apiService.get("/v2/countries", parameters)).data;

export const getCountry = async (id) => (await apiService.get(`/v2/countries/${id}`)).data;

export const createCountry = async (request) => (await apiService.post("/v2/countries", request)).data;

export const updateCountry = async (id, request) => (await apiService.post(`/v2/countries/${id}`, request)).data;

export const deleteCountry = async (id) => (await apiService.delete(`/v2/countries/${id}`)).data;

export const syncRateCountry = async (id, request) => (await apiService.post(`/v2/countries/${id}/sync`, request)).data;
