import { createSelector } from "reselect";

const customerSelector = ({ customer }) => customer;

export const getCustomers = createSelector([customerSelector], ({ customers }) => customers);

export const getSelectCustomers = createSelector([customerSelector], ({ selectCustomers }) => selectCustomers);

export const getCustomer = createSelector([customerSelector], ({ customer }) => customer);

export const getFoundCustomer = createSelector([customerSelector], ({ foundCustomer }) => foundCustomer);

export const getCreateCustomer = createSelector([customerSelector], ({ createCustomer }) => createCustomer);

export const getUpdateCustomer = createSelector([customerSelector], ({ updateCustomer }) => updateCustomer);

export const getIsCustomersHasMore = createSelector([customerSelector], ({ isCustomersHasMore }) => isCustomersHasMore);

export const getIsSelectCustomersHasMore = createSelector(
  [customerSelector],
  ({ isSelectCustomersHasMore }) => isSelectCustomersHasMore
);

export const getFetchCustomersSearch = createSelector(
  [customerSelector],
  ({ fetchCustomersSearch }) => fetchCustomersSearch
);

export const getFetchCustomersPage = createSelector([customerSelector], ({ fetchCustomersPage }) => fetchCustomersPage);

export const getFetchCustomersPerPage = createSelector(
  [customerSelector],
  ({ fetchCustomersPerPage }) => fetchCustomersPerPage
);

export const getFetchCustomersIncludes = createSelector(
  [customerSelector],
  ({ fetchCustomersIncludes }) => fetchCustomersIncludes
);

export const getFetchCustomersFilterMarketId = createSelector(
  [customerSelector],
  ({ fetchCustomersFilterMarketId }) => fetchCustomersFilterMarketId
);

export const getFetchCustomersLoading = createSelector(
  [customerSelector],
  ({ fetchCustomersLoading }) => fetchCustomersLoading
);

export const getFetchCustomersSuccess = createSelector(
  [customerSelector],
  ({ fetchCustomersSuccess }) => fetchCustomersSuccess
);

export const getFetchCustomersFailed = createSelector(
  [customerSelector],
  ({ fetchCustomersFailed }) => fetchCustomersFailed
);

export const getFetchSelectCustomersSearch = createSelector(
  [customerSelector],
  ({ fetchSelectCustomersSearch }) => fetchSelectCustomersSearch
);

export const getFetchSelectCustomersPage = createSelector(
  [customerSelector],
  ({ fetchSelectCustomersPage }) => fetchSelectCustomersPage
);

export const getFetchSelectCustomersPerPage = createSelector(
  [customerSelector],
  ({ fetchSelectCustomersPerPage }) => fetchSelectCustomersPerPage
);

export const getFetchSelectCustomersIncludes = createSelector(
  [customerSelector],
  ({ fetchSelectCustomersIncludes }) => fetchSelectCustomersIncludes
);

export const getFetchSelectCustomersFilterMarketId = createSelector(
  [customerSelector],
  ({ fetchSelectCustomersFilterMarketId }) => fetchSelectCustomersFilterMarketId
);

export const getFetchSelectCustomersLoading = createSelector(
  [customerSelector],
  ({ fetchSelectCustomersLoading }) => fetchSelectCustomersLoading
);

export const getFetchSelectCustomersSuccess = createSelector(
  [customerSelector],
  ({ fetchSelectCustomersSuccess }) => fetchSelectCustomersSuccess
);

export const getFetchSelectCustomersFailed = createSelector(
  [customerSelector],
  ({ fetchSelectCustomersFailed }) => fetchSelectCustomersFailed
);

export const getFetchCustomerLoading = createSelector(
  [customerSelector],
  ({ fetchCustomerLoading }) => fetchCustomerLoading
);

export const getFetchCustomerSuccess = createSelector(
  [customerSelector],
  ({ fetchCustomerSuccess }) => fetchCustomerSuccess
);

export const getFetchCustomerFailed = createSelector(
  [customerSelector],
  ({ fetchCustomerFailed }) => fetchCustomerFailed
);

export const getFetchFindCustomerLoading = createSelector(
  [customerSelector],
  ({ fetchFindCustomerLoading }) => fetchFindCustomerLoading
);

export const getFetchFindCustomerSuccess = createSelector(
  [customerSelector],
  ({ fetchFindCustomerSuccess }) => fetchFindCustomerSuccess
);

export const getFetchFindCustomerFailed = createSelector(
  [customerSelector],
  ({ fetchFindCustomerFailed }) => fetchFindCustomerFailed
);

export const getCreateCustomerLoading = createSelector(
  [customerSelector],
  ({ createCustomerLoading }) => createCustomerLoading
);

export const getCreateCustomerSuccess = createSelector(
  [customerSelector],
  ({ createCustomerSuccess }) => createCustomerSuccess
);

export const getCreateCustomerFailed = createSelector(
  [customerSelector],
  ({ createCustomerFailed }) => createCustomerFailed
);

export const getUpdateCustomerLoading = createSelector(
  [customerSelector],
  ({ updateCustomerLoading }) => updateCustomerLoading
);

export const getUpdateCustomerSuccess = createSelector(
  [customerSelector],
  ({ updateCustomerSuccess }) => updateCustomerSuccess
);

export const getUpdateCustomerFailed = createSelector(
  [customerSelector],
  ({ updateCustomerFailed }) => updateCustomerFailed
);

export const getIsFetchCustomersHitted = createSelector(
  [customerSelector],
  ({ isFetchCustomersHitted }) => isFetchCustomersHitted
);

export const getIsFetchSelectCustomersHitted = createSelector(
  [customerSelector],
  ({ isFetchSelectCustomersHitted }) => isFetchSelectCustomersHitted
);

export const getIsFetchCustomerHitted = createSelector(
  [customerSelector],
  ({ isFetchCustomerHitted }) => isFetchCustomerHitted
);

export const getIsFetchFindCustomerHitted = createSelector(
  [customerSelector],
  ({ isFetchFindCustomerHitted }) => isFetchFindCustomerHitted
);

export const getIsCreateCustomerHitted = createSelector(
  [customerSelector],
  ({ isCreateCustomerHitted }) => isCreateCustomerHitted
);

export const getIsUpdateCustomerHitted = createSelector(
  [customerSelector],
  ({ isUpdateCustomerHitted }) => isUpdateCustomerHitted
);
