import styled, { css, keyframes } from "styled-components";
import { SPINNER_COLORS, SPINNER_SIZES } from "./spinner.component";

const spinnerAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const SpinnerContainer = styled.div`
  display: inline-block;
  width: 1.5rem;
  min-width: 1.5rem;
  max-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  max-height: 1.5rem;
  border: 0.2rem solid #000;
  border-radius: 50%;
  animation: ${spinnerAnimation} 0.75s linear infinite;

  border-color: ${({ spinnerColor }) => {
    switch (spinnerColor) {
      case SPINNER_COLORS.PRIMARY:
        return "#2900ee";
      case SPINNER_COLORS.SUCCESS:
        return "#008d0e";
      case SPINNER_COLORS.DANGER:
        return "#f02c2c";
      case SPINNER_COLORS.WARNING:
        return "#fc9114";
      case SPINNER_COLORS.SECONDARY:
        return "#e9e9ee";
      case SPINNER_COLORS.WHITE:
        return "#ffffff";
      case SPINNER_COLORS.LIGHT:
        return "#f2f2f4";
      case SPINNER_COLORS.DARK_SECONDARY:
        return "#9691ac";
      case SPINNER_COLORS.DARK:
      default:
        return "#0e072f";
    }
  }};
  border-right-color: transparent;

  margin-left: ${({ marginLeft, marginRight, isRtl }) => {
    if (marginLeft && !isRtl) {
      return marginLeft;
    } else if (marginRight && isRtl) {
      return marginRight;
    } else {
      return 0;
    }
  }};
  margin-right: ${({ marginLeft, marginRight, isRtl }) => {
    if (marginRight && !isRtl) {
      return marginRight;
    } else if (marginLeft && isRtl) {
      return marginLeft;
    } else {
      return 0;
    }
  }};

  ${({ spinnerSize }) =>
    spinnerSize === SPINNER_SIZES.SMALL &&
    css`
      width: 1rem;
      min-width: 1rem;
      max-width: 1rem;
      height: 1rem;
      min-height: 1rem;
      max-height: 1rem;
      border-width: 0.15rem;
    `}
`;
