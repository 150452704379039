import { createSelector } from "reselect";

const restockRequestSelector = ({ restockRequest }) => restockRequest;

export const getRestockRequests = createSelector([restockRequestSelector], ({ restockRequests }) => restockRequests);

export const getRestockRequest = createSelector([restockRequestSelector], ({ restockRequest }) => restockRequest);

export const getIsRestockRequestsHasMore = createSelector(
  [restockRequestSelector],
  ({ isRestockRequestsHasMore }) => isRestockRequestsHasMore
);

export const getFetchRestockRequestsSearch = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestsSearch }) => fetchRestockRequestsSearch
);

export const getFetchRestockRequestsPage = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestsPage }) => fetchRestockRequestsPage
);

export const getFetchRestockRequestsPerPage = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestsPerPage }) => fetchRestockRequestsPerPage
);

export const getFetchRestockRequestsIncludes = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestsIncludes }) => fetchRestockRequestsIncludes
);

export const getFetchRestockRequestsFilterMarketId = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestsFilterMarketId }) => fetchRestockRequestsFilterMarketId
);

export const getFetchRestockRequestsFilterBranchId = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestsFilterBranchId }) => fetchRestockRequestsFilterBranchId
);

export const getFetchRestockRequestsFilterStatuses = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestsFilterStatuses }) => fetchRestockRequestsFilterStatuses
);

export const getFetchRestockRequestsLoading = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestsLoading }) => fetchRestockRequestsLoading
);

export const getFetchRestockRequestsSuccess = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestsSuccess }) => fetchRestockRequestsSuccess
);

export const getFetchRestockRequestsFailed = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestsFailed }) => fetchRestockRequestsFailed
);

export const getFetchRestockRequestLoading = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestLoading }) => fetchRestockRequestLoading
);

export const getFetchRestockRequestSuccess = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestSuccess }) => fetchRestockRequestSuccess
);

export const getFetchRestockRequestFailed = createSelector(
  [restockRequestSelector],
  ({ fetchRestockRequestFailed }) => fetchRestockRequestFailed
);

export const getCreateRestockRequestLoading = createSelector(
  [restockRequestSelector],
  ({ createRestockRequestLoading }) => createRestockRequestLoading
);

export const getCreateRestockRequestSuccess = createSelector(
  [restockRequestSelector],
  ({ createRestockRequestSuccess }) => createRestockRequestSuccess
);

export const getCreateRestockRequestFailed = createSelector(
  [restockRequestSelector],
  ({ createRestockRequestFailed }) => createRestockRequestFailed
);

export const getUpdateRestockRequestLoading = createSelector(
  [restockRequestSelector],
  ({ updateRestockRequestLoading }) => updateRestockRequestLoading
);

export const getUpdateRestockRequestSuccess = createSelector(
  [restockRequestSelector],
  ({ updateRestockRequestSuccess }) => updateRestockRequestSuccess
);

export const getUpdateRestockRequestFailed = createSelector(
  [restockRequestSelector],
  ({ updateRestockRequestFailed }) => updateRestockRequestFailed
);

export const getDeleteRestockRequestLoading = createSelector(
  [restockRequestSelector],
  ({ deleteRestockRequestLoading }) => deleteRestockRequestLoading
);

export const getDeleteRestockRequestSuccess = createSelector(
  [restockRequestSelector],
  ({ deleteRestockRequestSuccess }) => deleteRestockRequestSuccess
);

export const getDeleteRestockRequestFailed = createSelector(
  [restockRequestSelector],
  ({ deleteRestockRequestFailed }) => deleteRestockRequestFailed
);
