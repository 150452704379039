import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getPermissionPages, subscriberRoutePages } from "../../App";

import { LANGUAGES } from "../../constants/common.constant";
import {
  PERMISSION_ADMIN_PAGES,
  PERMISSION_SUBSCRIBER_PAGES,
} from "../../constants/permission.constant";
import { USER_ROLES } from "../../constants/user.constant";

import {
  getAuthorizePages,
  getIsHasSubscription,
} from "../../store/global/global.selector";
import {
  authCheckStart,
  setAuthCheckFailed,
  setAuthCheckSuccess,
} from "../../store/authentication/authentication.action";
import {
  getAuthCheckFailed,
  getAuthCheckSuccess,
  getUser,
} from "../../store/authentication/authentication.selector";

import Spinner, {
  SPINNER_COLORS,
} from "../../components/spinner/spinner.component";

import { LoadingContainer, LoadingLogo } from "./loading.style";

import LogoImageAr from "../../assets/images/logos/flavours-black-ar.png";
import LogoImageEn from "../../assets/images/logos/flavours-black-en.png";

const Loading = ({ isLoading = false, isRedirect = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  const authenticationUser = useSelector(getUser);
  const authorizePages = useSelector(getAuthorizePages);
  const isHasSubscription = useSelector(getIsHasSubscription);
  const authCheckSuccess = useSelector(getAuthCheckSuccess);
  const authCheckFailed = useSelector(getAuthCheckFailed);

  const getTargetRoute = () => {
    const unauthenticateRoutes = ["/sign-in", "/sign-up", "/short"];
    const currentRoute = location.pathname;

    const { role } = authenticationUser ?? {};

    if (
      authenticationUser === null ||
      ![
        USER_ROLES.USER_ADMIN,
        USER_ROLES.USER_MERCHANT,
        USER_ROLES.USER_SUBSCRIBER,
      ].includes(role)
    ) {
      return unauthenticateRoutes.includes(currentRoute)
        ? currentRoute
        : "/sign-in";
    }

    const initialPageKey = [
      USER_ROLES.USER_ADMIN,
      USER_ROLES.USER_MERCHANT,
    ].includes(role)
      ? PERMISSION_ADMIN_PAGES.DASHBOARD
      : PERMISSION_SUBSCRIBER_PAGES.ORDER;

    const firstPageKey = authorizePages.includes(initialPageKey)
      ? initialPageKey
      : authorizePages?.[0];

    const routePages = getPermissionPages(role);
    const routePath =
      role === USER_ROLES.USER_SUBSCRIBER && !isHasSubscription
        ? "/subscription"
        : routePages.find(({ key }) => key === firstPageKey)?.path ??
          "/dashboard";

    return currentRoute === "/" ? routePath : currentRoute;
  };

  useEffect(() => {
    dispatch(authCheckStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRedirect) {
      if (authCheckSuccess !== null || authCheckFailed !== null) {
        if (authCheckSuccess !== null) dispatch(setAuthCheckSuccess(null));
        if (authCheckFailed !== null) dispatch(setAuthCheckFailed(null));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCheckSuccess, authCheckFailed]);

  useEffect(() => {
    if (isRedirect && !isLoading) {
      const delayDebounce = setTimeout(() => navigate(getTargetRoute()), 1500);
      return () => clearTimeout(delayDebounce);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isRedirect,
    isLoading,
    authenticationUser,
    authorizePages,
    isHasSubscription,
  ]);

  return (
    <LoadingContainer>
      <LoadingLogo
        src={i18n.language === LANGUAGES.AR ? LogoImageAr : LogoImageEn}
      />
      <Spinner spinnerColor={SPINNER_COLORS.DARK} />
    </LoadingContainer>
  );
};

export default Loading;
