import { takeLatest, put, all, call } from "redux-saga/effects";

import { USER_ROLES } from "../../constants/user.constant";

import AUTHENTICATION_ACTION_TYPES from "./authentication.type";
import {
  setAuthCheckFailed,
  setAuthCheckLoading,
  setAuthCheckSuccess,
  setFetchMeFailed,
  setFetchMeLoading,
  setFetchMeSuccess,
  setIsAuthCheckHitted,
  setIsFetchMeHitted,
  setIsSignInHitted,
  setIsSignOutHitted,
  setIsSignUpHitted,
  setIsUpdateMeHitted,
  setSignInFailed,
  setSignInLoading,
  setSignInSuccess,
  setSignOutFailed,
  setSignOutLoading,
  setSignOutSuccess,
  setSignUpFailed,
  setSignUpLoading,
  setSignUpSuccess,
  setUpdateMeFailed,
  setUpdateMeLoading,
  setUpdateMeSuccess,
  setUser,
} from "./authentication.action";
import {
  doSignIn,
  doSignUp,
  getMe,
  updateMe,
} from "../../api/authentication.api";
import { getLocation } from "../../api/mapbox.api";

import {
  clearAuthToken,
  getAuthToken,
  setAuthToken,
} from "../../utils/storage.utils";

export function* _getMe() {
  try {
    yield put(setFetchMeLoading(true));

    const {
      meta: { message },
      data: user,
    } = yield call(getMe);

    yield put(setIsFetchMeHitted(true));
    yield put(setUser(user));

    yield put(setFetchMeSuccess(message));
    yield put(setFetchMeLoading(false));
  } catch (error) {
    yield put(setFetchMeFailed(error));
    yield put(setFetchMeLoading(false));
  }
}
export function* _updateMe({ payload: request }) {
  try {
    yield put(setUpdateMeLoading(true));

    const {
      meta: { message },
      data: user,
    } = yield call(updateMe, request);

    yield put(setIsUpdateMeHitted(true));
    yield put(setUser(user));

    yield put(setUpdateMeSuccess(message));
    yield put(setUpdateMeLoading(false));
  } catch (error) {
    yield put(setUpdateMeFailed(error));
    yield put(setUpdateMeLoading(false));
  }
}
export function* _doAuthCheck() {
  try {
    yield put(setAuthCheckLoading(true));

    const token = yield call(getAuthToken);

    if (token !== null) {
      yield call(_getMe);
    } else {
      yield call(clearAuthToken);
      yield put(setUser(null));
    }

    yield put(setIsAuthCheckHitted(true));

    yield put(setAuthCheckSuccess(true));
    yield put(setAuthCheckLoading(false));
  } catch (error) {
    yield put(setAuthCheckFailed(error));
    yield put(setAuthCheckLoading(false));
  }
}
export function* _doSignIn({ payload: request }) {
  try {
    yield put(setSignInLoading(true));

    const roles = [
      USER_ROLES.USER_ADMIN,
      USER_ROLES.USER_MERCHANT,
      USER_ROLES.USER_SUBSCRIBER,
    ];

    const {
      meta: { message },
      data: token,
    } = yield call(doSignIn, { ...request, roles });

    yield call(setAuthToken, token);
    yield call(_doAuthCheck);
    yield put(setIsSignInHitted(true));

    yield put(setSignInSuccess(message));
    yield put(setSignInLoading(false));
  } catch (error) {
    yield put(setSignInFailed(error));
    yield put(setSignInLoading(false));
  }
}
export function* _doSignUp({ payload: request }) {
  try {
    yield put(setSignUpLoading(true));

    const { longitude, latitude } = request?.branch;

    if (![longitude, latitude].includes(undefined)) {
      const { features } = yield call(getLocation, longitude, latitude);
      const [feature] = features ?? [];
      const { place_name } = feature ?? {};

      request.branch.address = place_name;
    }

    const {
      meta: { message },
      data: token,
    } = yield call(doSignUp, request);

    yield call(setAuthToken, token);
    yield call(_doAuthCheck);
    yield put(setIsSignUpHitted(true));

    yield put(setSignUpSuccess(message));
    yield put(setSignUpLoading(false));
  } catch (error) {
    yield put(setSignUpFailed(error));
    yield put(setSignUpLoading(false));
  }
}
export function* _doSignOut() {
  try {
    yield put(setSignOutLoading(true));

    yield call(clearAuthToken);
    yield put(setIsSignOutHitted(true));
    yield put(setUser(null));

    yield put(setSignOutSuccess("Sign out success"));
    yield put(setSignOutLoading(false));
  } catch (error) {
    yield put(setSignOutFailed(error));
    yield put(setSignOutLoading(true));
  }
}

export function* onAuthCheckStart() {
  yield takeLatest(AUTHENTICATION_ACTION_TYPES.AUTH_CHECK_START, _doAuthCheck);
}
export function* onFetchMeStart() {
  yield takeLatest(AUTHENTICATION_ACTION_TYPES.FETCH_ME_START, _getMe);
}
export function* onUpdateMeStart() {
  yield takeLatest(AUTHENTICATION_ACTION_TYPES.UPDATE_ME_START, _updateMe);
}
export function* onSignInStart() {
  yield takeLatest(AUTHENTICATION_ACTION_TYPES.SIGN_IN_START, _doSignIn);
}
export function* onSignUpStart() {
  yield takeLatest(AUTHENTICATION_ACTION_TYPES.SIGN_UP_START, _doSignUp);
}
export function* onSignOutStart() {
  yield takeLatest(AUTHENTICATION_ACTION_TYPES.SIGN_OUT_START, _doSignOut);
}

export function* authenticationSaga() {
  yield all([
    call(onAuthCheckStart),
    call(onFetchMeStart),
    call(onUpdateMeStart),
    call(onSignInStart),
    call(onSignUpStart),
    call(onSignOutStart),
  ]);
}
