import EXPORT_PDF_ACTION_TYPES from "./export-pdf.type";
import { createAction } from "../../utils/store.utils";

export const setExportPdfOrderInvoice = (exportPdfOrderInvoice) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE,
    exportPdfOrderInvoice
  );
export const setExportPdfOrderKitchen = (exportPdfOrderKitchen) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN,
    exportPdfOrderKitchen
  );

export const setExportPdfOrderInvoiceLoading = (exportPdfOrderInvoiceLoading) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_LOADING,
    exportPdfOrderInvoiceLoading
  );
export const setExportPdfOrderInvoiceSuccess = (exportPdfOrderInvoiceSuccess) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_SUCCESS,
    exportPdfOrderInvoiceSuccess
  );
export const setExportPdfOrderInvoiceFailed = (exportPdfOrderInvoiceFailed) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_INVOICE_FAILED,
    exportPdfOrderInvoiceFailed
  );

export const setExportPdfOrderKitchenLoading = (exportPdfOrderKitchenLoading) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_LOADING,
    exportPdfOrderKitchenLoading
  );
export const setExportPdfOrderKitchenSuccess = (exportPdfOrderKitchenSuccess) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_SUCCESS,
    exportPdfOrderKitchenSuccess
  );
export const setExportPdfOrderKitchenFailed = (exportPdfOrderKitchenFailed) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_EXPORT_PDF_ORDER_KITCHEN_FAILED,
    exportPdfOrderKitchenFailed
  );

export const exportPdfOrderInvoiceStart = (orderId, request) =>
  createAction(EXPORT_PDF_ACTION_TYPES.EXPORT_PDF_ORDER_INVOICE_START, {
    orderId,
    request,
  });
export const exportPdfOrderKitchenStart = (orderId, request) =>
  createAction(EXPORT_PDF_ACTION_TYPES.EXPORT_PDF_ORDER_KITCHEN_START, {
    orderId,
    request,
  });

export const setIsExportPdfOrderInvoiceHitted = (
  isExportPdfOrderInvoiceHitted
) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_IS_EXPORT_PDF_ORDER_INVOICE_HITTED,
    isExportPdfOrderInvoiceHitted
  );
export const setIsExportPdfOrderKitchenHitted = (
  isExportPdfOrderKitchenHitted
) =>
  createAction(
    EXPORT_PDF_ACTION_TYPES.SET_IS_EXPORT_PDF_ORDER_KITCHEN_HITTED,
    isExportPdfOrderKitchenHitted
  );

export const resetExportPdfReducer = () =>
  createAction(EXPORT_PDF_ACTION_TYPES.RESET_EXPORT_PDF_REDUCER);
