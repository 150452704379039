import { createSelector } from "reselect";

const branchSelector = ({ branch }) => branch;

export const getIsModalAutoClose = createSelector(
  [branchSelector],
  ({ isModalAutoClose }) => isModalAutoClose
);

export const getBranches = createSelector(
  [branchSelector],
  ({ branches }) => branches
);
export const getSelectBranches = createSelector(
  [branchSelector],
  ({ selectBranches }) => selectBranches
);
export const getActiveBranches = createSelector(
  [branchSelector],
  ({ activeBranches }) => activeBranches
);
export const getBranch = createSelector(
  [branchSelector],
  ({ branch }) => branch
);
export const getSelectBranch = createSelector(
  [branchSelector],
  ({ selectBranch }) => selectBranch
);
export const getActiveBranch = createSelector(
  [branchSelector],
  ({ activeBranch }) => activeBranch
);
export const getSubscriptionBranch = createSelector(
  [branchSelector],
  ({ subscriptionBranch }) => subscriptionBranch
);

export const getIsBranchesHasMore = createSelector(
  [branchSelector],
  ({ isBranchesHasMore }) => isBranchesHasMore
);
export const getIsSelectBranchesHasMore = createSelector(
  [branchSelector],
  ({ isSelectBranchesHasMore }) => isSelectBranchesHasMore
);
export const getIsActiveBranchesHasMore = createSelector(
  [branchSelector],
  ({ isActiveBranchesHasMore }) => isActiveBranchesHasMore
);

export const getFetchBranchesSearch = createSelector(
  [branchSelector],
  ({ fetchBranchesSearch }) => fetchBranchesSearch
);
export const getFetchBranchesPage = createSelector(
  [branchSelector],
  ({ fetchBranchesPage }) => fetchBranchesPage
);
export const getFetchBranchesPerPage = createSelector(
  [branchSelector],
  ({ fetchBranchesPerPage }) => fetchBranchesPerPage
);
export const getFetchBranchesIncludes = createSelector(
  [branchSelector],
  ({ fetchBranchesIncludes }) => fetchBranchesIncludes
);
export const getFetchBranchesFilterMarketId = createSelector(
  [branchSelector],
  ({ fetchBranchesFilterMarketId }) => fetchBranchesFilterMarketId
);
export const getFetchBranchesFilterIsActive = createSelector(
  [branchSelector],
  ({ fetchBranchesFilterIsActive }) => fetchBranchesFilterIsActive
);
export const getFetchBranchesLoading = createSelector(
  [branchSelector],
  ({ fetchBranchesLoading }) => fetchBranchesLoading
);
export const getFetchBranchesSuccess = createSelector(
  [branchSelector],
  ({ fetchBranchesSuccess }) => fetchBranchesSuccess
);
export const getFetchBranchesFailed = createSelector(
  [branchSelector],
  ({ fetchBranchesFailed }) => fetchBranchesFailed
);

export const getFetchSelectBranchesPage = createSelector(
  [branchSelector],
  ({ fetchSelectBranchesPage }) => fetchSelectBranchesPage
);
export const getFetchSelectBranchesPerPage = createSelector(
  [branchSelector],
  ({ fetchSelectBranchesPerPage }) => fetchSelectBranchesPerPage
);
export const getFetchSelectBranchesIncludes = createSelector(
  [branchSelector],
  ({ fetchSelectBranchesIncludes }) => fetchSelectBranchesIncludes
);
export const getFetchSelectBranchesFilterMarketId = createSelector(
  [branchSelector],
  ({ fetchSelectBranchesFilterMarketId }) => fetchSelectBranchesFilterMarketId
);
export const getFetchSelectBranchesFilterIsActive = createSelector(
  [branchSelector],
  ({ fetchSelectBranchesFilterIsActive }) => fetchSelectBranchesFilterIsActive
);
export const getFetchSelectBranchesLoading = createSelector(
  [branchSelector],
  ({ fetchSelectBranchesLoading }) => fetchSelectBranchesLoading
);
export const getFetchSelectBranchesSuccess = createSelector(
  [branchSelector],
  ({ fetchSelectBranchesSuccess }) => fetchSelectBranchesSuccess
);
export const getFetchSelectBranchesFailed = createSelector(
  [branchSelector],
  ({ fetchSelectBranchesFailed }) => fetchSelectBranchesFailed
);

export const getFetchActiveBranchesPage = createSelector(
  [branchSelector],
  ({ fetchActiveBranchesPage }) => fetchActiveBranchesPage
);
export const getFetchActiveBranchesPerPage = createSelector(
  [branchSelector],
  ({ fetchActiveBranchesPerPage }) => fetchActiveBranchesPerPage
);
export const getFetchActiveBranchesIncludes = createSelector(
  [branchSelector],
  ({ fetchActiveBranchesIncludes }) => fetchActiveBranchesIncludes
);
export const getFetchActiveBranchesFilterMarketId = createSelector(
  [branchSelector],
  ({ fetchActiveBranchesFilterMarketId }) => fetchActiveBranchesFilterMarketId
);
export const getFetchActiveBranchesFilterIsActive = createSelector(
  [branchSelector],
  ({ fetchActiveBranchesFilterIsActive }) => fetchActiveBranchesFilterIsActive
);
export const getFetchActiveBranchesLoading = createSelector(
  [branchSelector],
  ({ fetchActiveBranchesLoading }) => fetchActiveBranchesLoading
);
export const getFetchActiveBranchesSuccess = createSelector(
  [branchSelector],
  ({ fetchActiveBranchesSuccess }) => fetchActiveBranchesSuccess
);
export const getFetchActiveBranchesFailed = createSelector(
  [branchSelector],
  ({ fetchActiveBranchesFailed }) => fetchActiveBranchesFailed
);

export const getFetchBranchLoading = createSelector(
  [branchSelector],
  ({ fetchBranchLoading }) => fetchBranchLoading
);
export const getFetchBranchSuccess = createSelector(
  [branchSelector],
  ({ fetchBranchSuccess }) => fetchBranchSuccess
);
export const getFetchBranchFailed = createSelector(
  [branchSelector],
  ({ fetchBranchFailed }) => fetchBranchFailed
);

export const getFetchSelectBranchLoading = createSelector(
  [branchSelector],
  ({ fetchSelectBranchLoading }) => fetchSelectBranchLoading
);
export const getFetchSelectBranchSuccess = createSelector(
  [branchSelector],
  ({ fetchSelectBranchSuccess }) => fetchSelectBranchSuccess
);
export const getFetchSelectBranchFailed = createSelector(
  [branchSelector],
  ({ fetchSelectBranchFailed }) => fetchSelectBranchFailed
);

export const getFetchActiveBranchLoading = createSelector(
  [branchSelector],
  ({ fetchActiveBranchLoading }) => fetchActiveBranchLoading
);
export const getFetchActiveBranchSuccess = createSelector(
  [branchSelector],
  ({ fetchActiveBranchSuccess }) => fetchActiveBranchSuccess
);
export const getFetchActiveBranchFailed = createSelector(
  [branchSelector],
  ({ fetchActiveBranchFailed }) => fetchActiveBranchFailed
);

export const getCreateBranchLoading = createSelector(
  [branchSelector],
  ({ createBranchLoading }) => createBranchLoading
);
export const getCreateBranchSuccess = createSelector(
  [branchSelector],
  ({ createBranchSuccess }) => createBranchSuccess
);
export const getCreateBranchFailed = createSelector(
  [branchSelector],
  ({ createBranchFailed }) => createBranchFailed
);

export const getUpdateBranchLoading = createSelector(
  [branchSelector],
  ({ updateBranchLoading }) => updateBranchLoading
);
export const getUpdateBranchSuccess = createSelector(
  [branchSelector],
  ({ updateBranchSuccess }) => updateBranchSuccess
);
export const getUpdateBranchFailed = createSelector(
  [branchSelector],
  ({ updateBranchFailed }) => updateBranchFailed
);

export const getDeleteBranchLoading = createSelector(
  [branchSelector],
  ({ deleteBranchLoading }) => deleteBranchLoading
);
export const getDeleteBranchSuccess = createSelector(
  [branchSelector],
  ({ deleteBranchSuccess }) => deleteBranchSuccess
);
export const getDeleteBranchFailed = createSelector(
  [branchSelector],
  ({ deleteBranchFailed }) => deleteBranchFailed
);

export const getIsFetchBranchesHitted = createSelector(
  [branchSelector],
  ({ isFetchBranchesHitted }) => isFetchBranchesHitted
);
export const getIsFetchSelectBranchesHitted = createSelector(
  [branchSelector],
  ({ isFetchSelectBranchesHitted }) => isFetchSelectBranchesHitted
);
export const getIsFetchActiveBranchesHitted = createSelector(
  [branchSelector],
  ({ isFetchActiveBranchesHitted }) => isFetchActiveBranchesHitted
);
export const getIsFetchBranchHitted = createSelector(
  [branchSelector],
  ({ isFetchBranchHitted }) => isFetchBranchHitted
);
export const getIsFetchSelectBranchHitted = createSelector(
  [branchSelector],
  ({ isFetchSelectBranchHitted }) => isFetchSelectBranchHitted
);
export const getIsFetchActiveBranchHitted = createSelector(
  [branchSelector],
  ({ isFetchActiveBranchHitted }) => isFetchActiveBranchHitted
);
export const getIsCreateBranchHitted = createSelector(
  [branchSelector],
  ({ isCreateBranchHitted }) => isCreateBranchHitted
);
export const getIsUpdateBranchHitted = createSelector(
  [branchSelector],
  ({ isUpdateBranchHitted }) => isUpdateBranchHitted
);
export const getIsDeleteBranchHitted = createSelector(
  [branchSelector],
  ({ isDeleteBranchHitted }) => isDeleteBranchHitted
);
