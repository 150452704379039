import CUSTOMER_ACTION_TYPES from "./customer.type";
import { createAction } from "../../utils/store.utils";

export const setCustomers = (customers) => createAction(CUSTOMER_ACTION_TYPES.SET_CUSTOMERS, customers);

export const setSelectCustomers = (selectCustomers) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_SELECT_CUSTOMERS, selectCustomers);

export const setCustomer = (customer) => createAction(CUSTOMER_ACTION_TYPES.SET_CUSTOMER, customer);

export const setFoundCustomer = (foundCustomer) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FOUND_CUSTOMER, foundCustomer);

export const setCreateCustomer = (createCustomer) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER, createCustomer);

export const setUpdateCustomer = (updateCustomer) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER, updateCustomer);

export const setIsCustomersHasMore = (isCustomersHasMore) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_IS_CUSTOMERS_HAS_MORE, isCustomersHasMore);

export const setIsSelectCustomersHasMore = (isSelectCustomersHasMore) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_IS_SELECT_CUSTOMERS_HAS_MORE, isSelectCustomersHasMore);

export const setFetchCustomersSearch = (fetchCustomersSearch) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SEARCH, fetchCustomersSearch);

export const setFetchCustomersPage = (fetchCustomersPage) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PAGE, fetchCustomersPage);

export const setFetchCustomersPerPage = (fetchCustomersPerPage) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_PER_PAGE, fetchCustomersPerPage);

export const setFetchCustomersIncludes = (fetchCustomersIncludes) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_INCLUDES, fetchCustomersIncludes);

export const setFetchCustomersFilterMarketId = (fetchCustomersFilterMarketId) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FILTER_MARKET_ID, fetchCustomersFilterMarketId);

export const setFetchCustomersLoading = (fetchCustomersLoading) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_LOADING, fetchCustomersLoading);

export const setFetchCustomersSuccess = (fetchCustomersSuccess) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_SUCCESS, fetchCustomersSuccess);

export const setFetchCustomersFailed = (fetchCustomersFailed) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMERS_FAILED, fetchCustomersFailed);

export const setFetchSelectCustomersSearch = (fetchSelectCustomersSearch) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_SEARCH, fetchSelectCustomersSearch);

export const setFetchSelectCustomersPage = (fetchSelectCustomersPage) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_PAGE, fetchSelectCustomersPage);

export const setFetchSelectCustomersPerPage = (fetchSelectCustomersPerPage) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_PER_PAGE, fetchSelectCustomersPerPage);

export const setFetchSelectCustomersIncludes = (fetchSelectCustomersIncludes) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_INCLUDES, fetchSelectCustomersIncludes);

export const setFetchSelectCustomersFilterMarketId = (fetchSelectCustomersFilterMarketId) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_FILTER_MARKET_ID, fetchSelectCustomersFilterMarketId);

export const setFetchSelectCustomersLoading = (fetchSelectCustomersLoading) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_LOADING, fetchSelectCustomersLoading);

export const setFetchSelectCustomersSuccess = (fetchSelectCustomersSuccess) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_SUCCESS, fetchSelectCustomersSuccess);

export const setFetchSelectCustomersFailed = (fetchSelectCustomersFailed) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_SELECT_CUSTOMERS_FAILED, fetchSelectCustomersFailed);

export const setFetchCustomerLoading = (fetchCustomerLoading) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_LOADING, fetchCustomerLoading);

export const setFetchCustomerSuccess = (fetchCustomerSuccess) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_SUCCESS, fetchCustomerSuccess);

export const setFetchCustomerFailed = (fetchCustomerFailed) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_CUSTOMER_FAILED, fetchCustomerFailed);

export const setFetchFindCustomerLoading = (fetchFindCustomerLoading) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_FIND_CUSTOMER_LOADING, fetchFindCustomerLoading);

export const setFetchFindCustomerSuccess = (fetchFindCustomerSuccess) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_FIND_CUSTOMER_SUCCESS, fetchFindCustomerSuccess);

export const setFetchFindCustomerFailed = (fetchFindCustomerFailed) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_FETCH_FIND_CUSTOMER_FAILED, fetchFindCustomerFailed);

export const setCreateCustomerLoading = (createCustomerLoading) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_LOADING, createCustomerLoading);

export const setCreateCustomerSuccess = (createCustomerSuccess) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_SUCCESS, createCustomerSuccess);

export const setCreateCustomerFailed = (createCustomerFailed) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_CREATE_CUSTOMER_FAILED, createCustomerFailed);

export const setUpdateCustomerLoading = (updateCustomerLoading) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_LOADING, updateCustomerLoading);

export const setUpdateCustomerSuccess = (updateCustomerSuccess) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_SUCCESS, updateCustomerSuccess);

export const setUpdateCustomerFailed = (updateCustomerFailed) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_UPDATE_CUSTOMER_FAILED, updateCustomerFailed);

export const setDeleteCustomerLoading = (deleteCustomerLoading) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_DELETE_CUSTOMER_LOADING, deleteCustomerLoading);

export const setDeleteCustomerSuccess = (deleteCustomerSuccess) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_DELETE_CUSTOMER_SUCCESS, deleteCustomerSuccess);

export const setDeleteCustomerFailed = (deleteCustomerFailed) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_DELETE_CUSTOMER_FAILED, deleteCustomerFailed);

export const appendCustomers = (customers) => createAction(CUSTOMER_ACTION_TYPES.APPEND_CUSTOMERS, customers);

export const appendSelectCustomers = (selectCustomers) =>
  createAction(CUSTOMER_ACTION_TYPES.APPEND_SELECT_CUSTOMERS, selectCustomers);

export const fetchCustomersStart = () => createAction(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMERS_START);

export const fetchSelectCustomersStart = () => createAction(CUSTOMER_ACTION_TYPES.FETCH_SELECT_CUSTOMERS_START);

export const fetchCustomerStart = (id) => createAction(CUSTOMER_ACTION_TYPES.FETCH_CUSTOMER_START, id);

export const fetchFindCustomerStart = (phone) => createAction(CUSTOMER_ACTION_TYPES.FETCH_FIND_CUSTOMER_START, phone);

export const createCustomerStart = (request) => createAction(CUSTOMER_ACTION_TYPES.CREATE_CUSTOMER_START, request);

export const updateCustomerStart = (id, request) =>
  createAction(CUSTOMER_ACTION_TYPES.UPDATE_CUSTOMER_START, { id, request });

export const setIsFetchCustomersHitted = (isFetchCustomersHitted) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_IS_FETCH_CUSTOMERS_HITTED, isFetchCustomersHitted);

export const setIsFetchSelectCustomersHitted = (isFetchSelectCustomersHitted) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_IS_FETCH_SELECT_CUSTOMERS_HITTED, isFetchSelectCustomersHitted);

export const setIsFetchCustomerHitted = (isFetchCustomerHitted) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_IS_FETCH_CUSTOMER_HITTED, isFetchCustomerHitted);

export const setIsFetchFindCustomerHitted = (isFetchFindCustomerHitted) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_IS_FETCH_FIND_CUSTOMER_HITTED, isFetchFindCustomerHitted);

export const setIsCreateCustomerHitted = (isCreateCustomerHitted) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_IS_CREATE_CUSTOMER_HITTED, isCreateCustomerHitted);

export const setIsUpdateCustomerHitted = (isUpdateCustomerHitted) =>
  createAction(CUSTOMER_ACTION_TYPES.SET_IS_UPDATE_CUSTOMER_HITTED, isUpdateCustomerHitted);

export const resetCustomerReducer = () => createAction(CUSTOMER_ACTION_TYPES.RESET_CUSTOMER_REDUCER);
