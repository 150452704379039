import COUNTRY_ACTION_TYPES from "./country.type";

export const COUNTRY_INITIAL_STATE = {
  countries: {},
  selectCountries: {},
  country: null,

  isCountriesHasMore: true,
  isSelectCountriesHasMore: true,

  fetchCountriesSearch: null,
  fetchCountriesKeyBy: null,
  fetchCountriesPage: 1,
  fetchCountriesPerPage: null,
  fetchCountriesIncludes: null,
  fetchCountriesLoading: false,
  fetchCountriesSuccess: null,
  fetchCountriesFailed: null,

  fetchSelectCountriesSearch: null,
  fetchSelectCountriesKeyBy: null,
  fetchSelectCountriesPage: 1,
  fetchSelectCountriesPerPage: null,
  fetchSelectCountriesIncludes: null,
  fetchSelectCountriesLoading: false,
  fetchSelectCountriesSuccess: null,
  fetchSelectCountriesFailed: null,

  fetchCountryLoading: false,
  fetchCountrySuccess: null,
  fetchCountryFailed: null,

  createCountryLoading: false,
  createCountrySuccess: null,
  createCountryFailed: null,

  updateCountryLoading: false,
  updateCountrySuccess: null,
  updateCountryFailed: null,

  deleteCountryLoading: false,
  deleteCountrySuccess: null,
  deleteCountryFailed: null,

  syncRateCountryLoading: false,
  syncRateCountrySuccess: null,
  syncRateCountryFailed: null,

  isFetchCountriesHitted: false,
  isFetchSelectCountriesHitted: false,
  isFetchCountryHitted: false,
  isCreateCountryHitted: false,
  isUpdateCountryHitted: false,
  isDeleteCountryHitted: false,
  isSyncRateCountryHitted: false,
};

export const countryReducer = (state = COUNTRY_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case COUNTRY_ACTION_TYPES.SET_COUNTRIES:
      return { ...state, countries: payload };
    case COUNTRY_ACTION_TYPES.SET_SELECT_COUNTRIES:
      return { ...state, selectCountries: payload };
    case COUNTRY_ACTION_TYPES.SET_COUNTRY:
      return { ...state, country: payload };

    case COUNTRY_ACTION_TYPES.SET_IS_COUNTRIES_HAS_MORE:
      return { ...state, isCountriesHasMore: payload };
    case COUNTRY_ACTION_TYPES.SET_IS_SELECT_COUNTRIES_HAS_MORE:
      return { ...state, isSelectCountriesHasMore: payload };

    case COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_SEARCH:
      return { ...state, fetchCountriesSearch: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_KEY_BY:
      return { ...state, fetchCountriesKeyBy: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_PAGE:
      return { ...state, fetchCountriesPage: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_PER_PAGE:
      return { ...state, fetchCountriesPerPage: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_INCLUDES:
      return { ...state, fetchCountriesIncludes: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_LOADING:
      return { ...state, fetchCountriesLoading: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_SUCCESS:
      return { ...state, fetchCountriesSuccess: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRIES_FAILED:
      return { ...state, fetchCountriesFailed: payload };

    case COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_SEARCH:
      return { ...state, fetchSelectCountriesSearch: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_KEY_BY:
      return { ...state, fetchSelectCountriesKeyBy: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_PAGE:
      return { ...state, fetchSelectCountriesPage: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_PER_PAGE:
      return { ...state, fetchSelectCountriesPerPage: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_INCLUDES:
      return { ...state, fetchSelectCountriesIncludes: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_LOADING:
      return { ...state, fetchSelectCountriesLoading: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_SUCCESS:
      return { ...state, fetchSelectCountriesSuccess: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_SELECT_COUNTRIES_FAILED:
      return { ...state, fetchSelectCountriesFailed: payload };

    case COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRY_LOADING:
      return { ...state, fetchCountryLoading: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRY_SUCCESS:
      return { ...state, fetchCountrySuccess: payload };
    case COUNTRY_ACTION_TYPES.SET_FETCH_COUNTRY_FAILED:
      return { ...state, fetchCountryFailed: payload };

    case COUNTRY_ACTION_TYPES.SET_CREATE_COUNTRY_LOADING:
      return { ...state, createCountryLoading: payload };
    case COUNTRY_ACTION_TYPES.SET_CREATE_COUNTRY_SUCCESS:
      return { ...state, createCountrySuccess: payload };
    case COUNTRY_ACTION_TYPES.SET_CREATE_COUNTRY_FAILED:
      return { ...state, createCountryFailed: payload };

    case COUNTRY_ACTION_TYPES.SET_UPDATE_COUNTRY_LOADING:
      return { ...state, updateCountryLoading: payload };
    case COUNTRY_ACTION_TYPES.SET_UPDATE_COUNTRY_SUCCESS:
      return { ...state, updateCountrySuccess: payload };
    case COUNTRY_ACTION_TYPES.SET_UPDATE_COUNTRY_FAILED:
      return { ...state, updateCountryFailed: payload };

    case COUNTRY_ACTION_TYPES.SET_DELETE_COUNTRY_LOADING:
      return { ...state, deleteCountryLoading: payload };
    case COUNTRY_ACTION_TYPES.SET_DELETE_COUNTRY_SUCCESS:
      return { ...state, deleteCountrySuccess: payload };
    case COUNTRY_ACTION_TYPES.SET_DELETE_COUNTRY_FAILED:
      return { ...state, deleteCountryFailed: payload };

    case COUNTRY_ACTION_TYPES.SET_SYNC_RATE_COUNTRY_LOADING:
      return { ...state, syncRateCountryLoading: payload };
    case COUNTRY_ACTION_TYPES.SET_SYNC_RATE_COUNTRY_SUCCESS:
      return { ...state, syncRateCountrySuccess: payload };
    case COUNTRY_ACTION_TYPES.SET_SYNC_RATE_COUNTRY_FAILED:
      return { ...state, syncRateCountryFailed: payload };

    case COUNTRY_ACTION_TYPES.SET_IS_FETCH_COUNTRIES_HITTED:
      return { ...state, isFetchCountriesHitted: payload };
    case COUNTRY_ACTION_TYPES.SET_IS_FETCH_SELECT_COUNTRIES_HITTED:
      return { ...state, isFetchSelectCountriesHitted: payload };
    case COUNTRY_ACTION_TYPES.SET_IS_FETCH_COUNTRY_HITTED:
      return { ...state, isFetchCountryHitted: payload };
    case COUNTRY_ACTION_TYPES.SET_IS_CREATE_COUNTRY_HITTED:
      return { ...state, isCreateCountryHitted: payload };
    case COUNTRY_ACTION_TYPES.SET_IS_UPDATE_COUNTRY_HITTED:
      return { ...state, isUpdateCountryHitted: payload };
    case COUNTRY_ACTION_TYPES.SET_IS_DELETE_COUNTRY_HITTED:
      return { ...state, isDeleteCountryHitted: payload };
    case COUNTRY_ACTION_TYPES.SET_IS_SYNC_RATE_COUNTRY_HITTED:
      return { ...state, isSyncRateCountryHitted: payload };

    case COUNTRY_ACTION_TYPES.APPEND_COUNTRIES:
      return { ...state, countries: [...state.countries, ...payload] };
    case COUNTRY_ACTION_TYPES.APPEND_SELECT_COUNTRIES:
      return { ...state, selectCountries: [...state.selectCountries, ...payload] };

    case COUNTRY_ACTION_TYPES.RESET_COUNTRY_REDUCER:
      return COUNTRY_INITIAL_STATE;
    default:
      return state;
  }
};
