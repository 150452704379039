import { takeLatest, put, all, call, select } from "redux-saga/effects";

import SETTING_ACTION_TYPES from "./setting.type";

import {
  appendSettings,
  setFetchSettingFailed,
  setFetchSettingLoading,
  setFetchSettingsFailed,
  setFetchSettingsLoading,
  setFetchSettingsPage,
  setFetchSettingsSuccess,
  setFetchSettingSuccess,
  setIsSettingsHasMore,
  setSetting,
  setSettings,
  setUpdateSettingFailed,
  setUpdateSettingLoading,
  setUpdateSettingSuccess,
} from "./setting.action";
import { getFetchSettingsPage, getFetchSettingsPerPage } from "./setting.selector";

import { getSettings, getSetting, updateSetting } from "../../api/setting.api";

export function* _getSettings() {
  try {
    yield put(setFetchSettingsLoading(true));

    const page = yield select(getFetchSettingsPage);
    const per_page = yield select(getFetchSettingsPerPage);

    const parameters = { page, per_page };

    const {
      meta: { message },
      data: { data: settings },
    } = yield call(getSettings, parameters);

    yield put(setIsSettingsHasMore(settings.length > 0));

    if (page > 1) {
      yield put(appendSettings(settings));
    } else {
      yield put(setSettings(settings));
    }

    yield put(setFetchSettingsSuccess(message));
    yield put(setFetchSettingsLoading(false));
  } catch (error) {
    yield put(setFetchSettingsFailed(error));
    yield put(setFetchSettingsLoading(false));
  }
}

export function* _getSetting({ payload: key }) {
  try {
    yield put(setFetchSettingLoading(true));

    const {
      meta: { message },
      data: setting,
    } = yield call(getSetting, key);

    yield put(setSetting(setting));

    yield put(setFetchSettingSuccess(message));
    yield put(setFetchSettingLoading(false));
  } catch (error) {
    yield put(setFetchSettingFailed(error));
    yield put(setFetchSettingLoading(false));
  }
}

export function* _updateSetting({ payload: { key, request } }) {
  try {
    yield put(setUpdateSettingLoading(true));

    const {
      meta: { message },
    } = yield call(updateSetting, key, request);

    yield put(setUpdateSettingSuccess(message));
    yield put(setUpdateSettingLoading(false));

    yield put(setFetchSettingsPage(1));
    yield call(_getSettings);
  } catch (error) {
    yield put(setUpdateSettingFailed(error));
    yield put(setUpdateSettingLoading(false));
  }
}

export function* onFetchSettingsStart() {
  yield takeLatest(SETTING_ACTION_TYPES.FETCH_SETTINGS_START, _getSettings);
}

export function* onFetchSettingStart() {
  yield takeLatest(SETTING_ACTION_TYPES.FETCH_SETTING_START, _getSetting);
}

export function* onUpdateSettingStart() {
  yield takeLatest(SETTING_ACTION_TYPES.UPDATE_SETTING_START, _updateSetting);
}

export function* settingSaga() {
  yield all([call(onFetchSettingsStart), call(onFetchSettingStart), call(onUpdateSettingStart)]);
}
