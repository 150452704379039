import { createSelector } from "reselect";

const exportPdfSelector = ({ exportPdf }) => exportPdf;

export const getExportPdfOrderInvoice = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderInvoice }) => exportPdfOrderInvoice
);
export const getExportPdfOrderKitchen = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderKitchen }) => exportPdfOrderKitchen
);

export const getExportPdfOrderInvoiceLoading = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderInvoiceLoading }) => exportPdfOrderInvoiceLoading
);
export const getExportPdfOrderInvoiceSuccess = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderInvoiceSuccess }) => exportPdfOrderInvoiceSuccess
);
export const getExportPdfOrderInvoiceFailed = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderInvoiceFailed }) => exportPdfOrderInvoiceFailed
);

export const getExportPdfOrderKitchenLoading = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderKitchenLoading }) => exportPdfOrderKitchenLoading
);
export const getExportPdfOrderKitchenSuccess = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderKitchenSuccess }) => exportPdfOrderKitchenSuccess
);
export const getExportPdfOrderKitchenFailed = createSelector(
  [exportPdfSelector],
  ({ exportPdfOrderKitchenFailed }) => exportPdfOrderKitchenFailed
);

export const getIsExportPdfOrderInvoiceHitted = createSelector(
  [exportPdfSelector],
  ({ isExportPdfOrderInvoiceHitted }) => isExportPdfOrderInvoiceHitted
);
export const getIsExportPdfOrderKitchenHitted = createSelector(
  [exportPdfSelector],
  ({ isExportPdfOrderKitchenHitted }) => isExportPdfOrderKitchenHitted
);
