import { takeLatest, put, all, call, select } from "redux-saga/effects";

import GLOBAL_ACTION_TYPES from "./global.type";

import { USER_ROLES } from "../../constants/user.constant";

import {
  setCurrentBranch,
  setCurrentExtendPeriods,
  setCurrentMarket,
  setCurrentMerchant,
  setCurrentPermission,
  setCurrentSchedules,
  setFetchCurrentBranchFailed,
  setFetchCurrentBranchLoading,
  setFetchCurrentBranchSuccess,
  setFetchCurrentExtendPeriodsFailed,
  setFetchCurrentExtendPeriodsLoading,
  setFetchCurrentExtendPeriodsSuccess,
  setFetchCurrentMarketFailed,
  setFetchCurrentMarketLoading,
  setFetchCurrentMarketSuccess,
  setFetchCurrentMerchantFailed,
  setFetchCurrentMerchantLoading,
  setFetchCurrentMerchantSuccess,
  setFetchCurrentPermissionFailed,
  setFetchCurrentPermissionLoading,
  setFetchCurrentPermissionSuccess,
  setFetchCurrentSchedulesFailed,
  setFetchCurrentSchedulesLoading,
  setFetchCurrentSchedulesSuccess,
  setFetchTodayAttendanceFailed,
  setFetchTodayAttendanceLoading,
  setFetchTodayAttendanceSuccess,
  setIsFetchCurrentBranchHitted,
  setIsFetchCurrentExtendPeriodsHitted,
  setIsFetchCurrentMarketHitted,
  setIsFetchCurrentMerchantHitted,
  setIsFetchCurrentPermissionHitted,
  setIsFetchCurrentSchedulesHitted,
  setIsFetchTodayAttendanceHitted,
  setTodayAttendance,
} from "./global.action";
import {
  getFetchCurrentExtendPeriodsFilterBranchId,
  getFetchCurrentExtendPeriodsFilterEndAtAfter,
  getFetchCurrentExtendPeriodsFilterEndAtBefore,
  getFetchCurrentExtendPeriodsFilterMarketId,
  getFetchCurrentExtendPeriodsFilterStartAtAfter,
  getFetchCurrentExtendPeriodsFilterStartAtBefore,
  getFetchCurrentExtendPeriodsFilterStatus,
  getFetchCurrentExtendPeriodsIncludes,
  getFetchCurrentExtendPeriodsKeyBy,
  getFetchCurrentExtendPeriodsPage,
  getFetchCurrentExtendPeriodsPerPage,
  getFetchCurrentExtendPeriodsSearch,
  getFetchCurrentSchedulesFilterBranchId,
  getFetchCurrentSchedulesFilterMarketId,
  getFetchCurrentSchedulesIncludes,
  getFetchTodayAttendanceBranchId,
  getFetchTodayAttendanceMarketId,
} from "./global.selector";

import { getSchedules } from "../../api/schedule.api";
import { getExtendPeriods } from "../../api/extend-period.api";
import { getAttendance } from "../../api/attendance.api";
import { getMarket } from "../../api/market.api";
import { getBranch } from "../../api/branch.api";
import { getMerchant } from "../../api/merchant.api";
import { getPermission } from "../../api/permission.api";
import { getPermissionAdmin } from "../../api/permission-admin.api";
import { getPermissionMerchant } from "../../api/permission-merchant.api";
import { getPermissionSubscriber } from "../../api/permission-subscriber.api";

export function* _getCurrentMarket({ payload: marketId }) {
  try {
    yield put(setFetchCurrentMarketLoading(true));

    const {
      meta: { message },
      data: market,
    } = yield call(getMarket, marketId);

    yield put(setIsFetchCurrentMarketHitted(true));
    yield put(setCurrentMarket(market));

    yield put(setFetchCurrentMarketSuccess(message));
    yield put(setFetchCurrentMarketLoading(false));
  } catch (error) {
    yield put(setFetchCurrentMarketFailed(error));
    yield put(setFetchCurrentMarketLoading(false));
  }
}
export function* _getCurrentBranch({ payload: branchId }) {
  try {
    yield put(setFetchCurrentBranchLoading(true));

    const {
      meta: { message },
      data: branch,
    } = yield call(getBranch, branchId);

    yield put(setIsFetchCurrentBranchHitted(true));
    yield put(setCurrentBranch(branch));

    yield put(setFetchCurrentBranchSuccess(message));
    yield put(setFetchCurrentBranchLoading(false));
  } catch (error) {
    yield put(setFetchCurrentBranchFailed(error));
    yield put(setFetchCurrentBranchLoading(false));
  }
}
export function* _getCurrentMerchant({ payload: merchantId }) {
  try {
    yield put(setFetchCurrentMerchantLoading(true));

    const {
      meta: { message },
      data: merchant,
    } = yield call(getMerchant, merchantId);

    yield put(setIsFetchCurrentMerchantHitted(true));
    yield put(setCurrentMerchant(merchant));

    yield put(setFetchCurrentMerchantSuccess(message));
    yield put(setFetchCurrentMerchantLoading(false));
  } catch (error) {
    yield put(setFetchCurrentMerchantFailed(error));
    yield put(setFetchCurrentMerchantLoading(false));
  }
}
export function* _getCurrentPermission({ payload: { role, permissionId } }) {
  try {
    yield put(setFetchCurrentPermissionLoading(true));

    const {
      meta: { message },
      data: permission,
    } = yield call(
      role === USER_ROLES.USER_ADMIN
        ? getPermissionAdmin
        : role === USER_ROLES.USER_MERCHANT
        ? getPermissionMerchant
        : role === USER_ROLES.USER_SUBSCRIBER
        ? getPermissionSubscriber
        : getPermission,
      permissionId
    );

    yield put(setIsFetchCurrentPermissionHitted(true));
    yield put(setCurrentPermission(permission));

    yield put(setFetchCurrentPermissionSuccess(message));
    yield put(setFetchCurrentPermissionLoading(false));
  } catch (error) {
    yield put(setFetchCurrentPermissionFailed(error));
    yield put(setFetchCurrentPermissionLoading(false));
  }
}
export function* _getCurrentSchedules() {
  try {
    yield put(setFetchCurrentSchedulesLoading(true));

    const includes = yield select(getFetchCurrentSchedulesIncludes);
    const market_id = yield select(getFetchCurrentSchedulesFilterMarketId);
    const branch_id = yield select(getFetchCurrentSchedulesFilterBranchId);

    const parameters = {
      includes,
      filter: { market_id, branch_id },
    };

    const {
      meta: { message },
      data: schedules,
    } = yield call(getSchedules, parameters);

    yield put(setIsFetchCurrentSchedulesHitted(true));
    yield put(setCurrentSchedules(schedules));

    yield put(setFetchCurrentSchedulesSuccess(message));
    yield put(setFetchCurrentSchedulesLoading(false));
  } catch (error) {
    yield put(setFetchCurrentSchedulesFailed(error));
    yield put(setFetchCurrentSchedulesLoading(false));
  }
}
export function* _getCurrentExtendPeriods() {
  try {
    yield put(setFetchCurrentExtendPeriodsLoading(true));

    const search = yield select(getFetchCurrentExtendPeriodsSearch);
    const page = yield select(getFetchCurrentExtendPeriodsPage);
    const per_page = yield select(getFetchCurrentExtendPeriodsPerPage);
    const key_by = yield select(getFetchCurrentExtendPeriodsKeyBy);
    const includes = yield select(getFetchCurrentExtendPeriodsIncludes);
    const market_id = yield select(getFetchCurrentExtendPeriodsFilterMarketId);
    const branch_id = yield select(getFetchCurrentExtendPeriodsFilterBranchId);
    const status = yield select(getFetchCurrentExtendPeriodsFilterStatus);
    const start_at_before = yield select(
      getFetchCurrentExtendPeriodsFilterStartAtBefore
    );
    const start_at_after = yield select(
      getFetchCurrentExtendPeriodsFilterStartAtAfter
    );
    const end_at_before = yield select(
      getFetchCurrentExtendPeriodsFilterEndAtBefore
    );
    const end_at_after = yield select(
      getFetchCurrentExtendPeriodsFilterEndAtAfter
    );

    const parameters = {
      search,
      page,
      per_page,
      key_by,
      includes,
      filter: {
        market_id,
        branch_id,
        status,
        start_at_before,
        start_at_after,
        end_at_before,
        end_at_after,
      },
    };

    const {
      meta: { message },
      data: { data: extendPeriods },
    } = yield call(getExtendPeriods, parameters);

    yield put(setIsFetchCurrentExtendPeriodsHitted(true));
    yield put(setCurrentExtendPeriods(extendPeriods));

    yield put(setFetchCurrentExtendPeriodsSuccess(message));
    yield put(setFetchCurrentExtendPeriodsLoading(false));
  } catch (error) {
    yield put(setFetchCurrentExtendPeriodsFailed(error));
    yield put(setFetchCurrentExtendPeriodsLoading(false));
  }
}
export function* _getTodayAttendance({ payload: userId }) {
  try {
    yield put(setFetchTodayAttendanceLoading(true));

    const market_id = yield select(getFetchTodayAttendanceMarketId);
    const branch_id = yield select(getFetchTodayAttendanceBranchId);

    const parameters = { market_id, branch_id };

    const {
      meta: { message },
      data: attendance,
    } = yield call(getAttendance, userId, parameters);

    yield put(setIsFetchTodayAttendanceHitted(true));
    yield put(setTodayAttendance(attendance));

    yield put(setFetchTodayAttendanceSuccess(message));
    yield put(setFetchTodayAttendanceLoading(false));
  } catch (error) {
    yield put(setFetchTodayAttendanceFailed(error));
    yield put(setFetchTodayAttendanceLoading(false));
  }
}

export function* onFetchCurrentMarketStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_MARKET_START,
    _getCurrentMarket
  );
}
export function* onFetchCurrentBranchStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_BRANCH_START,
    _getCurrentBranch
  );
}
export function* onFetchCurrentMerchantStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_MERCHANT_START,
    _getCurrentMerchant
  );
}
export function* onFetchCurrentPermissionStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_PERMISSION_START,
    _getCurrentPermission
  );
}
export function* onFetchCurrentSchedulesStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_SCHEDULES_START,
    _getCurrentSchedules
  );
}
export function* onFetchCurrentExtendPeriodsStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_EXTEND_PERIODS_START,
    _getCurrentExtendPeriods
  );
}
export function* onFetchTodayAttendanceStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_TODAY_ATTENDANCE_START,
    _getTodayAttendance
  );
}

export function* globalSaga() {
  yield all([
    call(onFetchCurrentMarketStart),
    call(onFetchCurrentBranchStart),
    call(onFetchCurrentMerchantStart),
    call(onFetchCurrentPermissionStart),
    call(onFetchCurrentSchedulesStart),
    call(onFetchCurrentExtendPeriodsStart),
    call(onFetchTodayAttendanceStart),
  ]);
}
