import { createSelector } from "reselect";

const settingSelector = ({ setting }) => setting;

export const getSettings = createSelector([settingSelector], ({ settings }) => settings);

export const getSetting = createSelector([settingSelector], ({ setting }) => setting);

export const getIsSettingsHasMore = createSelector([settingSelector], ({ isSettingsHasMore }) => isSettingsHasMore);

export const getFetchSettingsPage = createSelector([settingSelector], ({ fetchSettingsPage }) => fetchSettingsPage);

export const getFetchSettingsPerPage = createSelector(
  [settingSelector],
  ({ fetchSettingsPerPage }) => fetchSettingsPerPage
);

export const getFetchSettingsLoading = createSelector(
  [settingSelector],
  ({ fetchSettingsLoading }) => fetchSettingsLoading
);

export const getFetchSettingsSuccess = createSelector(
  [settingSelector],
  ({ fetchSettingsSuccess }) => fetchSettingsSuccess
);

export const getFetchSettingsFailed = createSelector(
  [settingSelector],
  ({ fetchSettingsFailed }) => fetchSettingsFailed
);

export const getFetchSettingLoading = createSelector(
  [settingSelector],
  ({ fetchSettingLoading }) => fetchSettingLoading
);

export const getFetchSettingSuccess = createSelector(
  [settingSelector],
  ({ fetchSettingSuccess }) => fetchSettingSuccess
);

export const getFetchSettingFailed = createSelector([settingSelector], ({ fetchSettingFailed }) => fetchSettingFailed);

export const getUpdateSettingLoading = createSelector(
  [settingSelector],
  ({ updateSettingLoading }) => updateSettingLoading
);

export const getUpdateSettingSuccess = createSelector(
  [settingSelector],
  ({ updateSettingSuccess }) => updateSettingSuccess
);

export const getUpdateSettingFailed = createSelector(
  [settingSelector],
  ({ updateSettingFailed }) => updateSettingFailed
);
