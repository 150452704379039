import GLOBAL_ACTION_TYPES from "./global.type";
import { createAction } from "../../utils/store.utils";

export const setIsRightToLeft = (isRightToLeft) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_RIGHT_TO_LEFT, isRightToLeft);
export const setIsGlobalLoading = (isGlobalLoading) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_LOADING, isGlobalLoading);

export const setAuthorizePages = (authorizePages) =>
  createAction(GLOBAL_ACTION_TYPES.SET_AUTHORIZE_PAGES, authorizePages);
export const setIsMarketAdministrator = (isMarketAdministrator) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_MARKET_ADMINISTRATOR,
    isMarketAdministrator
  );
export const setIsCenterAdministrator = (isCenterAdministrator) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_CENTER_ADMINISTRATOR,
    isCenterAdministrator
  );

export const setCurrentMarket = (currentMarket) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET, currentMarket);
export const setCurrentBranch = (currentBranch) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH, currentBranch);
export const setCurrentMerchant = (currentMerchant) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT, currentMerchant);
export const setCurrentPermission = (currentPermission) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_PERMISSION, currentPermission);
export const setCurrentSchedules = (currentSchedules) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_SCHEDULES, currentSchedules);
export const setCurrentExtendPeriods = (currentExtendPeriods) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_CURRENT_EXTEND_PERIODS,
    currentExtendPeriods
  );

export const setCurrentMarketId = (currentMarketId) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET_ID, currentMarketId);
export const setCurrentBranchId = (currentBranchId) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH_ID, currentBranchId);
export const setCurrentMerchantId = (currentMerchantId) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT_ID, currentMerchantId);
export const setCurrentPermissionId = (currentPermissionId) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_CURRENT_PERMISSION_ID,
    currentPermissionId
  );
export const setIsCurrentKitchenActive = (isCurrentKitchenActive) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_CURRENT_KITCHEN_ACTIVE,
    isCurrentKitchenActive
  );

export const setTodaySchedule = (todaySchedule) =>
  createAction(GLOBAL_ACTION_TYPES.SET_TODAY_SCHEDULE, todaySchedule);
export const setIsTodayInSchedule = (isTodayInSchedule) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_TODAY_IN_SCHEDULE, isTodayInSchedule);

export const setTodayAttendance = (todayAttendance) =>
  createAction(GLOBAL_ACTION_TYPES.SET_TODAY_ATTENDANCE, todayAttendance);
export const setIsTodayHasAttendance = (isTodayHasAttendance) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_TODAY_HAS_ATTENDANCE,
    isTodayHasAttendance
  );

export const setActiveSubscription = (activeSubscription) =>
  createAction(GLOBAL_ACTION_TYPES.SET_ACTIVE_SUBSCRIPTION, activeSubscription);
export const setIsSubscription = (isSubscription) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_SUBSCRIPTION, isSubscription);
export const setIsAdvancedSubscription = (isAdvancedSubscription) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_ADVANCED_SUBSCRIPTION,
    isAdvancedSubscription
  );
export const setIsHasSubscription = (isHasSubscription) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_HAS_SUBSCRIPTION, isHasSubscription);
export const setIsHasAdvancedSubscription = (isHasAdvancedSubscription) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_HAS_ADVANCED_SUBSCRIPTION,
    isHasAdvancedSubscription
  );

export const setFetchCurrentMarketLoading = (fetchCurrentMarketLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_LOADING,
    fetchCurrentMarketLoading
  );
export const setFetchCurrentMarketSuccess = (fetchCurrentMarketSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_SUCCESS,
    fetchCurrentMarketSuccess
  );
export const setFetchCurrentMarketFailed = (fetchCurrentMarketFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_FAILED,
    fetchCurrentMarketFailed
  );

export const setFetchCurrentBranchLoading = (fetchCurrentBranchLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_LOADING,
    fetchCurrentBranchLoading
  );
export const setFetchCurrentBranchSuccess = (fetchCurrentBranchSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_SUCCESS,
    fetchCurrentBranchSuccess
  );
export const setFetchCurrentBranchFailed = (fetchCurrentBranchFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_FAILED,
    fetchCurrentBranchFailed
  );

export const setFetchCurrentMerchantLoading = (fetchCurrentMerchantLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_LOADING,
    fetchCurrentMerchantLoading
  );
export const setFetchCurrentMerchantSuccess = (fetchCurrentMerchantSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_SUCCESS,
    fetchCurrentMerchantSuccess
  );
export const setFetchCurrentMerchantFailed = (fetchCurrentMerchantFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_FAILED,
    fetchCurrentMerchantFailed
  );

export const setFetchCurrentPermissionLoading = (
  fetchCurrentPermissionLoading
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_LOADING,
    fetchCurrentPermissionLoading
  );
export const setFetchCurrentPermissionSuccess = (
  fetchCurrentPermissionSuccess
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_SUCCESS,
    fetchCurrentPermissionSuccess
  );
export const setFetchCurrentPermissionFailed = (fetchCurrentPermissionFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_FAILED,
    fetchCurrentPermissionFailed
  );

export const setFetchCurrentSchedulesIncludes = (
  fetchCurrentSchedulesIncludes
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_INCLUDES,
    fetchCurrentSchedulesIncludes
  );
export const setFetchCurrentSchedulesFilterMarketId = (
  fetchCurrentSchedulesFilterMarketId
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_MARKET_ID,
    fetchCurrentSchedulesFilterMarketId
  );
export const setFetchCurrentSchedulesFilterBranchId = (
  fetchCurrentSchedulesFilterBranchId
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_BRANCH_ID,
    fetchCurrentSchedulesFilterBranchId
  );
export const setFetchCurrentSchedulesLoading = (fetchCurrentSchedulesLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_LOADING,
    fetchCurrentSchedulesLoading
  );
export const setFetchCurrentSchedulesSuccess = (fetchCurrentSchedulesSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_SUCCESS,
    fetchCurrentSchedulesSuccess
  );
export const setFetchCurrentSchedulesFailed = (fetchCurrentSchedulesFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FAILED,
    fetchCurrentSchedulesFailed
  );

export const setFetchCurrentExtendPeriodsSearch = (
  fetchCurrentExtendPeriodsSearch
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_SEARCH,
    fetchCurrentExtendPeriodsSearch
  );
export const setFetchCurrentExtendPeriodsPage = (
  fetchCurrentExtendPeriodsPage
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_PAGE,
    fetchCurrentExtendPeriodsPage
  );
export const setFetchCurrentExtendPeriodsPerPage = (
  fetchCurrentExtendPeriodsPerPage
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_PER_PAGE,
    fetchCurrentExtendPeriodsPerPage
  );
export const setFetchCurrentExtendPeriodsKeyBy = (
  fetchCurrentExtendPeriodsKeyBy
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_KEY_BY,
    fetchCurrentExtendPeriodsKeyBy
  );
export const setFetchCurrentExtendPeriodsIncludes = (
  fetchCurrentExtendPeriodsIncludes
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_INCLUDES,
    fetchCurrentExtendPeriodsIncludes
  );
export const setFetchCurrentExtendPeriodsFilterMarketId = (
  fetchCurrentExtendPeriodsFilterMarketId
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_MARKET_ID,
    fetchCurrentExtendPeriodsFilterMarketId
  );
export const setFetchCurrentExtendPeriodsFilterBranchId = (
  fetchCurrentExtendPeriodsFilterBranchId
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_BRANCH_ID,
    fetchCurrentExtendPeriodsFilterBranchId
  );
export const setFetchCurrentExtendPeriodsFilterStatus = (
  fetchCurrentExtendPeriodsFilterStatus
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_STATUS,
    fetchCurrentExtendPeriodsFilterStatus
  );
export const setFetchCurrentExtendPeriodsFilterStartAtBefore = (
  fetchCurrentExtendPeriodsFilterStartAtBefore
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_START_AT_BEFORE,
    fetchCurrentExtendPeriodsFilterStartAtBefore
  );
export const setFetchCurrentExtendPeriodsFilterStartAtAfter = (
  fetchCurrentExtendPeriodsFilterStartAtAfter
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_START_AT_AFTER,
    fetchCurrentExtendPeriodsFilterStartAtAfter
  );
export const setFetchCurrentExtendPeriodsFilterEndAtBefore = (
  fetchCurrentExtendPeriodsFilterEndAtBefore
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_END_AT_BEFORE,
    fetchCurrentExtendPeriodsFilterEndAtBefore
  );
export const setFetchCurrentExtendPeriodsFilterEndAtAfter = (
  fetchCurrentExtendPeriodsFilterEndAtAfter
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_END_AT_AFTER,
    fetchCurrentExtendPeriodsFilterEndAtAfter
  );
export const setFetchCurrentExtendPeriodsFilterCreatedBy = (
  fetchCurrentExtendPeriodsFilterCreatedBy
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FILTER_CREATED_BY,
    fetchCurrentExtendPeriodsFilterCreatedBy
  );
export const setFetchCurrentExtendPeriodsLoading = (
  fetchCurrentExtendPeriodsLoading
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_LOADING,
    fetchCurrentExtendPeriodsLoading
  );
export const setFetchCurrentExtendPeriodsSuccess = (
  fetchCurrentExtendPeriodsSuccess
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_SUCCESS,
    fetchCurrentExtendPeriodsSuccess
  );
export const setFetchCurrentExtendPeriodsFailed = (
  fetchCurrentExtendPeriodsFailed
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_EXTEND_PERIODS_FAILED,
    fetchCurrentExtendPeriodsFailed
  );

export const setFetchTodayAttendanceMarketId = (fetchTodayAttendanceMarketId) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_MARKET_ID,
    fetchTodayAttendanceMarketId
  );
export const setFetchTodayAttendanceBranchId = (fetchTodayAttendanceBranchId) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_BRANCH_ID,
    fetchTodayAttendanceBranchId
  );
export const setFetchTodayAttendanceLoading = (fetchTodayAttendanceLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_LOADING,
    fetchTodayAttendanceLoading
  );
export const setFetchTodayAttendanceSuccess = (fetchTodayAttendanceSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_SUCCESS,
    fetchTodayAttendanceSuccess
  );
export const setFetchTodayAttendanceFailed = (fetchTodayAttendanceFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_FAILED,
    fetchTodayAttendanceFailed
  );

export const setIsFetchCurrentMarketHitted = (isFetchCurrentMarketHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MARKET_HITTED,
    isFetchCurrentMarketHitted
  );
export const setIsFetchCurrentBranchHitted = (isFetchCurrentBranchHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_BRANCH_HITTED,
    isFetchCurrentBranchHitted
  );
export const setIsFetchCurrentMerchantHitted = (isFetchCurrentMerchantHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MERCHANT_HITTED,
    isFetchCurrentMerchantHitted
  );
export const setIsFetchCurrentPermissionHitted = (
  isFetchCurrentPermissionHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_PERMISSION_HITTED,
    isFetchCurrentPermissionHitted
  );
export const setIsFetchCurrentSchedulesHitted = (
  isFetchCurrentSchedulesHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_SCHEDULES_HITTED,
    isFetchCurrentSchedulesHitted
  );
export const setIsFetchCurrentExtendPeriodsHitted = (
  isFetchCurrentExtendPeriodsHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_EXTEND_PERIODS_HITTED,
    isFetchCurrentExtendPeriodsHitted
  );
export const setIsFetchTodayAttendanceHitted = (isFetchTodayAttendanceHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_TODAY_ATTENDANCE_HITTED,
    isFetchTodayAttendanceHitted
  );

export const fetchCurrentMarketStart = (marketId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_MARKET_START, marketId);
export const fetchCurrentBranchStart = (branchId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_BRANCH_START, branchId);
export const fetchCurrentMerchantStart = (merchantId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_MERCHANT_START, merchantId);
export const fetchCurrentPermissionStart = (role, permissionId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_PERMISSION_START, {
    role,
    permissionId,
  });
export const fetchCurrentSchedulesStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_SCHEDULES_START);
export const fetchCurrentExtendPeriodsStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_EXTEND_PERIODS_START);
export const fetchTodayAttendanceStart = (userId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_TODAY_ATTENDANCE_START, userId);

export const resetGlobalReducer = () =>
  createAction(GLOBAL_ACTION_TYPES.RESET_GLOBAL_REDUCER);
