import SUBSCRIPTION_ACTION_TYPES from "./subscription.type";
import { createAction } from "../../utils/store.utils";

export const setIsModalAutoClose = (isModalAutoClose) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE, isModalAutoClose);

export const setSubscriptions = (subscriptions) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTIONS, subscriptions);

export const setSubscription = (subscription) => createAction(SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION, subscription);

export const setExtendSubscription = (extendSubscription) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_EXTEND_SUBSCRIPTION, extendSubscription);

export const setExtendSubscriptionKey = (extendSubscriptionKey) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_EXTEND_SUBSCRIPTION_KEY, extendSubscriptionKey);

export const setExtendSubscriptionOptionKey = (extendSubscriptionOptionKey) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_EXTEND_SUBSCRIPTION_OPTION_KEY, extendSubscriptionOptionKey);

export const setIsSubscriptionsHasMore = (isSubscriptionsHasMore) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_IS_SUBSCRIPTIONS_HAS_MORE, isSubscriptionsHasMore);

export const setFetchSubscriptionsSearch = (fetchSubscriptionsSearch) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_SEARCH, fetchSubscriptionsSearch);

export const setFetchSubscriptionsPage = (fetchSubscriptionsPage) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_PAGE, fetchSubscriptionsPage);

export const setFetchSubscriptionsPerPage = (fetchSubscriptionsPerPage) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_PER_PAGE, fetchSubscriptionsPerPage);

export const setFetchSubscriptionsLoading = (fetchSubscriptionsLoading) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_LOADING, fetchSubscriptionsLoading);

export const setFetchSubscriptionsSuccess = (fetchSubscriptionsSuccess) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_SUCCESS, fetchSubscriptionsSuccess);

export const setFetchSubscriptionsFailed = (fetchSubscriptionsFailed) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTIONS_FAILED, fetchSubscriptionsFailed);

export const setFetchSubscriptionLoading = (fetchSubscriptionLoading) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_LOADING, fetchSubscriptionLoading);

export const setFetchSubscriptionSuccess = (fetchSubscriptionSuccess) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_SUCCESS, fetchSubscriptionSuccess);

export const setFetchSubscriptionFailed = (fetchSubscriptionFailed) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_FETCH_SUBSCRIPTION_FAILED, fetchSubscriptionFailed);

export const setCreateSubscriptionLoading = (createSubscriptionLoading) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_CREATE_SUBSCRIPTION_LOADING, createSubscriptionLoading);

export const setCreateSubscriptionSuccess = (createSubscriptionSuccess) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_CREATE_SUBSCRIPTION_SUCCESS, createSubscriptionSuccess);

export const setCreateSubscriptionFailed = (createSubscriptionFailed) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_CREATE_SUBSCRIPTION_FAILED, createSubscriptionFailed);

export const setUpdateSubscriptionLoading = (updateSubscriptionLoading) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_LOADING, updateSubscriptionLoading);

export const setUpdateSubscriptionSuccess = (updateSubscriptionSuccess) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_SUCCESS, updateSubscriptionSuccess);

export const setUpdateSubscriptionFailed = (updateSubscriptionFailed) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_UPDATE_SUBSCRIPTION_FAILED, updateSubscriptionFailed);

export const setDeleteSubscriptionLoading = (deleteSubscriptionLoading) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_DELETE_SUBSCRIPTION_LOADING, deleteSubscriptionLoading);

export const setDeleteSubscriptionSuccess = (deleteSubscriptionSuccess) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_DELETE_SUBSCRIPTION_SUCCESS, deleteSubscriptionSuccess);

export const setDeleteSubscriptionFailed = (deleteSubscriptionFailed) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_DELETE_SUBSCRIPTION_FAILED, deleteSubscriptionFailed);

export const appendSubscriptions = (subscriptions) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.APPEND_SUBSCRIPTIONS, subscriptions);

export const fetchSubscriptionsStart = () => createAction(SUBSCRIPTION_ACTION_TYPES.FETCH_SUBSCRIPTIONS_START);

export const fetchSubscriptionStart = (key) => createAction(SUBSCRIPTION_ACTION_TYPES.FETCH_SUBSCRIPTION_START, key);

export const createSubscriptionStart = (request) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.CREATE_SUBSCRIPTION_START, request);

export const updateSubscriptionStart = (key, request) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.UPDATE_SUBSCRIPTION_START, { key, request });

export const deleteSubscriptionStart = (key) => createAction(SUBSCRIPTION_ACTION_TYPES.DELETE_SUBSCRIPTION_START, key);

export const setIsFetchSubscriptionsHitted = (isFetchSubscriptionsHitted) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTIONS_HITTED, isFetchSubscriptionsHitted);

export const setIsFetchSubscriptionHitted = (isFetchSubscriptionHitted) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_IS_FETCH_SUBSCRIPTION_HITTED, isFetchSubscriptionHitted);

export const setIsCreateSubscriptionHitted = (isCreateSubscriptionHitted) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_IS_CREATE_SUBSCRIPTION_HITTED, isCreateSubscriptionHitted);

export const setIsUpdateSubscriptionHitted = (isUpdateSubscriptionHitted) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_IS_UPDATE_SUBSCRIPTION_HITTED, isUpdateSubscriptionHitted);

export const setIsDeleteSubscriptionHitted = (isDeleteSubscriptionHitted) =>
  createAction(SUBSCRIPTION_ACTION_TYPES.SET_IS_DELETE_SUBSCRIPTION_HITTED, isDeleteSubscriptionHitted);

export const resetSubscriptionReducer = () => createAction(SUBSCRIPTION_ACTION_TYPES.RESET_SUBSCRIPTION_REDUCER);
