import apiService from "./api";

export const getExtendPeriods = async (parameters) =>
  (await apiService.get("/v2/extend-periods", parameters)).data;
export const getExtendPeriod = async (id) =>
  (await apiService.get(`/v2/extend-periods/${id}`)).data;
export const createExtendPeriod = async (request) =>
  (await apiService.post("/v2/extend-periods", request)).data;
export const customExtendPeriod = async (request) =>
  (await apiService.post("/v2/extend-periods/custom", request)).data;
export const stopExtendPeriod = async (request) =>
  (await apiService.post("/v2/extend-periods/stop", request)).data;
