import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { LANGUAGES, LANGUAGE_RESOURCES } from "./constants/common.constant";

const resources = LANGUAGE_RESOURCES.reduce((langResources, { code, json }) => {
  return { ...langResources, [code]: { translation: json } };
}, {});

const detection = {
  order: ["querystring", "localStorage"],
  lookupQuerystring: "lng",
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: LANGUAGES.EN,
    fallbackLng: LANGUAGES.EN,
    resources,
    detection,
    debug: true,
    interpolation: { escapeValue: false },
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i"],
  });

export default i18next;
