import { takeLatest, put, all, call, select } from "redux-saga/effects";

import REPORT_ACTION_TYPES from "./report.type";

import {
  setFetchNextReportTotalsFailed,
  setFetchNextReportTotalsLoading,
  setFetchNextReportTotalsSuccess,
  setFetchPrevReportTotalsFailed,
  setFetchPrevReportTotalsLoading,
  setFetchPrevReportTotalsSuccess,
  setFetchReportCashiersFailed,
  setFetchReportCashiersLoading,
  setFetchReportCashiersSuccess,
  setFetchReportCategoriesFailed,
  setFetchReportCategoriesLoading,
  setFetchReportCategoriesSuccess,
  setFetchReportPaymentsFailed,
  setFetchReportPaymentsLoading,
  setFetchReportPaymentsSuccess,
  setFetchReportProductsFailed,
  setFetchReportProductsLoading,
  setFetchReportProductsSuccess,
  setIsFetchNextReportTotalsHitted,
  setIsFetchPrevReportTotalsHitted,
  setIsFetchReportCashiersHitted,
  setIsFetchReportCategoriesHitted,
  setIsFetchReportPaymentsHitted,
  setIsFetchReportProductsHitted,
  setNextReportTotals,
  setPrevReportTotals,
  setReportCashiers,
  setReportCategories,
  setReportPayments,
  setReportProducts,
} from "./report.action";
import {
  getFetchNextReportTotalsFilterCreatedAtAfter,
  getFetchNextReportTotalsFilterCreatedAtBefore,
  getFetchPrevReportTotalsFilterCreatedAtAfter,
  getFetchPrevReportTotalsFilterCreatedAtBefore,
  getFetchReportCashiersKeyBy,
  getFetchReportCashiersPage,
  getFetchReportCashiersPerPage,
  getFetchReportCashiersSearch,
  getFetchReportCashiersSort,
  getFetchReportCategoriesFilterProductStatuses,
  getFetchReportCategoriesKeyBy,
  getFetchReportCategoriesPage,
  getFetchReportCategoriesPerPage,
  getFetchReportCategoriesSearch,
  getFetchReportCategoriesSort,
  getFetchReportPaymentsFilterPaymentStatuses,
  getFetchReportPaymentsKeyBy,
  getFetchReportPaymentsPage,
  getFetchReportPaymentsPerPage,
  getFetchReportPaymentsSearch,
  getFetchReportPaymentsSort,
  getFetchReportProductsFilterProductStatuses,
  getFetchReportProductsKeyBy,
  getFetchReportProductsPage,
  getFetchReportProductsPerPage,
  getFetchReportProductsSearch,
  getFetchReportProductsSort,
  getFetchReportsFilterBranchId,
  getFetchReportsFilterCashierId,
  getFetchReportsFilterCreatedAtAfter,
  getFetchReportsFilterCreatedAtBefore,
  getFetchReportsFilterCustomerId,
  getFetchReportsFilterMarketId,
  getFetchReportsFilterOrderStatuses,
  getFetchReportsFilterTransactionStatuses,
} from "./report.selector";

import {
  getReportCashiers,
  getReportCategories,
  getReportPayments,
  getReportProducts,
  getReportTotals,
} from "../../api/report.api";

export function* _getPrevReportTotals() {
  try {
    yield put(setFetchPrevReportTotalsLoading(true));

    const market_id = yield select(getFetchReportsFilterMarketId);
    const branch_id = yield select(getFetchReportsFilterBranchId);
    const cashier_id = yield select(getFetchReportsFilterCashierId);
    const customer_id = yield select(getFetchReportsFilterCustomerId);
    const order_statuses = yield select(getFetchReportsFilterOrderStatuses);
    const transaction_statuses = yield select(
      getFetchReportsFilterTransactionStatuses
    );

    const created_at_after = yield select(
      getFetchPrevReportTotalsFilterCreatedAtAfter
    );
    const created_at_before = yield select(
      getFetchPrevReportTotalsFilterCreatedAtBefore
    );

    const parameters = {
      filter: {
        market_id,
        branch_id,
        cashier_id,
        customer_id,
        created_at_before,
        created_at_after,
        order_statuses,
        transaction_statuses,
      },
    };

    const {
      meta: { message },
      data: reportTotals,
    } = yield call(getReportTotals, parameters);

    yield put(setIsFetchPrevReportTotalsHitted(true));
    yield put(setPrevReportTotals(reportTotals));

    yield put(setFetchPrevReportTotalsSuccess(message));
    yield put(setFetchPrevReportTotalsLoading(false));
  } catch (error) {
    yield put(setFetchPrevReportTotalsFailed(error));
    yield put(setFetchPrevReportTotalsLoading(false));
  }
}
export function* _getNextReportTotals() {
  try {
    yield put(setFetchNextReportTotalsLoading(true));

    const market_id = yield select(getFetchReportsFilterMarketId);
    const branch_id = yield select(getFetchReportsFilterBranchId);
    const cashier_id = yield select(getFetchReportsFilterCashierId);
    const customer_id = yield select(getFetchReportsFilterCustomerId);
    const order_statuses = yield select(getFetchReportsFilterOrderStatuses);
    const transaction_statuses = yield select(
      getFetchReportsFilterTransactionStatuses
    );

    const created_at_after = yield select(
      getFetchNextReportTotalsFilterCreatedAtAfter
    );
    const created_at_before = yield select(
      getFetchNextReportTotalsFilterCreatedAtBefore
    );

    const parameters = {
      filter: {
        market_id,
        branch_id,
        cashier_id,
        customer_id,
        created_at_before,
        created_at_after,
        order_statuses,
        transaction_statuses,
      },
    };

    const {
      meta: { message },
      data: reportTotals,
    } = yield call(getReportTotals, parameters);

    yield put(setIsFetchNextReportTotalsHitted(true));
    yield put(setNextReportTotals(reportTotals));

    yield put(setFetchNextReportTotalsSuccess(message));
    yield put(setFetchNextReportTotalsLoading(false));
  } catch (error) {
    yield put(setFetchNextReportTotalsFailed(error));
    yield put(setFetchNextReportTotalsLoading(false));
  }
}
export function* _getReportCategories() {
  try {
    yield put(setFetchReportCategoriesLoading(true));

    const market_id = yield select(getFetchReportsFilterMarketId);
    const branch_id = yield select(getFetchReportsFilterBranchId);
    const cashier_id = yield select(getFetchReportsFilterCashierId);
    const customer_id = yield select(getFetchReportsFilterCustomerId);
    const created_at_after = yield select(getFetchReportsFilterCreatedAtAfter);
    const created_at_before = yield select(
      getFetchReportsFilterCreatedAtBefore
    );
    const order_statuses = yield select(getFetchReportsFilterOrderStatuses);
    const transaction_statuses = yield select(
      getFetchReportsFilterTransactionStatuses
    );

    const search = yield select(getFetchReportCategoriesSearch);
    const sort = yield select(getFetchReportCategoriesSort);
    const key_by = yield select(getFetchReportCategoriesKeyBy);
    const page = yield select(getFetchReportCategoriesPage);
    const per_page = yield select(getFetchReportCategoriesPerPage);
    const product_statuses = yield select(
      getFetchReportCategoriesFilterProductStatuses
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      filter: {
        market_id,
        branch_id,
        cashier_id,
        customer_id,
        created_at_before,
        created_at_after,
        order_statuses,
        transaction_statuses,
        product_statuses,
      },
    };

    const {
      meta: { message },
      data: reportCategories,
    } = yield call(getReportCategories, parameters);

    yield put(setIsFetchReportCategoriesHitted(true));
    yield put(setReportCategories(reportCategories));

    yield put(setFetchReportCategoriesSuccess(message));
    yield put(setFetchReportCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchReportCategoriesFailed(error));
    yield put(setFetchReportCategoriesLoading(false));
  }
}
export function* _getReportProducts() {
  try {
    yield put(setFetchReportProductsLoading(true));

    const market_id = yield select(getFetchReportsFilterMarketId);
    const branch_id = yield select(getFetchReportsFilterBranchId);
    const cashier_id = yield select(getFetchReportsFilterCashierId);
    const customer_id = yield select(getFetchReportsFilterCustomerId);
    const created_at_after = yield select(getFetchReportsFilterCreatedAtAfter);
    const created_at_before = yield select(
      getFetchReportsFilterCreatedAtBefore
    );
    const order_statuses = yield select(getFetchReportsFilterOrderStatuses);
    const transaction_statuses = yield select(
      getFetchReportsFilterTransactionStatuses
    );

    const search = yield select(getFetchReportProductsSearch);
    const sort = yield select(getFetchReportProductsSort);
    const key_by = yield select(getFetchReportProductsKeyBy);
    const page = yield select(getFetchReportProductsPage);
    const per_page = yield select(getFetchReportProductsPerPage);
    const product_statuses = yield select(
      getFetchReportProductsFilterProductStatuses
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      filter: {
        market_id,
        branch_id,
        cashier_id,
        customer_id,
        created_at_before,
        created_at_after,
        order_statuses,
        transaction_statuses,
        product_statuses,
      },
    };

    const {
      meta: { message },
      data: reportProducts,
    } = yield call(getReportProducts, parameters);

    yield put(setIsFetchReportProductsHitted(true));
    yield put(setReportProducts(reportProducts));

    yield put(setFetchReportProductsSuccess(message));
    yield put(setFetchReportProductsLoading(false));
  } catch (error) {
    yield put(setFetchReportProductsFailed(error));
    yield put(setFetchReportProductsLoading(false));
  }
}
export function* _getReportCashiers() {
  try {
    yield put(setFetchReportCashiersLoading(true));

    const market_id = yield select(getFetchReportsFilterMarketId);
    const branch_id = yield select(getFetchReportsFilterBranchId);
    const cashier_id = yield select(getFetchReportsFilterCashierId);
    const customer_id = yield select(getFetchReportsFilterCustomerId);
    const created_at_after = yield select(getFetchReportsFilterCreatedAtAfter);
    const created_at_before = yield select(
      getFetchReportsFilterCreatedAtBefore
    );
    const order_statuses = yield select(getFetchReportsFilterOrderStatuses);
    const transaction_statuses = yield select(
      getFetchReportsFilterTransactionStatuses
    );

    const search = yield select(getFetchReportCashiersSearch);
    const sort = yield select(getFetchReportCashiersSort);
    const key_by = yield select(getFetchReportCashiersKeyBy);
    const page = yield select(getFetchReportCashiersPage);
    const per_page = yield select(getFetchReportCashiersPerPage);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      filter: {
        market_id,
        branch_id,
        cashier_id,
        customer_id,
        created_at_before,
        created_at_after,
        order_statuses,
        transaction_statuses,
      },
    };

    const {
      meta: { message },
      data: reportCashiers,
    } = yield call(getReportCashiers, parameters);

    yield put(setIsFetchReportCashiersHitted(true));
    yield put(setReportCashiers(reportCashiers));

    yield put(setFetchReportCashiersSuccess(message));
    yield put(setFetchReportCashiersLoading(false));
  } catch (error) {
    yield put(setFetchReportCashiersFailed(error));
    yield put(setFetchReportCashiersLoading(false));
  }
}
export function* _getReportPayments() {
  try {
    yield put(setFetchReportPaymentsLoading(true));

    const market_id = yield select(getFetchReportsFilterMarketId);
    const branch_id = yield select(getFetchReportsFilterBranchId);
    const cashier_id = yield select(getFetchReportsFilterCashierId);
    const customer_id = yield select(getFetchReportsFilterCustomerId);
    const created_at_after = yield select(getFetchReportsFilterCreatedAtAfter);
    const created_at_before = yield select(
      getFetchReportsFilterCreatedAtBefore
    );
    const order_statuses = yield select(getFetchReportsFilterOrderStatuses);
    const transaction_statuses = yield select(
      getFetchReportsFilterTransactionStatuses
    );

    const search = yield select(getFetchReportPaymentsSearch);
    const sort = yield select(getFetchReportPaymentsSort);
    const key_by = yield select(getFetchReportPaymentsKeyBy);
    const page = yield select(getFetchReportPaymentsPage);
    const per_page = yield select(getFetchReportPaymentsPerPage);
    const payment_statuses = yield select(
      getFetchReportPaymentsFilterPaymentStatuses
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      filter: {
        market_id,
        branch_id,
        cashier_id,
        customer_id,
        created_at_before,
        created_at_after,
        order_statuses,
        transaction_statuses,
        payment_statuses,
      },
    };

    const {
      meta: { message },
      data: reportPayments,
    } = yield call(getReportPayments, parameters);

    yield put(setIsFetchReportPaymentsHitted(true));
    yield put(setReportPayments(reportPayments));

    yield put(setFetchReportPaymentsSuccess(message));
    yield put(setFetchReportPaymentsLoading(false));
  } catch (error) {
    yield put(setFetchReportPaymentsFailed(error));
    yield put(setFetchReportPaymentsLoading(false));
  }
}

export function* onFetchPrevReportTotalsStart() {
  yield takeLatest(
    REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_START,
    _getPrevReportTotals
  );
}
export function* onFetchNextReportTotalsStart() {
  yield takeLatest(
    REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_START,
    _getNextReportTotals
  );
}
export function* onFetchReportCategoriesStart() {
  yield takeLatest(
    REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_START,
    _getReportCategories
  );
}
export function* onFetchReportProductsStart() {
  yield takeLatest(
    REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_START,
    _getReportProducts
  );
}
export function* onFetchReportCashiersStart() {
  yield takeLatest(
    REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_START,
    _getReportCashiers
  );
}
export function* onFetchReportPaymentsStart() {
  yield takeLatest(
    REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_START,
    _getReportPayments
  );
}

export function* reportSaga() {
  yield all([
    call(onFetchPrevReportTotalsStart),
    call(onFetchNextReportTotalsStart),
    call(onFetchReportCategoriesStart),
    call(onFetchReportProductsStart),
    call(onFetchReportCashiersStart),
    call(onFetchReportPaymentsStart),
  ]);
}
