export const getAuthToken = () => localStorage.getItem("authToken");
export const setAuthToken = (token) => {
  localStorage.setItem("authToken", token);
  document.dispatchEvent(new Event("localStorage"));
};
export const clearAuthToken = () => {
  localStorage.removeItem("authToken");
  window.dispatchEvent(new Event("localStorage"));
};

export const getIpAddress = () => localStorage.getItem("ipAddress");
export const setIpAddress = (token) => {
  localStorage.setItem("ipAddress", token);
  document.dispatchEvent(new Event("localStorage"));
};
export const clearIpAddress = () => {
  localStorage.removeItem("ipAddress");
  window.dispatchEvent(new Event("localStorage"));
};

export const getLazyRetry = () => sessionStorage.getItem("lazyRetry");
export const setLazyRetry = (lazyRetry) => {
  sessionStorage.setItem("lazyRetry", lazyRetry);
  document.dispatchEvent(new Event("localStorage"));
};
export const clearLazyRetry = () => {
  sessionStorage.removeItem("lazyRetry");
  window.dispatchEvent(new Event("localStorage"));
};

export const getItem = (key) => localStorage.getItem(key);
export const setItem = (key, value) => {
  localStorage.setItem(key, value);
  window.dispatchEvent(new Event("localStorage"));
};
export const clearItem = (key) => {
  localStorage.removeItem(key);
  window.dispatchEvent(new Event("localStorage"));
};
