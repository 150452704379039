import EXTEND_PERIOD_ACTION_TYPES from "./extend-period.type";
import { createAction } from "../../utils/store.utils";

export const setExtendPeriods = (extendPeriods) =>
  createAction(EXTEND_PERIOD_ACTION_TYPES.SET_EXTEND_PERIODS, extendPeriods);
export const setExtendPeriod = (extendPeriod) =>
  createAction(EXTEND_PERIOD_ACTION_TYPES.SET_EXTEND_PERIOD, extendPeriod);
export const setCreateExtendPeriod = (createExtendPeriod) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_CREATE_EXTEND_PERIOD,
    createExtendPeriod
  );
export const setCustomExtendPeriod = (customExtendPeriod) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_CUSTOM_EXTEND_PERIOD,
    customExtendPeriod
  );

export const setIsExtendPeriodsHasMore = (isExtendPeriodsHasMore) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_IS_EXTEND_PERIODS_HAS_MORE,
    isExtendPeriodsHasMore
  );

export const setFetchExtendPeriodsSearch = (fetchExtendPeriodsSearch) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_SEARCH,
    fetchExtendPeriodsSearch
  );
export const setFetchExtendPeriodsSort = (fetchExtendPeriodsSort) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_SORT,
    fetchExtendPeriodsSort
  );
export const setFetchExtendPeriodsKeyBy = (fetchExtendPeriodsKeyBy) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_KEY_BY,
    fetchExtendPeriodsKeyBy
  );
export const setFetchExtendPeriodsPage = (fetchExtendPeriodsPage) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_PAGE,
    fetchExtendPeriodsPage
  );
export const setFetchExtendPeriodsPerPage = (fetchExtendPeriodsPerPage) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_PER_PAGE,
    fetchExtendPeriodsPerPage
  );
export const setFetchExtendPeriodsIncludes = (fetchExtendPeriodsIncludes) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_INCLUDES,
    fetchExtendPeriodsIncludes
  );
export const setFetchExtendPeriodsFilterMarketId = (
  fetchExtendPeriodsFilterMarketId
) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_MARKET_ID,
    fetchExtendPeriodsFilterMarketId
  );
export const setFetchExtendPeriodsFilterBranchId = (
  fetchExtendPeriodsFilterBranchId
) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_BRANCH_ID,
    fetchExtendPeriodsFilterBranchId
  );
export const setFetchExtendPeriodsFilterStatus = (
  fetchExtendPeriodsFilterStatus
) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_STATUS,
    fetchExtendPeriodsFilterStatus
  );
export const setFetchExtendPeriodsFilterStartAtValue = (
  fetchExtendPeriodsFilterStartAtValue
) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_START_AT_VALUE,
    fetchExtendPeriodsFilterStartAtValue
  );
export const setFetchExtendPeriodsFilterStartAtOperator = (
  fetchExtendPeriodsFilterStartAtOperator
) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_START_AT_OPERATOR,
    fetchExtendPeriodsFilterStartAtOperator
  );
export const setFetchExtendPeriodsFilterEndAtValue = (
  fetchExtendPeriodsFilterEndAtValue
) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_END_AT_VALUE,
    fetchExtendPeriodsFilterEndAtValue
  );
export const setFetchExtendPeriodsFilterEndAtOperator = (
  fetchExtendPeriodsFilterEndAtOperator
) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_END_AT_OPERATOR,
    fetchExtendPeriodsFilterEndAtOperator
  );
export const setFetchExtendPeriodsFilterCreatedBy = (
  fetchExtendPeriodsFilterCreatedBy
) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FILTER_CREATED_BY,
    fetchExtendPeriodsFilterCreatedBy
  );
export const setFetchExtendPeriodsLoading = (fetchExtendPeriodsLoading) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_LOADING,
    fetchExtendPeriodsLoading
  );
export const setFetchExtendPeriodsSuccess = (fetchExtendPeriodsSuccess) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_SUCCESS,
    fetchExtendPeriodsSuccess
  );
export const setFetchExtendPeriodsFailed = (fetchExtendPeriodsFailed) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIODS_FAILED,
    fetchExtendPeriodsFailed
  );

export const setFetchExtendPeriodLoading = (fetchExtendPeriodLoading) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIOD_LOADING,
    fetchExtendPeriodLoading
  );
export const setFetchExtendPeriodSuccess = (fetchExtendPeriodSuccess) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIOD_SUCCESS,
    fetchExtendPeriodSuccess
  );
export const setFetchExtendPeriodFailed = (fetchExtendPeriodFailed) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_FETCH_EXTEND_PERIOD_FAILED,
    fetchExtendPeriodFailed
  );

export const setCreateExtendPeriodLoading = (createExtendPeriodLoading) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_CREATE_EXTEND_PERIOD_LOADING,
    createExtendPeriodLoading
  );
export const setCreateExtendPeriodSuccess = (createExtendPeriodSuccess) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_CREATE_EXTEND_PERIOD_SUCCESS,
    createExtendPeriodSuccess
  );
export const setCreateExtendPeriodFailed = (createExtendPeriodFailed) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_CREATE_EXTEND_PERIOD_FAILED,
    createExtendPeriodFailed
  );

export const setCustomExtendPeriodLoading = (customExtendPeriodLoading) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_CUSTOM_EXTEND_PERIOD_LOADING,
    customExtendPeriodLoading
  );
export const setCustomExtendPeriodSuccess = (customExtendPeriodSuccess) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_CUSTOM_EXTEND_PERIOD_SUCCESS,
    customExtendPeriodSuccess
  );
export const setCustomExtendPeriodFailed = (customExtendPeriodFailed) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_CUSTOM_EXTEND_PERIOD_FAILED,
    customExtendPeriodFailed
  );

export const setStopExtendPeriodLoading = (stopExtendPeriodLoading) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_STOP_EXTEND_PERIOD_LOADING,
    stopExtendPeriodLoading
  );
export const setStopExtendPeriodSuccess = (stopExtendPeriodSuccess) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_STOP_EXTEND_PERIOD_SUCCESS,
    stopExtendPeriodSuccess
  );
export const setStopExtendPeriodFailed = (stopExtendPeriodFailed) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_STOP_EXTEND_PERIOD_FAILED,
    stopExtendPeriodFailed
  );

export const appendExtendPeriods = (extendPeriods) =>
  createAction(EXTEND_PERIOD_ACTION_TYPES.APPEND_EXTEND_PERIODS, extendPeriods);

export const fetchExtendPeriodsStart = () =>
  createAction(EXTEND_PERIOD_ACTION_TYPES.FETCH_EXTEND_PERIODS_START);
export const fetchExtendPeriodStart = (extendPeriodId) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.FETCH_EXTEND_PERIOD_START,
    extendPeriodId
  );
export const createExtendPeriodStart = (request) =>
  createAction(EXTEND_PERIOD_ACTION_TYPES.CREATE_EXTEND_PERIOD_START, request);
export const customExtendPeriodStart = (request) =>
  createAction(EXTEND_PERIOD_ACTION_TYPES.CUSTOM_EXTEND_PERIOD_START, request);
export const stopExtendPeriodStart = (request) =>
  createAction(EXTEND_PERIOD_ACTION_TYPES.STOP_EXTEND_PERIOD_START, request);

export const setIsFetchExtendPeriodsHitted = (isFetchExtendPeriodsHitted) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_IS_FETCH_EXTEND_PERIODS_HITTED,
    isFetchExtendPeriodsHitted
  );
export const setIsFetchExtendPeriodHitted = (isFetchExtendPeriodHitted) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_IS_FETCH_EXTEND_PERIOD_HITTED,
    isFetchExtendPeriodHitted
  );
export const setIsCreateExtendPeriodHitted = (isCreateExtendPeriodHitted) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_IS_CREATE_EXTEND_PERIOD_HITTED,
    isCreateExtendPeriodHitted
  );
export const setIsCustomExtendPeriodHitted = (isCustomExtendPeriodHitted) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_IS_CUSTOM_EXTEND_PERIOD_HITTED,
    isCustomExtendPeriodHitted
  );
export const setIsStopExtendPeriodHitted = (isStopExtendPeriodHitted) =>
  createAction(
    EXTEND_PERIOD_ACTION_TYPES.SET_IS_STOP_EXTEND_PERIOD_HITTED,
    isStopExtendPeriodHitted
  );

export const resetExtendPeriodReducer = () =>
  createAction(EXTEND_PERIOD_ACTION_TYPES.RESET_EXTEND_PERIOD_REDUCER);
