import { takeLatest, put, all, call, select } from "redux-saga/effects";

import BRANCH_ACTION_TYPES from "./branch.type";

import {
  appendActiveBranches,
  appendBranches,
  appendSelectBranches,
  setActiveBranch,
  setActiveBranches,
  setBranch,
  setBranches,
  setCreateBranchFailed,
  setCreateBranchLoading,
  setCreateBranchSuccess,
  setDeleteBranchFailed,
  setDeleteBranchLoading,
  setDeleteBranchSuccess,
  setFetchActiveBranchesFailed,
  setFetchActiveBranchesLoading,
  setFetchActiveBranchesSuccess,
  setFetchActiveBranchFailed,
  setFetchActiveBranchLoading,
  setFetchActiveBranchSuccess,
  setFetchBranchesFailed,
  setFetchBranchesLoading,
  setFetchBranchesPage,
  setFetchBranchesSuccess,
  setFetchBranchFailed,
  setFetchBranchLoading,
  setFetchBranchSuccess,
  setFetchSelectBranchesFailed,
  setFetchSelectBranchesLoading,
  setFetchSelectBranchesPage,
  setFetchSelectBranchesSuccess,
  setFetchSelectBranchFailed,
  setFetchSelectBranchLoading,
  setFetchSelectBranchSuccess,
  setIsActiveBranchesHasMore,
  setIsBranchesHasMore,
  setIsCreateBranchHitted,
  setIsDeleteBranchHitted,
  setIsFetchActiveBranchesHitted,
  setIsFetchActiveBranchHitted,
  setIsFetchBranchesHitted,
  setIsFetchBranchHitted,
  setIsFetchSelectBranchesHitted,
  setIsFetchSelectBranchHitted,
  setIsSelectBranchesHasMore,
  setIsUpdateBranchHitted,
  setSelectBranch,
  setSelectBranches,
  setUpdateBranchFailed,
  setUpdateBranchLoading,
  setUpdateBranchSuccess,
} from "./branch.action";
import {
  getFetchActiveBranchesFilterIsActive,
  getFetchActiveBranchesFilterMarketId,
  getFetchActiveBranchesIncludes,
  getFetchActiveBranchesPage,
  getFetchActiveBranchesPerPage,
  getFetchBranchesFilterIsActive,
  getFetchBranchesFilterMarketId,
  getFetchBranchesIncludes,
  getFetchBranchesPage,
  getFetchBranchesPerPage,
  getFetchBranchesSearch,
  getFetchSelectBranchesFilterIsActive,
  getFetchSelectBranchesFilterMarketId,
  getFetchSelectBranchesIncludes,
  getFetchSelectBranchesPage,
  getFetchSelectBranchesPerPage,
  getIsFetchBranchesHitted,
  getIsFetchSelectBranchesHitted,
} from "./branch.selector";
import { getCurrentBranchId } from "../global/global.selector";
import { setCurrentBranch } from "../global/global.action";

import {
  getBranches,
  getBranch,
  createBranch,
  updateBranch,
  deleteBranch,
} from "../../api/branch.api";
import { getLocation } from "../../api/mapbox.api";

export function* _getBranches() {
  try {
    yield put(setFetchBranchesLoading(true));

    const search = yield select(getFetchBranchesSearch);
    const page = yield select(getFetchBranchesPage);
    const per_page = yield select(getFetchBranchesPerPage);
    const includes = yield select(getFetchBranchesIncludes);
    const market_id = yield select(getFetchBranchesFilterMarketId);
    const is_active = yield select(getFetchBranchesFilterIsActive);

    const parameters = {
      search,
      page,
      per_page,
      includes,
      filter: { market_id, is_active },
    };

    const {
      meta: { message },
      data: { data: branches },
    } = yield call(getBranches, parameters);

    yield put(setIsFetchBranchesHitted(true));
    yield put(setIsBranchesHasMore(branches.length > 0));

    if (page > 1) {
      yield put(appendBranches(branches));
    } else {
      yield put(setBranches(branches));
    }

    yield put(setFetchBranchesSuccess(message));
    yield put(setFetchBranchesLoading(false));
  } catch (error) {
    yield put(setFetchBranchesFailed(error));
    yield put(setFetchBranchesLoading(false));
  }
}
export function* _getSelectBranches() {
  try {
    yield put(setFetchSelectBranchesLoading(true));

    const page = yield select(getFetchSelectBranchesPage);
    const per_page = yield select(getFetchSelectBranchesPerPage);
    const includes = yield select(getFetchSelectBranchesIncludes);
    const market_id = yield select(getFetchSelectBranchesFilterMarketId);
    const is_active = yield select(getFetchSelectBranchesFilterIsActive);

    const parameters = {
      page,
      per_page,
      includes,
      filter: { market_id, is_active },
    };

    const {
      meta: { message },
      data: { data: branches },
    } = yield call(getBranches, parameters);

    yield put(setIsFetchSelectBranchesHitted(true));
    yield put(setIsSelectBranchesHasMore(branches.length > 0));

    if (page > 1) {
      yield put(appendSelectBranches(branches));
    } else {
      yield put(setSelectBranches(branches));
    }

    yield put(setFetchSelectBranchesSuccess(message));
    yield put(setFetchSelectBranchesLoading(false));
  } catch (error) {
    yield put(setFetchSelectBranchesFailed(error));
    yield put(setFetchSelectBranchesLoading(false));
  }
}
export function* _getActiveBranches() {
  try {
    yield put(setFetchActiveBranchesLoading(true));

    const page = yield select(getFetchActiveBranchesPage);
    const per_page = yield select(getFetchActiveBranchesPerPage);
    const includes = yield select(getFetchActiveBranchesIncludes);
    const market_id = yield select(getFetchActiveBranchesFilterMarketId);
    const is_active = yield select(getFetchActiveBranchesFilterIsActive);

    const parameters = {
      page,
      per_page,
      includes,
      filter: { market_id, is_active },
    };

    const {
      meta: { message },
      data: { data: branches },
    } = yield call(getBranches, parameters);

    yield put(setIsFetchActiveBranchesHitted(true));
    yield put(setIsActiveBranchesHasMore(branches.length > 0));

    if (page > 1) {
      yield put(appendActiveBranches(branches));
    } else {
      yield put(setActiveBranches(branches));
    }

    yield put(setFetchActiveBranchesSuccess(message));
    yield put(setFetchActiveBranchesLoading(false));
  } catch (error) {
    yield put(setFetchActiveBranchesFailed(error));
    yield put(setFetchActiveBranchesLoading(false));
  }
}
export function* _getBranch({ payload: branchId }) {
  try {
    yield put(setFetchBranchLoading(true));

    const {
      meta: { message },
      data: branch,
    } = yield call(getBranch, branchId);

    yield put(setIsFetchBranchHitted(true));
    yield put(setBranch(branch));

    yield put(setFetchBranchSuccess(message));
    yield put(setFetchBranchLoading(false));
  } catch (error) {
    yield put(setFetchBranchFailed(error));
    yield put(setFetchBranchLoading(false));
  }
}
export function* _getSelectBranch({ payload: branchId }) {
  try {
    yield put(setFetchSelectBranchLoading(true));

    const {
      meta: { message },
      data: branch,
    } = yield call(getBranch, branchId);

    yield put(setIsFetchSelectBranchHitted(true));
    yield put(setSelectBranch(branch));

    yield put(setFetchSelectBranchSuccess(message));
    yield put(setFetchSelectBranchLoading(false));
  } catch (error) {
    yield put(setFetchSelectBranchFailed(error));
    yield put(setFetchSelectBranchLoading(false));
  }
}
export function* _getActiveBranch({ payload: branchId }) {
  try {
    yield put(setFetchActiveBranchLoading(true));

    const {
      meta: { message },
      data: branch,
    } = yield call(getBranch, branchId);

    yield put(setIsFetchActiveBranchHitted(true));
    yield put(setActiveBranch(branch));

    yield put(setFetchActiveBranchSuccess(message));
    yield put(setFetchActiveBranchLoading(false));
  } catch (error) {
    yield put(setFetchActiveBranchFailed(error));
    yield put(setFetchActiveBranchLoading(false));
  }
}
export function* _createBranch({ payload: request }) {
  try {
    yield put(setCreateBranchLoading(true));

    const { longitude, latitude } = request;

    if (![longitude, latitude].includes(undefined)) {
      const { features } = yield call(getLocation, longitude, latitude);
      const [feature] = features ?? [];
      const { place_name } = feature ?? {};

      request.address = place_name;
    }

    const {
      meta: { message },
      data: branch,
    } = yield call(createBranch, request);

    yield put(setIsCreateBranchHitted(true));
    yield put(setBranch(branch));

    const isFetchBranchesHitted = yield select(getIsFetchBranchesHitted);
    const isFetchSelectBranchesHitted = yield select(
      getIsFetchSelectBranchesHitted
    );

    if (isFetchBranchesHitted) {
      yield put(setFetchBranchesPage(1));
      yield call(_getBranches);
    }
    if (isFetchSelectBranchesHitted) {
      yield put(setFetchSelectBranchesPage(1));
      yield call(_getSelectBranches);
    }

    yield put(setCreateBranchSuccess(message));
    yield put(setCreateBranchLoading(false));
  } catch (error) {
    yield put(setCreateBranchFailed(error));
    yield put(setCreateBranchLoading(false));
  }
}
export function* _updateBranch({ payload: { branchId, request } }) {
  try {
    yield put(setUpdateBranchLoading(true));

    const { longitude, latitude } = request;

    if (![longitude, latitude].includes(undefined)) {
      const { features } = yield call(getLocation, longitude, latitude);
      const [feature] = features ?? [];
      const { place_name } = feature ?? {};

      request.address = place_name;
    }

    const {
      meta: { message },
      data: branch,
    } = yield call(updateBranch, branchId, request);

    yield put(setIsUpdateBranchHitted(true));
    yield put(setBranch(branch));

    const isFetchBranchesHitted = yield select(getIsFetchBranchesHitted);
    const isFetchSelectBranchesHitted = yield select(
      getIsFetchSelectBranchesHitted
    );

    if (isFetchBranchesHitted) {
      yield put(setFetchBranchesPage(1));
      yield call(_getBranches);
    }
    if (isFetchSelectBranchesHitted) {
      yield put(setFetchSelectBranchesPage(1));
      yield call(_getSelectBranches);
    }

    const currentBranchId = yield select(getCurrentBranchId);
    if (branchId === currentBranchId) yield put(setCurrentBranch(branch));

    yield put(setUpdateBranchSuccess(message));
    yield put(setUpdateBranchLoading(false));
  } catch (error) {
    yield put(setUpdateBranchFailed(error));
    yield put(setUpdateBranchLoading(false));
  }
}
export function* _deleteBranch({ payload: branchId }) {
  try {
    yield put(setDeleteBranchLoading(true));

    const {
      meta: { message },
    } = yield call(deleteBranch, branchId);

    yield put(setIsDeleteBranchHitted(true));

    const isFetchBranchesHitted = yield select(getIsFetchBranchesHitted);
    const isFetchSelectBranchesHitted = yield select(
      getIsFetchSelectBranchesHitted
    );

    if (isFetchBranchesHitted) {
      yield put(setFetchBranchesPage(1));
      yield call(_getBranches);
    }
    if (isFetchSelectBranchesHitted) {
      yield put(setFetchSelectBranchesPage(1));
      yield call(_getSelectBranches);
    }

    yield put(setDeleteBranchSuccess(message));
    yield put(setDeleteBranchLoading(false));
  } catch (error) {
    yield put(setDeleteBranchFailed(error));
    yield put(setDeleteBranchLoading(false));
  }
}

export function* onFetchBranchesStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.FETCH_BRANCHES_START, _getBranches);
}
export function* onFetchSelectBranchesStart() {
  yield takeLatest(
    BRANCH_ACTION_TYPES.FETCH_SELECT_BRANCHES_START,
    _getSelectBranches
  );
}
export function* onFetchActiveBranchesStart() {
  yield takeLatest(
    BRANCH_ACTION_TYPES.FETCH_ACTIVE_BRANCHES_START,
    _getActiveBranches
  );
}
export function* onFetchBranchStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.FETCH_BRANCH_START, _getBranch);
}
export function* onFetchSelectBranchStart() {
  yield takeLatest(
    BRANCH_ACTION_TYPES.FETCH_SELECT_BRANCH_START,
    _getSelectBranch
  );
}
export function* onFetchActiveBranchStart() {
  yield takeLatest(
    BRANCH_ACTION_TYPES.FETCH_ACTIVE_BRANCH_START,
    _getActiveBranch
  );
}
export function* onCreateBranchStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.CREATE_BRANCH_START, _createBranch);
}
export function* onUpdateBranchStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.UPDATE_BRANCH_START, _updateBranch);
}
export function* onDeleteBranchStart() {
  yield takeLatest(BRANCH_ACTION_TYPES.DELETE_BRANCH_START, _deleteBranch);
}

export function* branchSaga() {
  yield all([
    call(onFetchBranchesStart),
    call(onFetchSelectBranchesStart),
    call(onFetchActiveBranchesStart),
    call(onFetchBranchStart),
    call(onFetchSelectBranchStart),
    call(onFetchActiveBranchStart),
    call(onCreateBranchStart),
    call(onUpdateBranchStart),
    call(onDeleteBranchStart),
  ]);
}
