import SETTING_ACTION_TYPES from "./setting.type";

export const SETTING_INITIAL_STATE = {
  settings: [],
  setting: null,
  isSettingsHasMore: true,
  fetchSettingsPage: 1,
  fetchSettingsPerPage: 999,
  fetchSettingsLoading: false,
  fetchSettingsSuccess: null,
  fetchSettingsFailed: null,
  fetchSettingLoading: false,
  fetchSettingSuccess: null,
  fetchSettingFailed: null,
  updateSettingLoading: false,
  updateSettingSuccess: null,
  updateSettingFailed: null,
};

export const settingReducer = (state = SETTING_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SETTING_ACTION_TYPES.SET_SETTINGS:
      return { ...state, settings: payload };
    case SETTING_ACTION_TYPES.SET_SETTING:
      return { ...state, setting: payload };
    case SETTING_ACTION_TYPES.SET_IS_SETTINGS_HAS_MORE:
      return { ...state, isSettingsHasMore: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_PAGE:
      return { ...state, fetchSettingsPage: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_PER_PAGE:
      return { ...state, fetchSettingsPerPage: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_LOADING:
      return { ...state, fetchSettingsLoading: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_SUCCESS:
      return { ...state, fetchSettingsSuccess: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_FAILED:
      return { ...state, fetchSettingsFailed: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTING_LOADING:
      return { ...state, fetchSettingLoading: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTING_SUCCESS:
      return { ...state, fetchSettingSuccess: payload };
    case SETTING_ACTION_TYPES.SET_FETCH_SETTING_FAILED:
      return { ...state, fetchSettingFailed: payload };
    case SETTING_ACTION_TYPES.SET_UPDATE_SETTING_LOADING:
      return { ...state, updateSettingLoading: payload };
    case SETTING_ACTION_TYPES.SET_UPDATE_SETTING_SUCCESS:
      return { ...state, updateSettingSuccess: payload };
    case SETTING_ACTION_TYPES.SET_UPDATE_SETTING_FAILED:
      return { ...state, updateSettingFailed: payload };
    case SETTING_ACTION_TYPES.APPEND_SETTINGS:
      return { ...state, settings: [...state.settings, ...payload] };
    case SETTING_ACTION_TYPES.RESET_SETTING_REDUCER:
      return SETTING_INITIAL_STATE;
    default:
      return state;
  }
};
