import SETTING_ACTION_TYPES from "./setting.type";
import { createAction } from "../../utils/store.utils";

export const setSettings = (settings) => createAction(SETTING_ACTION_TYPES.SET_SETTINGS, settings);

export const setSetting = (setting) => createAction(SETTING_ACTION_TYPES.SET_SETTING, setting);

export const setIsSettingsHasMore = (isSettingsHasMore) =>
  createAction(SETTING_ACTION_TYPES.SET_IS_SETTINGS_HAS_MORE, isSettingsHasMore);

export const setFetchSettingsPage = (fetchSettingsPage) =>
  createAction(SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_PAGE, fetchSettingsPage);

export const setFetchSettingsPerPage = (fetchSettingsPerPage) =>
  createAction(SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_PER_PAGE, fetchSettingsPerPage);

export const setFetchSettingsLoading = (fetchSettingsLoading) =>
  createAction(SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_LOADING, fetchSettingsLoading);

export const setFetchSettingsSuccess = (fetchSettingsSuccess) =>
  createAction(SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_SUCCESS, fetchSettingsSuccess);

export const setFetchSettingsFailed = (fetchSettingsFailed) =>
  createAction(SETTING_ACTION_TYPES.SET_FETCH_SETTINGS_FAILED, fetchSettingsFailed);

export const setFetchSettingLoading = (fetchSettingLoading) =>
  createAction(SETTING_ACTION_TYPES.SET_FETCH_SETTING_LOADING, fetchSettingLoading);

export const setFetchSettingSuccess = (fetchSettingSuccess) =>
  createAction(SETTING_ACTION_TYPES.SET_FETCH_SETTING_SUCCESS, fetchSettingSuccess);

export const setFetchSettingFailed = (fetchSettingFailed) =>
  createAction(SETTING_ACTION_TYPES.SET_FETCH_SETTING_FAILED, fetchSettingFailed);

export const setUpdateSettingLoading = (updateSettingLoading) =>
  createAction(SETTING_ACTION_TYPES.SET_UPDATE_SETTING_LOADING, updateSettingLoading);

export const setUpdateSettingSuccess = (updateSettingSuccess) =>
  createAction(SETTING_ACTION_TYPES.SET_UPDATE_SETTING_SUCCESS, updateSettingSuccess);

export const setUpdateSettingFailed = (updateSettingFailed) =>
  createAction(SETTING_ACTION_TYPES.SET_UPDATE_SETTING_FAILED, updateSettingFailed);

export const appendSettings = (settings) => createAction(SETTING_ACTION_TYPES.APPEND_SETTINGS, settings);

export const fetchSettingsStart = () => createAction(SETTING_ACTION_TYPES.FETCH_SETTINGS_START);

export const fetchSettingStart = (key) => createAction(SETTING_ACTION_TYPES.FETCH_SETTING_START, key);

export const updateSettingStart = (key, request) =>
  createAction(SETTING_ACTION_TYPES.UPDATE_SETTING_START, { key, request });

export const resetSettingReducer = () => createAction(SETTING_ACTION_TYPES.RESET_SETTING_REDUCER);
